.widget-type-filter__options {
  font-size: 13px;
}

.widget-options-title {
  margin: 16px 0;
  padding-left: 18px;
  padding-top: 0 !important;
  cursor: pointer;
}

.static-div {
  flex-shrink: 0;
  overflow-x: hidden;
  border-right: 1px solid #f5f8fa;
}

.widget-preview-tabs-container {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 250px);
}

.compare-widget-layout {
  display: flex;
  height: 100%;
}

.static-div, .widget-preview-tabs-container {
  max-height: 100%;
  overflow-y: auto;
}

.widget-option-tab {
  width: 370px;
  padding: 0 10px 0 20px;
  margin-bottom: 20px;
}

.filters-override-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filters-override-title {
  padding-top: 17px !important;
}

.filters-override-button {
  cursor: pointer;
  padding: 15px 20px 10px 5px;
}