.gpt-prompt-page {
  margin: 45px;
}

.gpt-prompt-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gpt-prompt-title {
  margin-top: 0 !important;
  margin-bottom: 10px;
}

.prompt-count-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.gpt-prompt-tab {
  margin: 45px 45px 65px 45px;
}

.prompt-textarea {
  min-height: 200px !important;
}

.prompt-count-input {
  width: 100px;
}

.gpt-prompt-nav-tab {
  margin-bottom: 10px !important;
}

.prompt-count-label {
  margin-top: 8px;
}

.tab-textarea {
  width: 1000px;
  height: 800px;
  margin-bottom: 20px;
}

.gpt-prompt-table {
  margin-bottom: 80px;
}

.response-gpt-prompt-textarea {
  min-height: 200px !important;
  width: 100%;
  text-align: justify;
}

.gpt-prompt-error {
  margin: 45px;
  font-size: 18px;
  color: red;
}
