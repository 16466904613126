
.meeting-score-chart {
  width: 100%;
  margin-bottom: 0;
  font-size: small;

  .chart {
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%;

      .chart-info {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        width: 100%;

          .bar-color {
            width: 30px;
            height: 16.5px;
            background: #3C64F0;
            border-radius: 8px;
            margin-right: 12px;
          }

          .chart-value-type {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 23px;
            color: #000000;
          }
        }

      .chart-values {
        display: flex;
        flex-direction: column;
        width: 93%;
        position: relative;

          .average-value {
            position: absolute;
            height: 100%;
            width: 1px;
            border: 1px solid black;
            z-index: 0;
          }

          .thirds-value {
            position: absolute;
            height: 100%;
            width: 1px;
            border: 1px solid #E2E2E2;
            z-index: 0;
          }

          .average-title {
            display: flex;
            justify-content: center;
            top: -20px;
            position: absolute;
            width: 230px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 15.2015px;
            line-height: 18px;
            color: #000000;
            text-align: center;

              .light-title {
                color: #7D7D7D;
                margin-right: 3px;
              }
          }

          .top {
            top: -40px;
          }

          .chart-bar {
            display: flex;
            width: 100%;
            padding-top: 5px;

              .user-image {
                min-width: 50px;
                height: 50px;
                border-radius: 50%;
                background: #DFDFDF;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin: 10px;

                  span {
                    font-weight: 700;
                  }

                  img {
                    padding: 0;
                    height: inherit;
                    width: inherit;
                  }
              }

              .bar-wrapper {
                display: flex;
              }

              .bar-value {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                width: 100%;

                  .user-name {
                    font-size: 18px;
                    color: #333 !important;
                    font-weight: 700;
                    margin-bottom: 10px;
                    margin-left: 0.1px;
                    z-index: 1;
                  }

                  .bar-progress {
                    height: 20px;
                    background: #3C64F0;
                    position: relative;
                    z-index: 1;

                      .tooltiptext {
                        position: absolute;
                        top: 2px;
                        right: 5px;
                        z-index: 1;
                        color: #eff2f7;
                        background-color: transparent;
                        text-align: center;
                        font-weight: 600;
                      }
                  }

                  .tooltiptext-black {
                    color: #333;
                    background-color: transparent;
                    text-align: left;
                    font-weight: 600;
                    position: relative;
                    top: 2px;
                    padding-left: 5px;
                  }
              }
          }

          &-empty {
            text-align: center;
            font-size: 30px;
            font-weight: 600;
            height: 300px;
            padding: 135px;
          }
          .chart-no-data-img {
            width: 35px;
            height: 35px;
            margin: 0 10px 10px 0
          }
      }
  }

  .widget-chart .chart {
        padding: 1px;
        width: 95%;

        &.empty {
            width: 100%;

            .chart-info {
                display: none;
            }

            .chart-values {
                width: 100%;

                .chart-values-empty {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 235px;

                      .no-data-image {
                        width: 30px;
                        height: 30px;
                        margin: 0 5px 5px 0;
                      }
                }
            }
        }

        .chart-info {
          margin-bottom: 35px;
            .bar-color {
              display: none;
            }
        }
        .average-title {
            font-size: 12px;
            margin-right: 10px;
            line-height: 20px;
            top: -40px;
        }

        .top{
          top: -20px;
        }

        .chart-values {
            .chart-bar {
                padding-top: 0;

                .user-image {
                    height: 20px;
                    width: 20px;
                    min-width: 20px;
                    margin: 7px;
                }
            }
            .bar-value {
                margin-bottom: 0;

                .user-name {
                    font-size: 12px;
                    margin-bottom: 0;
                    z-index: 0;
                }
                .bar-progress {
                    height: 10px;
                    z-index: 1;
                    .tooltiptext {
                        font-size: 8px;
                        top: 0;
                    }
                }
            }
        }
    }
}

.chart-empty-value {

}