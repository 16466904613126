.sidebar {
  width: 222px;
  background-color: #fff;
  padding: 8px;
  border-radius: 5px;
  box-shadow: rgba(71, 88, 114, 0.1) 0px 2px 6px;
}

.tag-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 2px;
  @media (max-width: 992px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.tag-item {
  display: flex;
  justify-content: start;
  align-items: center;
  list-style-type: none;
  border-radius: 10px;
  color: #000;
  padding: 4px 7px;
  width: fit-content;
  margin-top: 3px;
  font-weight: 600;
  cursor: pointer;
  @media (max-width: 992px) {
    min-width: fit-content;
  }
}

.tag-color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: auto;

  padding: 20px 0;
  gap: 20px;
  align-items: start;
  max-height: 85%;

  &__loading {
    display: flex;
    justify-content: center;
  }
}

.grid-container > * {
  max-height: 250px;
  min-width: 200px;
  overflow: hidden;
}

.inspiration-card {
  height: 250px;

  &__information {
    background-color: #fff !important;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  .owner-name {
    text-align: end;
  }

  &__date {
    color: #00b388;
    font-size: 12px;
    font-weight: 700;
  }

  &__picture {
    padding: 0;
    height: 95%;

    &__no-picture {
      position: relative;
      height: 140px;
      border-radius: 5px;
      background-color: $gray-200;
      border: 2px solid #0000001c;
      text-align: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 60px;
    }
  }

  &__icons {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.inspiration-library-container {
  display: flex;
  width: 100%;
  margin-bottom: 55px;
  @media (max-width: 992px) {
    flex-direction: column !important;
  }
}

.search-library {
  width: 100%;
  background-color: #fff;
  margin: 16px 10px 24px 0;
  padding: 8px;
  border-radius: 5px;
  box-shadow: rgba(71, 88, 114, 0.1) 0px 2px 6px;
}

.inspiration-library-placeholder-container {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  height: 625px;
  position: relative;
}

.no-data-icon {
  width: 60px;
  height: 60px;
}

.inspiration-library-title {
  height: 32px;
  margin-bottom: 60px;
}

.delete-tag-button {
  border: none;
  margin-left: 7px;
  border-radius: 5px;
  background-color: transparent;
}

.inspiration-no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.no-search-result {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
}

.inspiration-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inspiration-title {
  font-size: 30px;
  line-height: 35px;
  color: #00b388;
  margin: 50px 0;
}

.inspiration-filters {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-right: 40px;
  justify-content: flex-end !important;

  .filters {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-left: 11px;

    @media (max-width: 1300px) {
      flex-direction: column;
      align-items: flex-start !important;
      margin-left: 0;
    }

    &__title {
      text-decoration: underline;
      font-size: 13px;
      font-weight: 600;
      white-space: nowrap;
      display: none;
    }

    &__filter {
      margin: 0;
      min-width: 150px;
      cursor: pointer;
      white-space: nowrap;

      @media(min-width: 1200px) {
        width: 110px;
      }

      @media (max-width: 1200px) {
        width: 100%;
      }

      .select2-selection__control {
        border: none;
      }

      .select2-selection__value-container {
        justify-content: start;
        padding-left: 16px;
      }

      .select2-selection__single-value, .select2-selection__placeholder, .select2-selection__value-container--is-multi {
        color: #000000!important;
        font-weight: 600;
      }

      @media (max-width: 1300px) {
        .select2-selection__menu {
          position: absolute !important;
          top: 100% !important;
          left: 0 !important;
          right: 0 !important;
        }
      }

      &--date-time {
        width: fit-content;

        @media (max-width: 1300px) {
          width: 100%;
        }

        .btn.btn-primary {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 6px 5px 17px;
          border: none;
          background: none;
          width: 100%;
          color: #000000 !important;
          font-weight: 600;

          &:focus {
            transition: none;
            box-shadow: none;
            border: 1px solid #2684FF;
          }
        }

        .dropdown-menu {
          left: auto !important;
          right: 0;
          transform: none !important;

          @media (max-width: 1300px) {
            position: absolute !important;
            top: 100% !important;
            left: 0 !important;
            right: 0 !important;
            min-width: 220px;
          }
        }

        i.mdi {
          margin-left: auto;
          line-height: 1;
          color: #cccccc;
          font-size: 24px;
        }
      }
    }
  }
}

.short {
  min-width: 100px;
}

.medium {
  min-width: 125px;
}

.long {
  min-width: 150px;
}

.inspiration-search {
  @extend .card;
  margin: 0;
  width: auto;

  &__icon {
    @extend .input-group-prepend;

    & > * {
      border: none;
      background: none;

      i {
        font-size: 18px;
      }
    }
  }

  &__input {
    @extend .form-control;
    border: none;
    background: #ffffff;
    font-size: 16px;
    font-weight: 400;
  }
}

/* Mobile Screen */
@media (max-width: 992px) {
  .sidebar {
    width: 100%;
    margin: 10px 0;
    display: flex;
    align-items: flex-start;
  }

  .tag-list {
    flex-direction: row;
  }

  .grid-container {
    grid-template-columns: 1fr;
    padding: 10px;
  }

  .inspiration-card {
    width: auto;

    &__footer {
      bottom: 0;
    }
  }

  .search-library {
    margin: 8px;
  }

  .inspiration-library-container {
    flex-direction: column;
    align-items: center;
  }

  .inspiration-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0;
  }

  .inspiration-title {
    font-size: 24px;
    margin: 25px 0 !important;
  }

  .inspiration-search {
    width: auto;
  }
}

@media (max-width: 1450px) {
  .inspiration-header {
      align-items: flex-start;
      flex-direction: column;

      .inspiration-title {
        margin: 50px 0 25px 0;
      }
    
      .filters {
        margin: 0 0 16px 0 !important;
      }
  }
}

@media (max-width: 500px) {
  .inspiration-filters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;

    &__actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      @media (max-width: 1300px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__filter {
      &--date-time {
        .dropdown-menu.show {
          position: absolute !important;
          top: 35px !important;
          left: 0 !important;
          min-width: 320px;
        }
      }
    }
  }

  .inspiration-library-title {
    height: 132px;
  }
}


@media (max-width: 700px) {
  .inspiration-library-title {
    height: 110px;
  }
}

@media (max-width: 400px) {
  .inspiration-library-title {
    height: 150px;
  }
}
