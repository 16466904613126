$comments-color-dark-grey: #8f8f8f;
$comments-font-size: 14px;

.comments {
  position: relative;

  *:not(.fa) {
    font-family: 'roboto';
  }
  a {
    color: $color-blue !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
  .btn-blue {
    color: #fff !important;

    &:hover {
      background-color: $color-blue-hover;
      border-color: $color-blue-hover;
    }
  }

  .title {
    padding: 40px 0 34px;
    margin: 0;
    color: #000;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
  }

  #comments-to-timeline-repositioner {
    width: calc(100% - 146px);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1003;

    @media(max-width:767px){
      width: 100%;
    }
  }

  .comments-line {
    width: 100%;
    height: 21px;
    padding: 13px 0 6px;
    background-color: #000;
    background-clip: content-box;
    position: absolute;
    top: -58px;
    right: 0;

    a.add-comments-toggle {
      padding: 5px;
      border-radius: 50%;
      background-color: #6c32b7;
      line-height: .8;
      position: absolute;
      bottom: -3px;
      left: 0;
      transform: translateX(-42%);
      z-index: 10000;
  
      .fa {
        color: #fff;
        font-size: 10px;
      }
      &:hover {
        .fa {
          font-size: 12px;
        }
      }

      @media(max-width:767px){
        display: none;
      }
    }
    .comments-preview-toggle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $color-blue;
      color: #fff;
      font-size: 10px;
      line-height: 20px;
      text-align: center;
      position: absolute;
      bottom: -2px;
      left: 0;
      transform: translateX(-43%);
      cursor: default;

      &:hover {
        z-index: 11000;

        .comments-preview {
          display: block;
        }
      }
      .comments-preview {
        padding: 21px;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        color: $gray-700;
        font-size: 13px;
        line-height: 1.5;
        text-align: left;
        position: absolute;
        left: 32px;
        bottom: -34px;
        display: none;

        &.reverse {
          left: auto;
          right: 32px;
        }
        &:not(.reverse)::before {
          content: '';
          width: 22px;
          height: 100%;
          position: absolute;
          top: 0;
          left: -22px;
        }
        &.reverse::after {
          content: '';
          width: 22px;
          height: 100%;
          position: absolute;
          top: 0;
          right: -22px;
        }
        &:hover {
          display: block;
        }
        ul {
          max-height: 230px;
          margin: 0;
          overflow: auto;

          li {
            padding: 9px 11px;
            border-bottom: 1px solid #f1f1f1;
            white-space: nowrap;
            cursor: pointer;
         
            &:last-child {
              border-bottom: 0;
            }
            &:hover {
              background-color: #3c64f026;
            }
          }
        }
      }
    }
  }

  .add-comment-box {
    padding: 14px 16px;
    border-radius: 10px;
    margin-left: 44px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    position: absolute;
    top: -180px;
    left: 0;

    &.reverse {
      margin-left: -20px;
      transform: translateX(-100%);
    }
    .add-comment-box-title {
      margin-bottom: 12px;

      .fa {
        color: #6c32b7;
        font-size: 24px;
        vertical-align: middle;
      }
      span {
        margin: 0 10px;
        vertical-align: middle;
      }
      a {
        vertical-align: middle;
      }
    }
    .buttons-wrapper {
      margin-top: 10px;
  
      .col-lg-3 {
        padding-right: 0 !important;
        white-space: nowrap;
      }
      .btn {
        width: 85px;
        padding: 5px 20px;
        margin-right: 6px;
        border-radius: 6px;
        font-size: $comments-font-size;
        box-shadow: none !important;
      }
      .radio-buttons-wrapper {
        line-height: 33px;
        text-align: right;
    
        span {
          vertical-align: top;
          margin-right: 16px;
        }
        .form-check-inline {
          vertical-align: middle;
    
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .comment-title {
    margin: 13px 0;
  }

  .comment-wrapper {
    max-width: 820px;
    padding: 20px 20px 18px;
    border-radius: 10px;
    margin-bottom: 32px;
    background-color: #fff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

    &:last-child {
      margin-bottom: 15px;
    }
    .reply-box {
      padding-left: 35px !important;
    }
    .comment-text {
      margin-bottom: 4px;
      font-size: $comments-font-size;
      white-space: pre-wrap;
      a {
        text-decoration: underline !important;
      }
    }
    .comment-details {
      font-size: $comments-font-size;
      color: $comments-color-dark-grey;
  
      a {
        color: $comments-color-dark-grey !important;
      }
    }
    .reply-btn {
      width: 100%;
      padding: 7px 24px;
      border-radius: 2px;
      // font-size: $comments-font-size;
      box-shadow: none !important;
    }

    .post-comment-button {
      border-radius: 2px;
      // font-size: $comments-font-size;
      box-shadow: none !important;
      white-space: nowrap;
    }
  }
}

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  color: $comments-color-dark-grey;
  pointer-events: none;
  display: block; /* For Firefox */
}
.editable-element {
  width: 100%;
  max-height: 160px;
  padding: 8px 14px;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: #f4f4f4;
  font-family: 'roboto';
  font-size: $comments-font-size;
  display: inline-block;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: auto;

  &:focus {
    border-color: #d3d3d3;
    outline: 0 !important;
  }
  a {
    color: $color-blue;
    text-decoration: underline !important;
  }
}

.inline-select-menu {
  padding: 8px 0;
  border-radius: 6px;
  background-color: #f8f8f8;
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  transform: translate(-26px, calc(-100% - 12px));
  z-index: 20000;

  ul {
    max-height: 190px;
    margin: 0;
    overflow: auto;

    li {
      padding: 4px 18px;
      font-family: 'roboto';
      cursor: pointer;
    
      &.active {
        color: #fff;
        background-color: $color-blue;
      }
    }
  }
}
