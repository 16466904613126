@mixin center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.draggable-item {
  display: grid;
  grid-template: auto / 20px 1fr 20px 20px;
  gap: 10px;
  width: 95%;
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0;

  &__handle {
    @include center-items;
  }

  &__content {
    display: flex;
    flex-direction: column;
    min-width: 0;
  }

  &__title {
    font-size: 13px;
    font-weight: bold;
    margin: 0;
  }

  &__description {
    font-size: 12px;
    color: #555;
    margin: 5px 0 0 0;
    white-space: normal;
    word-wrap: break-word;
  }

  &__weight-input {
    @include center-items;
    font-size: 14px;
    font-weight: 600;
  }

  &__actions {
    @include center-items;
    flex-direction: column;
  }

  &__icon {
    font-size: 24px;
    color: #888;
  }

  .dropdown {
    &__toggle {
      background: none;
      border: none;
      color: #888;
      cursor: pointer;
      padding: 5px;
    }

    &__menu {
      position: absolute;
      top: 100%;
      right: 0;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      z-index: 1000;
    }

    &__item {
      background: none;
      border: none;
      color: #333;
      cursor: pointer;
      padding: 5px 10px;
      width: 100%;
      text-align: left;
    }

    &__item:hover {
      background-color: #f0f0f0;
    }
  }

  &__children {
    margin-left: 20px;
  }

  &__children &__actions {
    flex-direction: unset;
    margin-right: 3px;
  }
}
