
//
// apexcharts.scss
//
.apex-charts {
    min-height: 10px !important;
    text {
        font-family: $font-family-base !important;
        fill: $gray-500;
    }
    .apexcharts-canvas {
        // margin: 0 auto;
        margin: 25px
    }
}

.apexcharts-tooltip {
  max-height: 96%;
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
    font-family: $font-family-base !important;
}

.apexcharts-legend-series {
    font-weight: $font-weight-medium;
}

.apexcharts-gridline {
    pointer-events: none;
    stroke: $apex-grid-color;
}

.apexcharts-legend-text {
    font-weight: 600 !important;
    font-family: $font-family-base !important;
    font-size: 12px !important;
}

.apexcharts-pie-label {
    fill: $white !important;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
    text {
        font-family: $font-family-base !important;
        fill: white;
    }
}

.pie-chart-wrapper .apexcharts-legend {
    display: block !important;
}

.apexcharts-canvas .apexcharts-datalabels .apexcharts-text.apexcharts-pie-label {
    fill: white !important;
}

.bar-chart {
  margin-bottom: -25px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  background-color: white;
  z-index: 1;
}

.bar-chart-bullet {
  margin: 5px 5px 0 0;
  font-size: 40px;
  line-height: 1px;
  display: inline-block;
  vertical-align: middle;
}

.apexcharts-bar-area:hover {
  cursor: pointer !important;
}
