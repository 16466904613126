.widget-color-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-color-button {
  margin-top: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.no-color-button:hover {
  background-color: #e0e0e0;
}
