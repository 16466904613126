//
// _horizontal.scss
//


.topnav {
  .dropdown:hover>.dropdown-menu {
    // display: none;
}

.dropdown:hover {
  >.dropdown-menu {
    display: block;
    background-color: #fff;
  }
}

    background: #fff;
    /* box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%); */
    margin-top: 0;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    color: #fff;
    border-bottom: thin solid #E7E7E7;

    .topnav-menu {
        margin: 0;
        padding: 0;
    }

    .navbar-nav {
        .nav-link {
            font-size: 15px;
            position: relative;
            padding: 15px 1.3rem;
            color: #333;
            font-weight: 500;

            @media (max-width: 1210px) {
                font-size: 14px;
              }

            i {
                font-size: 15px;
                color: black;
            }
            &:focus, &:hover{
                background-color: transparent;
            }
            p {

                display: inline-block;
                margin: 0;
                vertical-align: top;
                line-height: 34px;
                color: #000000;
                font-weight: 500;
                opacity: 1;
                text-transform: capitalize;
            }
            img {

                border-radius: 50%;
                width: 32px;
                margin-right: 9px;
            }
        }
        .dropdown-item{
            color: $menu-item-color;

            &.active, &:hover{
                color: $menu-item-active-color;
            }
        }
        .nav-item{
            .nav-link.active{
                color: $menu-item-active-color;
            }
        }

        .dropdown{
            &.active{
              >a {
                    color: $menu-item-active-color;
                    background-color: transparent;
                }
            }

            .override-white-space {
                white-space: normal;
            }
        }
    }
}

li.nav-item:hover {
    background: #f6f6f6;
}

@include media-breakpoint-up(xl) {

    body[data-layout="horizontal"] {
        .container-fluid,
        .navbar-header {
            max-width: 85%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .topnav {
        .dropdown-item {
            padding: .5rem 1.5rem;
            min-width: 180px;
        }

        .dropdown {
            &.mega-dropdown{
                .mega-dropdown-menu{
                    left: 0px;
                    right: auto;
                }
            }
            .dropdown-menu {
                position: absolute;
                top: 32px;
                left: -82px;
                margin-top: 0;
                border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;

                .arrow-down {
                    &::after {
                        right: 15px;
                        transform: rotate(-135deg) translateY(-50%);
                        position: absolute;
                    }
                }

                .dropdown {
                    .dropdown-menu {
                        position: absolute;
                        top: 0 !important;
                        left: 100%;
                        display: none
                    }
                }
            }

            &:hover {
                >.dropdown-menu {
                    display: block;
                }
            }
        }

        .dropdown:hover>.dropdown-menu>.dropdown:hover>.dropdown-menu {
            display: block
        }
    }

    .navbar-toggle {
        display: none;
    }
}

.arrow-down {
    display: inline-block;

    &:after {
        border-color: initial;
        border-style: solid;
        border-width: 0 0 1px 1px;
        content: "";
        height: .4em;
        display: inline-block;
        right: 5px;
        top: 50%;
        margin-left: 10px;
        transform: rotate(-45deg) translateY(-50%);
        transform-origin: top;
        transition: all .3s ease-out;
        width: .4em;
    }
}


@include media-breakpoint-down(lg) {
    .topnav-menu {
        .navbar-nav {
            li {
                &:last-of-type {
                    .dropdown {
                        .dropdown-menu {
                            right: 100%;
                            left: auto;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {

    .navbar-brand-box{
        .logo-dark {
            display: $display-block;
            span.logo-sm{
                display: $display-block;
            }
        }

        .logo-light {
            display: $display-none;
            span.logo-sm{
                display: $display-none;
            }
        }
    }

    .topnav {
        max-height: 360px;
        padding: 0;
        .navbar-nav {
            .nav-link {
                padding: 0.75rem 1.1rem;
            }
        }

        .dropdown {
            .dropdown-menu {
                position: absolute;
                top: 30px;
                left: -82px;
                background-color: transparent;
                border: none;
                box-shadow: none;
                padding-left: 15px;
                &.dropdown-mega-menu-xl{
                    width: auto;

                    .row{
                        margin: 0px;
                    }
                }

                @media (min-width: 992px){
                  left: -77px;
                  margin-left: 15px;
              }
            }

            .dropdown-item {
                position: relative;
                background-color: transparent;

                &.active,
                &:active {
                    color: $primary;
                }
            }
        }

        .arrow-down {
            &::after {
                right: 15px;
                position: absolute;
            }
        }
    }
}

@media (max-width: 784px) {
  .navbar-brand-box {
      .logo-dark {
          display: $display-block;
          span.logo-sm {
              display: $display-block;
          }
      }

      .logo-light {
          display: $display-none;
          span.logo-sm {
              display: $display-none;
          }
      }
  }

  .topnav {
      max-height: 360px;
      padding: 0;

      .navbar-nav {
          .nav-link {
              padding: 0.75rem 1.1rem;
          }
      }

      .dropdown {
          .dropdown-menu {
            display: none;
            position: absolute;
            top: auto;
            bottom: 100%;
            left: -110px;
            float: left;
            background-color: transparent;
            border: none;
            box-shadow: none;
            padding-left: 15px;

              &.dropdown-mega-menu-xl {
                  width: auto;

                  .row {
                      margin: 0;
                  }
              }
          }

          .dropdown-item {
              position: relative;
              background-color: transparent;

              &.active,
              &:active {
                  color: $primary;
              }
          }
      }
  }
}

@include media-breakpoint-up(lg) {

    body[data-layout="horizontal"][data-topbar="light"] {
        .navbar-brand-box{
            .logo-dark {
                display: $display-block;
            }

            .logo-light {
                display: $display-none;
            }

            background: #00318f!important;
        }

        .topnav{
            background-color: $primary;
            .navbar-nav {

                .nav-link {
                    color: rgba($white, 0.6);

                    &:focus, &:hover{
                        color: rgba($white, 0.9);
                    }
                }

                > .dropdown{
                    &.active{
                    >a {
                            color: rgba($white, 0.9) !important;
                        }
                    }
                }
            }
        }
    }
}

// Colored Topbar

body[data-layout="horizontal"][data-topbar="colored"] {
    #page-topbar{
        background-color: none;
        box-shadow: none;
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }

    .app-search {

        .form-control {
            background-color: rgba($topbar-search-bg,0.07);
            color: $white;
        }
        span,
        input.form-control::-webkit-input-placeholder {
            color: rgba($white,0.5);
        }
    }
    .header-item {
        color: $header-dark-item-color;

        &:hover {
            color: $header-dark-item-color;
        }
    }

    .navbar-header {
        .dropdown.show {
            .header-item{
                background-color: rgba($white,0.1);
            }
        }

        .waves-effect .waves-ripple {
            background: rgba($white, 0.4);
        }
    }

    .noti-icon {
        i {
            color: $header-dark-item-color;
        }
    }

    @include media-breakpoint-up(lg) {
        .topnav{
            background-color: $primary;
            .navbar-nav {

                .nav-link {
                    color: rgba($white, 0.6);

                    &:focus, &:hover{
                        color: rgba($white, 0.9);
                    }
                }

                > .dropdown{
                    &.active{
                      >a {
                            color: rgba($white, 0.9) !important;
                        }
                    }
                }
            }
        }
    }
}

body[data-topbar="dark"] {
    #page-topbar.custome-horizontal-header {
        background-color: none!important;
        box-shadow: none !important;
    }
}



