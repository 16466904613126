// 
// _badge.scss
// 

// Soft badge
@mixin badge-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18);

  &[href] {
    @include hover-focus {
      color: $bg;
      text-decoration: none;
      background-color: rgba($bg, 0.4);
    }
  }
}

@each $color, $value in $theme-colors {
  .badge-soft-#{$color} {
      @include badge-variant-soft($value);
  }
}

// Dark badge

.badge-dark{
  color: $light;
}

.badge-clickable {
  background-color: #f4f4f4;
  font-weight: 600;
  font-size: 12px;
}

.show-more-wrapper + .show-more-button {
  color: #000;
  cursor: pointer;
}

.show-more-wrapper.show {
  white-space: pre-wrap;
  line-height: 2;
}

.show-more-wrapper.show + .show-more-button {
  display: none;
}

.show-more-wrapper.show .hidden {
  display: inline !important;
}
