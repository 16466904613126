//
// Widgets.scss
//
.home-page .widgets .react-grid-item.widget {
    padding: 0;
    padding-bottom: 13px;

    > .widget {
        margin-bottom: 0;
        box-shadow: unset;
    }
}

.mini-stats-wid{
    .mini-stat-icon{
        overflow: hidden;
        position: relative;
        &:before, &:after{
            content: "";
            position: absolute;
            width: 8px;
            height: 54px;
            background-color: rgba($white,.1);
            left: 16px;
            transform: rotate(32deg);
            top: -5px;
            transition: all 0.4s;
        }

        &::after{
            left: -12px;
            width: 12px;
            transition: all 0.2s;
        }
    }

    &:hover{
        .mini-stat-icon{
            &::after{
                left: 60px;
            }
        }
    }
}

.widget-meeting-stats {
    height: 79px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
}

.stats {

    &__title {
        font-size: 18px;
        font-weight: 600;
        color: #000;

        @media (min-width: 750px) and (max-width: 800px) {
            margin-top: 5px;
            font-size: 15px;
        }

        @media (min-width: 500px) and (max-width: 750px) {
            margin-top: 5px;
            font-size: 13px;
        }

        @media (max-width: 500px) {
            margin-top: 5px;
            font-size: 10px
        }
    }

    &__stat {
        text-align: center;
        color: $green;
        font-size: 40px;
        font-weight: 600;

        @media (min-width: 750px) and (max-width: 800px) {
            margin-top: 10px;
            font-size: 35px;
        }

        @media (min-width: 500px) and (max-width: 750px) {
            margin-top: 10px;
            font-size: 25px;
        }

        @media (max-width: 500px) {
            margin-top: 10px;
            font-size: 20px
        }
    }
}

.single-stat {
    display: grid;
    align-items: center;
    text-align: center;

    &__title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        color: #000000;
    }

    &__body {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        color: #000000;
    }
}

.recording-modal {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-title {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  &__sm-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-title {
      font-size: 16px !important;
      font-weight: 600;
      height: 30px;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  &__modal-switch {
    margin-bottom: 20px;

    .switch {
      margin-bottom: 20px;
      &__wrapper {
        &__label {
          font-size: 14px;
        }
        &__switch-button {
          width: 50px;
          height: 23px;

          .slider:before {
            width: 15px;
            height: 15px;
          }
        }

      }
    }
  }

  &__exclude-btn {
    padding: 6px 8px;

    &:hover {
      background-color: rgb(235, 86, 86);
    }
  }

  &__close-button {
    border: none;
    border-radius: 5px;
    padding: 8px;
  }

  &__modify-button {
    border: none;
    border-radius: 5px;
    padding: 8px;
  }

  .modal-dialog {
    max-height: 50vh;
  }

  .modal-content {
    width: 200%;
    height: 85%;
    overflow-y: auto;

    @media (min-width: 700px) and (max-width: 1000px) {
      width: 140%;
      height: 85%;
    }

    @media (max-width: 700px) {
      width: 100%;
      height: 85%;
    }
  }
}

.card-not-mentioned {
  background-color: #f0f0f082;
}

.recording-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__header{
      padding-left: 20px;
    }

    &__speaker {
      font-weight: 500;
    }

    &__title-and-date {
      display: flex;
      align-items: baseline;
      font-weight: 600;
    }

    &__summary-title-and-date {
      display: flex;
      align-items: baseline;
      font-weight: 600;
      padding-top: 10px;
    }

    &__custom-link {
      cursor: pointer;
      color: #597BF2 !important;
      padding: 0;
      font-weight: 700;
      margin-left: 5px;

      &.nav-link {
        padding: 0;
      }
    }

    &__summary-link {
      cursor: pointer;
      color: #597BF2 !important;
      padding: 0;
      font-weight: 700;

      &.nav-link {
        padding: 0;
      }
    }

    &__summary-show-sentences {
      cursor: pointer;
      font-weight: bold;
    }

    &__first-sentence {
      flex-direction: column;
    }

    &__summary-sentences {
      min-width: 845px;
      background-color: #F5F5F5;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__performance-count {
      background-color: #D9D9D9;
      margin-left: 5px;
      border-radius: 4px;
      width: 135px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 600;
    }

    &__count {
      background-color: #D9D9D9;
      margin-left: 5px;
      border-radius: 4px;
      width: 15px;
      height: 13px;
      display: flex;
      justify-content: center;
      font-size: 10px;
    }

    &__owner-name {
      font-weight: 500;
      font-style: italic;
      font-size: 13px;
      margin-left: 5px !important;
    }

    &__not-mentioned {
      font-weight: 600;
      font-size: 13px;
      margin-left: 5px !important;
    }

    &.card-body {
      padding: 0;
    }
}

.total-widget {
    &__description{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      font-weight: 600;
      margin-top: 60px;
    }

    &__number {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      font-size: 45px;
    }
}

.stats-preview-img {
  height: 60px;
  width: 90px;
  margin-left: 10px;
}

.stats-no-picture {
  background-color: $gray-200;
  border: 2px solid #0000001c;
  border-radius: 4px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 95px;
  height: 60px;
  margin: 5px;
}

.modal-container {
  display: flex;
  overflow: hidden;
}

.widget-chart-no-data {
  display: flex;
  justify-content: center;
  color: #bebebe;
  font-weight: 500;
}

.total-widget-no-data {
  display: flex;
  flex-direction: column;
}

.burger-menu-content {
  position: absolute;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1000;
  display: block;
  top: 40px;
  cursor: pointer;
}

.burger-menu-content > * {
  margin: 5px 0;
}

.widget-options-icons {
  font-size: 20px;
}

.burger-menu-item {
  padding: 5px 10px;
  cursor: pointer;
}

.burger-menu-item:hover {
  background-color: #f0f0f0;
}
