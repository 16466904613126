.empty-profile {
    width: 225px;
    height: 225px;
    background: #DFDFDF;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media(max-width: 1400px) {
        width: 170px;
        height: 170px;
    }
    span {
        font-size: 166px;
        line-height: 225px;
        text-transform: uppercase;
        @media(max-width: 1400px) {
            margin-top: 25px;
            font-size: 106px;
            line-height: 170px;
        }
    }
}

.signle-user {
    width: 32px;
    height: 32px;
    background: #DFDFDF;
    position: relative;
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
    p {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        position: absolute;
        text-transform: uppercase;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 0 auto !important;
        color: #495057 !important;
        opacity: 1 !important;
    }
}

.single-user-chat {
    p {
        top: 6px;
    }
}

.bot-chat-image {
    background-color: #34C38F;
    p {
        text-transform: lowercase;
    }
}

.meeting-img {
    max-width: 200px;
    height: auto;
    margin-right: 20px;
}

.meetings-tab {
    .mbg-btn{
        width: 100px;
    }
    .checkbox {
        margin: 15px auto;
        margin-top: 0;

        label {
            position: relative;
            min-height: 25px;
            padding-right: 30px;
            margin-bottom: 0;
            font-weight: normal;
            padding-left: 15px;
            cursor: pointer;
            display: flex;
            align-items: center;

            span {
                top: 50%;
                transform: translateY(-50%);
            }

            &:before {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                width: 22px;
                height: 22px;
                transition: transform 0.28s ease;
                background: #f5f5f5;
            }

            &:after {
                content: "";
                display: block;
                width: 10px;
                height: 5px;
                border-bottom: 2px solid #0045CA;
                border-left: 2px solid #0045CA;
                transform: rotate(-45deg) scale(0);
                transition: transform ease 0.25s;
                position: absolute;
                top: 7px;
                right: 6px;
            }
        }

        input[type="radio"],
        input[type="checkbox"] {
            width: auto;
            opacity: 0.00000001;
            position: absolute;
            left: 0;
            margin-left: -20px;

            &:checked~label {
                &:after {
                    transform: rotate(-45deg) scale(1);
                }
            }

            &:focus+label::before {
                outline: 0;
            }
        }
    }

    .shared-title {
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        text-align: center;
        border-bottom: 1px solid #e7e7e7;
        padding: 10px;
        margin-bottom: 35px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .teams-title {
        font-family: Gilroy;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #3a3a3a;
    }

    .view-btn {
        background: #ebebeb;
        border-radius: 5px;
        padding: 2px 10px;
        margin-right: 10px;
    }

    div.shared-with-wrapper {
        margin: 40px 0;
        width: 100% !important;
    }

    @media (max-width: 991px) {
        .border-tag {
            border-bottom: 1px solid #e7e7e7;
            margin-bottom: 20px;
            margin-left: 25px;
            margin-right: 30px;
            width: 100%;
        }
    }

    .shared-wrapper {
        padding: 0px 50px;

        &.first {
            border-right: 1px solid #e7e7e7;
        }

        @media (max-width: 991px) {
            border-right: 0px !important;
        }

        h5 {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #3a3a3a;
            text-align: left;
            margin-bottom: 15px;
        }

        .right-view {
            display: flex;
            align-self: center;
        }
    }

    .mdi {
        color: #c4c4c4;
    }

    .team-row {
        display: flex;
        justify-content: space-between;

        h5 {
            color: #6d6d6d;
            width: 70%;
            word-break: break-all;
            text-transform: capitalize;
        }

        p {
            font-size: 8px;
            color: #9d9d9d;
            width: 40px;
        }
    }

    .small-tital {
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #8e8e8e;
        display: inline-block;
        padding-right: 15px;
    }

    .checkbox-main {
        display: inline-block;
        width: 100%;
        margin: 10px 0px 10px;

        .checkbox {
            display: inline-block;
            vertical-align: top;
            margin: 0px;
            width: 100%;

            .label-for-edit {
                top: -8px;
                padding-left: 35px;
                text-transform: capitalize;
            }

            .radio {
                display: inline-block;
            }
        }
    }

    .btn-done {
        width: 96px;
        height: 39px;
        background: #303030;
        border-radius: 5px;
        color: #fff;
        font-family: Gilroy;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        border: 1px solid #303030;
        margin-top: 10px;

        &:hover,
        &:focus {
            background-color: #fff;
            color: #303030 !important;
        }
    }
}

.bg-center {
    display: flex;
    align-items: center;
    margin-left: 15px
}

.picker-wrapper {
    position: absolute;
    left: -265px;
    z-index: 99999;
    top: 25px;
}


.picker-background {
    width: 30;
    height: 30;
    border-radius: 5;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
}

.custom-layout-picker {
    width: 200px;
}

.bg-width {
    min-width: 125px;
}

.btn-width {
    min-width: 100px;
}

.light-fontweight-table {
    tr {
        font-weight: normal;
        opacity: 1;
    }
}

.keyword-phrases {
    max-width: 350px;
    white-space: normal !important;
}

.settings-page{
  .nav-link.active {
      color: #556ee6;
      font-weight: 500;
  }

  .nav-link.active:hover {
      color: #556ee6;
  }
  &.page-content {
      padding: calc(70px + 24px) calc(24px / 2) 150px calc(24px / 2);
      padding-top: 15px;
  }
}
.resource-wrapper {
    background: #F9F9F9;
    padding-left: 25px;
    padding-top: 24px;
    padding-right: 0;
    padding-bottom: 5px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 20px;
}
.add {
    position: relative;
    overflow: hidden;
    background: #FFF;
    width: 125px;
    height: 90px;
    align-items: center;
    justify-content: center;
    display: inline-grid;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin-right: 20px;
    margin-bottom: 20px;
    p {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 0;
    }
    span {
        font-weight: 600;
        font-size: 12px;
        line-height: 10px;
        padding-bottom: 2px;
        width: 108px;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: 99;
    }
    iframe {
        margin-top: 5px;
        border: none;
        margin-bottom: 5px;
    }
}
.google-btn {
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgba(0, 0, 0, 0.54) 0px 2px 2px 0px, rgba(0, 0, 0, 0.54) 0px 0px 1px 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
}
.resource-document{
    height: 58px;
    width: 108px;
    overflow: hidden;
    canvas {
        width: 100%;
    }
}
.table-resources {
    .table-nowrap th, .table-nowrap td {
        white-space: initial;
    }
}

.filter-invert img {
  filter: invert(0);
  transition: filter .15s ease-in-out;
}
.filter-invert:hover img {
  filter: invert(1);
}

.analytic-tabs {
    padding: 6px;
    display: flex;
    justify-content: space-evenly;

    .btn {
        width: 100%;
        box-shadow: none !important;

        &.active {
            color: #00B388 !important;
            box-shadow: none !important;
        }
    }
}

.settings-analytic-keyword {
  font-size: 15px;
  margin-left: 12px;
  color: $red-error;
}

.keyword-close-button {
  position: absolute;
  right: 0;
  width: 30px;
  margin-right: 7px;
  border: none;
  border-radius: 5px;
  background-color: transparent;
}

.keyword-modal {

  .modal-content {
    height: auto;

    .select2-selection__value-container {
      div, .select2-selection__input, input {
        width: 100%;
      }
    }
  }

  &.modal-dialog {
    display: flex;
    justify-content: center;
  }
}

.keyword-label {
  padding-top: 10px;
}

.keyword-input {
  width: 400px;
}

.keyword-language {
    margin-bottom: 20px;
    &__radio-wrapper {
        display: flex;
        justify-content: flex-start;
        width: 60%;

        &__radio-button {
            margin-right: 4px;
            margin-left: 2px;
            cursor: pointer;

            &:checked {
                border-color: #007bff;
            }

            &:focus {
                outline: none;
            }
        }

        &__radio-label {
            cursor: pointer;
            text-align: center;
            margin-bottom: 0 !important;
        }
    }

    &__label {
        margin-bottom: 0;
    }
}

.phrases-and-search {
  display: flex;
  align-items: center;
}

.search-button {
  height: 40px;
  flex-grow: 0;
  background-color: #00B388;
}

.save-button {
  width: 100px;
  height: 40px;
  flex-grow: 0;
}

.keywords-table {
  margin-top: 15px;
}

.sentence-link{
  font-size: 14px;
  font-weight: 500;
  color: #597BF2;
  padding: 0;
}

.saved-success {
  color: #00B388 !important;
}

.keyword-title {
  font-weight: 500;
  font-size: 15px;
}

.keyword-error-message {
  color: $red-error;
  margin-top: 2px;
}

.nav-accordion {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 2px;
  margin-left: 13px;
}

.nav-items {
  margin-left: 15px;
}

.keywords-group-close-button {
  background-color: transparent;
  border: 0;
}

.keywords-group-input{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.keywords-group-form {
  display: flex;
  flex-direction: column;

  .error {
    color: $red-error;
  }
}

.participant-tag {
  border-radius: 10px;
  background-color: #d6d6d6;
  color: #000;
  width: fit-content;
  padding: 5px;
  margin-right: 2px;
}

.search-fetch {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
}

.search-fetch > input {
  width: 500px;
}

.past-meeting {
  background-color: #D3D3D3;
}

.keyword-switch {
    margin-bottom: 20px;

    .switch {
        margin-bottom: 20px;
        &__wrapper {
            &__switch-button {
                width: 50px;
                height: 23px;

                .slider:before {
                    width: 15px;
                    height: 15px;
                }
            }

        }
    }
}

.radioWrapper {
    legend {
        font-size: 14px;
    }

    .form-control {
        .form-check {
            input {
                bottom: 5px;
            }
        }

        .form-group {
            display: flex;
            margin-left: 20px;
            margin-bottom: 0;
        }
    }
}

.account-token-button {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}

.lynes-modal-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  line-height: 20px;
}

.lynes-body {
  padding: 10px 40px 20px 40px !important;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .modal-loading {
    text-align: center;
    margin-top: -4rem;
  }
}

.lynes-modal-icon {
  margin: 0 5px;
  cursor: pointer;

}

.telenor-modal-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  line-height: 20px;
}

.telenor-body {
  padding: 10px 40px 20px 40px !important;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .modal-loading {
    text-align: center;
    margin-top: -4rem;
  }
}

.telenor-modal-icon {
  margin: 0 5px;
  cursor: pointer;
}

.url-link {
  display: flex;
  height: 30px;
  cursor: pointer;
}

.token-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-word;
  cursor: pointer;
}

.lynes-token, .telenor-token {
  display: flex;
}

.account-token-table-token-cell {
  .lynes-modal-icon, .telenor-modal-icon {
    visibility: hidden;
  }

  &:hover .lynes-modal-icon, &:hover .telenor-modal-icon {
    visibility: visible;
  }
}

.copy-notification {
  color: #52be4f;
  margin-left: 10px;
  font-weight: 600;
}

.lynes-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin: 16px 26px;
  font-weight: 500;
}

.telenor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin: 16px 26px;
  font-weight: 500;
}

.telavox-integration {
  &__header {
    display: flex !important;
    justify-content: space-between !important;
  }

  &__body {
    padding: 0 1rem 2rem 1rem !important;
    height: 450px;
    display: flex;
    flex-direction: column;

    &--title {
      font-size: 1rem;
    }

    &--secret {
      width: 100%;
      height: 100%;
      padding: 1rem;
      box-sizing: border-box;
      border: 1px solid #ccc;
      outline: none;
    }

    &--input {
      margin-top: 10px;
      border-radius: 0;
    }

    &--save-button {
      margin: 10px 0 !important;
      background-color: #34c38f;
      box-shadow: none !important;
      border: none !important;
    }

    .infracom-card-number-missing-error {
      margin-top: -25px;
    }
  }
}

.destiny-integration {
    &__header {
        display: flex !important;
        justify-content: space-between !important;
    }

    &__body {
        padding: 0 1rem 0 1rem !important;
        height: 475px;
        display: flex;
        flex-direction: column;

        &--title {
            font-size: 14px;
        }

        &--username, &--secret, &--domain, &--duration {
            width: 70%;
        }

        &--save-button {
            margin: 10px 0 !important;
            background-color: #34c38f;
            box-shadow: none !important;
            border: none !important;
        }

        .infracom-card-number-missing-error {
            margin-top: -25px;
        }
    }
}

.hitrate-integration {
    &__header {
        display: flex !important;
        justify-content: space-between !important;
    }

    &__body {
        padding: 0 1rem 0 1rem !important;
        height: 400px;
        display: flex;
        flex-direction: column;

        &--title {
            font-size: 14px;
        }

        &--dialer, &--secret, &--duration {
            width: 70%;
        }

        &--save-button {
            margin: 10px 0 !important;
            background-color: #34c38f;
            box-shadow: none !important;
            border: none !important;
        }

        .infracom-card-number-missing-error {
            margin-top: -25px;
        }
    }
}

.aircall-integration {
    &__header {
        display: flex !important;
        justify-content: space-between !important;
    }

    &__body {
        padding: 0 1rem 0 1rem !important;
        height: 400px;
        display: flex;
        flex-direction: column;

        &--title {
            font-size: 14px;
        }

        &--api-id, &--api-token, &--duration {
            width: 70%;
        }

        &--save-button {
            margin: 10px 0 !important;
            background-color: #34c38f;
            box-shadow: none !important;
            border: none !important;
        }

        .infracom-card-number-missing-error {
            margin-top: -25px;
        }
    }
}

.right {
  right: 0;
}

.meetric-engage-option {
  display: flex;
  align-items: center;
  margin: 0 0 20px 20px;
}
.meetric-engage-option .form-check-label {
  margin-bottom: 0;
}

.crm-integration {
  &__header {
    display: flex !important;
    justify-content: space-between !important;
  }

  &__body {
    padding: 0 1rem 2rem 1rem !important;
    display: flex;
    flex-direction: column;

    &--title {
      font-size: 1rem;
      margin: 5px 0 0 0;
    }

    &--input {
      margin-top: 10px;
      border-radius: 0;
    }
  }

  &__button-div {
    display: flex;
    flex-direction: row-reverse;

    &--save-button {
      margin: 10px 0 !important;
      background-color: #34c38f;
      box-shadow: none !important;
      border: none !important;
      width: 100px;
    }
  }
}

.error-message {
  background-color: rgb(240, 193, 193);
  border-color: red;
  border-radius: 4px;
  width: 400px;
  padding: 10px;
  text-align: center;
  font-weight: 500;
}

.settings-agent-table {
    overflow-x: auto;
}

.settings-checkbox-row {
  margin: 10px 0;
  .settings-checkbox {
    padding-left: 0;
    label {
      margin-top: 3px;
    }
  }
}

.settings-agent-actions {
    display: flex;
    justify-content: flex-start;

    &--burger {
        display: flex;
        align-content: center;
        align-items: center;
        width: 25px;

        .dropdown__toggle {
          padding: 0 10px;
        }

        &__content {
            position: absolute;
            right: 0;
            background-color: white;
            border: 1px solid #ccc;
            border-radius: 5px;
            z-index: 1000;
            display: block;
            top: 25px;
            cursor: pointer;

            &.bottom {
                top: initial;
                bottom: 25px;
            }

            &.middle {
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }

        &__item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
            gap: 5px;
            width: 100%;
            padding: 8px 11px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover,
            &:active {
                background-color: #f0f0f0;
            }

            i {
                width: 17px;
                height: 12px;
            }
        }
    }
}

.playbook-generation-modal {
    .modal-content {
        padding: 5px 10px;
    }

    &__header {
        font-weight: bold;
        font-size: 16px;
    }

    &__body {
        display: flex;
        flex-direction: column;
    }

    &__label {
        font-weight: bold;
        margin: 10px 0 26px 0;
    }

    &__alert {
        width: 250px !important;
        margin: 0;
        padding: 0 !important;
        max-height: 15px !important;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &__description {
        border: none;
        outline: none;
        width: 100%;
        height: 250px;
        padding: 12px 20px;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: #f8f8f8;
        resize: none;
    }

    &__loader {
        display: flex;
        align-items: center;
    }
}

.dynamics-link {
    color: #58704E;
    font-weight: bold;
    text-decoration: underline;
}

.dynamics-link:hover {
    font-weight: bolder;
    cursor: pointer;
}

.dynamics-modal {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        padding: 5px 14px !important;
        margin: 5px 0 15px 0;
    }

    &__body {
        height: 450px;
        padding: 10px 15px 20px 15px !important;
    }

    &__msg-icon {
        padding: 0 5px 0 0;
    }
}

.deals-settings {
  .collapsible-options {
    margin-top: 10px;
  }

  .select-deals-closed {
    .row {
      margin: 10px -12px;
    }

    &.loading {
      opacity: 0.5;
    }

    .select-deals-closed-spinner {
      position: absolute;
      left: calc(50% - 10px);
      top: calc(50% - 10px);
      z-index: 1;
    }
  }
}

.crm-meeting-type {
    &__crm-switch {
        margin-bottom: 20px;

        .switch {
            margin-bottom: 20px;
            &__wrapper {
                &__label {
                    font-size: 14px;
                }
                &__switch-button {
                    width: 50px;
                    height: 23px;

                    .slider:before {
                        width: 15px;
                        height: 15px;
                    }
                }

            }
        }
    }
}

.calendar-preview-table {
    &__event-name:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.destiny-extension-id-instructions {
  margin: 1rem 0;
}

.import-only-crm-calls-wrapper {
  .switch__wrapper {
    height: 100%;
    transform: translateY(25px);

    @media(max-width: 991px) {
      justify-content: space-around;
    }

    @media(min-width: 992px) {
      label {
        max-width: 300px;
      }
    }
  }
}

.destiny-tabs.nav-tabs {
  margin-top: 0;
  margin-bottom: 1.5rem;

  .nav-item a {
    padding: 1rem;

    &:hover {
      background-color: #EEE;
    }
    &.current {
      background-color: #E6E6E6;
    }
  }
}

#call-import-time-from:disabled,
#call-import-time-to:disabled {
  color: lightgray;
}

.private-numbers-list {
  display: flex;
  margin: 5px;
  gap: 20px;

  .private-numbers-sublist {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 50%;

    .private-phone-number {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid lightgray;
      padding: 0 5px 0 10px;
      font-size: 12pt;
      width: 100%;

      button {
        width: unset;
      }
    }
  }
}
