.snippet-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 300px;
  position: relative;
}

.video-icon {
  width: 132px;
  height: 120px;
}

.create-snippet-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headline-text {
  height: 52px;
  margin-bottom: 10px;
}
