.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.radio-toolbar label {
    display: inline-block;
    background-color: $gray-300;
  cursor : pointer;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
}
.radio-toolbar input[type="radio"]:checked + label {
    background-color:$primary;
    border : none;
    color : $white;
}

#radio-title
{
  margin-bottom : 5px
}

.loader-container{
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.loader{
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  position: absolute;
}