.call-import-modal {

  &__buttons {
    display: flex;
    float: right;
    padding: 10px 5px;
    margin-top: 25px;

    button {
      padding: 10px 20px;
    }
  }

  .token-select{
    option {
      padding: 20px;
    }
  }
}

.call-import-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  height: 100px;
  position: relative;
}

