.context-menu{
    width: 240px;
    background: white;
    z-index: 1000;
    border-radius: 2px;
    border: 1px solid lightgrey;

    .context-menu-item{
        cursor: pointer;
        padding: 10px;

        p{
            margin: 0;
        }

        &:hover{
            background: lightgrey;
        }
    }
}