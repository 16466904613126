.card_integration {
  justify-content: center;
  align-items: center;
  border-radius: 0;
  border: 1px solid #e1e1e1;
  box-shadow: none;
  width: 310px;
  min-height: 240px;
  text-align: center;
  margin: 0;

  .card-header {
    padding: 0.5rem 0 0.5rem 0;
    font-size: 14px;
    color: black;
    font-weight: 450;
    background-color: transparent;
    width: 100%;
    height: 70px;

    img {
      margin-right: 5px;
    }

    hr {
      margin: 0.25rem 0 0.25rem 0;
      color: #fafafa;
    }

    .card-subtitle {
      font-size: 10px;
      color: #7f7f7f;
      margin: 0.25rem 0 0.25rem 0;
    }
  }

  .card-body {
    width: 100%;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    height: 250px;

    .images img {
      margin: 5px;
    }

    .finished-integration {
      display: flex;
      margin-top: 10px;

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        font-size: 25px;
        color: #00b388;

        &:last-child {
          margin-right: auto;
        }
      }

      &-disconnect {
        margin: auto auto auto 5px;
        padding: 4px 6px;
        background-color: #ff0000;
        color: #fff;
        border-radius: 5px;
      }
    }

    .additional-notice {
      margin-top: 15px;
      font-size: 11px;

      &.with-checkmark {
        margin-top: 10px;
      }
    }
  }

  .card-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &.salesforce-card {
    opacity: 0.5;
  }

  img.img-center {
    margin-top: 40px;
  }

  &-switch {
    cursor: default;
    display: flex;
    justify-content: center;
    align-content: center;

    .switch {
      padding-top: 15px;
      width: 100%;
      border-top: 1px solid #e1e1e1;
      margin-bottom: 20px;

      &__wrapper {
        &__label {
          margin-bottom: 0;
        }

        &__switch-button {
          width: 50px;
          height: 23px;
          margin-bottom: 0;

          .slider:before {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    .dynamics365-switch-label {
      text-wrap: nowrap;
      max-width: 230px;
      padding-left: 50px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.grayed-out {
    pointer-events: none;
    filter: grayscale(1);
    opacity: 0.8;
  }
}

.integration-google__modal {
  .modal-content {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 0;
    background-color: #fff;
  }
  .modal-header {
    border-bottom: none;
    display: block !important;
    .back-button {
      float: right;
      padding: 7px 30px;
      background-color: #373737;
      color: white;
      border: none;
      border-radius: 3px;
    }
  }

  .modal-title {
    display: inherit;
    img {
      &.modal-title__google-img {
        margin-left: 65px;
      }

      &.modal-title__outlook-img {
        margin-left: 50px;
      }
    }

    h2 {
      font-weight: 500;
      color: #000;
      border-bottom: none;
      margin-left: 10px;
      text-align: center;
    }
    &.chrome-extension {
      margin-left: 15px;
    }
  }

  .modal-body {
    padding: 1rem 5rem;
    padding-bottom: 2rem;
    .row {
      margin-top: 20px;
      .googleIntegrations-card {
        span {
          border-bottom: 1px solid #fafafa;
        }
        .title {
          text-align: center;
        }
      }

      .number {
        background-color: #00b388;
        border-radius: 50%;
        min-width: 40px;
        max-height: 40px;
        text-align: center;
        line-height: 40px;
        font-weight: bold;
        font-size: 22px;
        color: #000;
      }
      h5 {
        color: #000;
        padding-top: 10px;
        margin-left: 20px;
        font-weight: bold;
      }

      .googleIntegrations-card {
        .title-card {
          padding: 5px;
        }
        .title {
          font-size: 14px;
          color: black;
          font-weight: 450;
          background-color: #fff;

          &.chrome-extension {
            margin-left: 10px;
          }
        }
        .under-title {
          text-align: center;
          font-size: 10px;
          color: #282828;
          border-top: 1px solid #f3f3f3;
          width: 100%;

          p {
            margin-top: 5px;
          }
        }

        .images {
          background-color: #fafafa;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .images-grid {
          display: grid;
          grid-template: repeat(2, 1fr) / repeat(2, 1fr);
          gap: 20px;
          width: 100%;
          &.chrome-extension {
            gap: 5px !important;
          }

          img.upsales-img {
            margin-top: 25px;
          }
        }
      }

      a.meetric-link {
        background: #f3f3f3;
        padding: 8px 20px;
        text-align: center;
        margin-left: 50px;
        color: #005ac4;
        font-weight: 600;
      }
    }
  }

  .protip {
    margin-top: 30px;
    border: 1px solid #f3f3f3;
    background: #f3f3f3;
    padding: 15px;
    h4 {
      color: #00b388;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      a.green-link {
        color: #00b388;
        text-decoration: underline;
      }
    }
  }

  .container {
    display: flex;
    .text {
      margin-left: 20px;
      max-width: 350px;
      h5 {
        font-weight: 600;
        color: #000;
      }
      p {
        font-size: 15px;
        font-weight: 600;
        color: #8a8a8a;
      }
    }
  }
}

.enhance-text {
  color: $color-blue;
  font-weight: 600;
  font-size: 16px;
}

.integration-cards {
  gap: 2rem;
  justify-content: center;
}

.integrations-profile.row {
  justify-content: center;

  .col {
    padding: 50px;
  }

  &:not(:last-child) .col {
    padding-bottom: 20px;
  }

  .row.integration-cards.card-group {
    justify-content: center;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 25px;
  }
}

@media (min-width: 576px) {
  .row.mobile {
    flex-wrap: nowrap;
  }
}

@media (max-width: 480px) {
  .integration-cards {
    justify-content: center;
  }

  .card_integration.card {
    width: 250px;
  }
  .row.mobile {
    flex-wrap: nowrap;
  }

  h2.modal-title {
    font-size: 16px;
  }

  .row.integration-cards.card-group {
    padding-left: 0;
  }
}

.card_integration .card-footer {

  &.integration-buttons {
    justify-content: flex-end;
  }

  .integration-type-badge {
    padding: 2px 5px;
    background-color: #f3f3f3;
    color: #282828;
    border-radius: 2px;
    font-weight: bold;
    position: absolute;
    left: 8px;
    bottom: 10px;
  }
}
