// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: rgba(71, 88, 114, 0.1) 0px 2px 6px;
}

.card-selectable:hover {
  cursor: pointer;
  opacity: 0.7;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

.room-analytics-card {
  position: absolute;
  bottom: 4px;
  left: 4px;
  z-index: 100;
  background-color: #fff;
  border-radius: 4px;
  font-weight: bold;
  padding: 4px 8px;
}

.room-analytics-card * {
  vertical-align: middle;
}
