.filter-query-builder {
    /* react-querybilder styles override*/
    .ruleGroup {
        font-size: 13px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        border-color: rgba(0, 0, 0, 0.1);
        border-style: dashed;
        border-radius: 0.25rem;
        border-width: 1px;
        background-color: #F3F3F3;
    }

    .ruleGroup-remove {
        margin-left: 30px;
        padding: 5px 15px;
        background-color: crimson;
        font-weight: bolder;
        border-radius: 50%;
        content: 'Remove'
    }

    .ruleGroup-header {
        width: 100%;
        justify-content: space-between;

        @media (max-width: 700px) {
            align-items: flex-start !important;
            flex-direction: column-reverse;
        }
    }

    .queryBuilder {
        min-height: 400px;
        max-height: 800px;
        overflow-y: auto;
        padding-bottom: 30px;
        padding-right: 0;
    }

    .ruleGroup-body .rule {
        @media (max-width: 650px) {
            align-items: flex-start !important;
            flex-direction: column;
        }
    }

    .ruleGroup .ruleGroup-body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .ruleGroup .ruleGroup-body:empty {
        display: none;
    }
    .ruleGroup .ruleGroup-header,
    .ruleGroup .rule {
        display: flex;
        gap: 1rem;
        align-items: center;
    }
    .ruleGroup .rule .rule-value:has(.rule-value-list-item) {
        display: flex;
        gap: 1rem;
        align-items: baseline;
    }

    [data-inlinecombinators=disabled] .dndOver.rule, [data-inlinecombinators=disabled] .dndOver.ruleGroup-header {
        border-bottom-width: 2px;
        border-bottom-style: dashed;
        border-bottom-color: rebeccapurple;
        padding-bottom: 0.5rem;
    }
    [data-inlinecombinators=disabled] .dndOver.rule.dndCopy, [data-inlinecombinators=disabled] .dndOver.ruleGroup-header.dndCopy {
        border-bottom-color: #669933;
    }

    [data-inlinecombinators=enabled] .dndOver.rule:last-child, [data-inlinecombinators=enabled] .dndOver.ruleGroup-header, [data-inlinecombinators=enabled] .dndOver.rule + .betweenRules, [data-inlinecombinators=enabled] .dndOver.betweenRules {
        border-bottom-width: 2px;
        border-bottom-style: dashed;
        border-bottom-color: rebeccapurple;
        padding-bottom: 0.5rem;
    }
    [data-inlinecombinators=enabled] .dndOver.rule:last-child.dndCopy, [data-inlinecombinators=enabled] .dndOver.ruleGroup-header.dndCopy, [data-inlinecombinators=enabled] .dndOver.rule + .betweenRules.dndCopy, [data-inlinecombinators=enabled] .dndOver.betweenRules.dndCopy {
        border-bottom-color: #669933;
    }

    .ruleGroup.dndDragging,
    .rule.dndDragging {
        opacity: 0.5;
    }
    .ruleGroup .queryBuilder-dragHandle,
    .rule .queryBuilder-dragHandle {
        cursor: move;
    }

    .queryBuilder-branches .ruleGroup-body {
        margin-left: calc(2 * 0.5rem);
    }
    .queryBuilder-branches .rule,
    .queryBuilder-branches .ruleGroup .ruleGroup {
        position: relative;
    }
    .queryBuilder-branches .rule::before, .queryBuilder-branches .rule::after,
    .queryBuilder-branches .ruleGroup .ruleGroup::before,
    .queryBuilder-branches .ruleGroup .ruleGroup::after {
        content: "";
        width: 0.5rem;
        left: calc(-0.5rem - 1px);
        border-color: #8081a2;
        border-style: solid;
        border-radius: 0;
        position: absolute;
    }
    .queryBuilder-branches .rule::before,
    .queryBuilder-branches .ruleGroup .ruleGroup::before {
        top: -0.5rem;
        height: calc(50% + 0.5rem);
        border-width: 0 0 1px 1px;
    }
    .queryBuilder-branches .rule:last-child::before,
    .queryBuilder-branches .ruleGroup .ruleGroup:last-child::before {
        border-bottom-left-radius: 0.25rem;
    }
    .queryBuilder-branches .rule::after,
    .queryBuilder-branches .ruleGroup .ruleGroup::after {
        top: 50%;
        height: 50%;
        border-width: 0 0 0 1px;
    }
    .queryBuilder-branches .rule:last-child::after,
    .queryBuilder-branches .ruleGroup .ruleGroup:last-child::after {
        display: none;
    }
    .queryBuilder-branches .ruleGroup .ruleGroup::before, .queryBuilder-branches .ruleGroup .ruleGroup::after {
        left: calc(calc(-0.5rem - 1px) - 1px);
    }
    .queryBuilder-branches .ruleGroup .ruleGroup::before {
        top: calc(-0.5rem - 1px);
        height: calc(50% + 0.5rem + 1px);
    }
    .queryBuilder-branches .ruleGroup .ruleGroup::after {
        height: calc(50% + 1px);
    }
    .queryBuilder-branches .betweenRules {
        position: relative;
    }
    .queryBuilder-branches .betweenRules::before {
        content: "";
        width: 0.5rem;
        left: calc(-0.5rem - 1px);
        border-color: #8081a2;
        border-style: solid;
        border-radius: 0;
        position: absolute;
        top: -0.5rem;
        height: calc(100% + 0.5rem);
        border-width: 0 0 0 1px;
    }


    /* custom select */
    .combinator-select {
        display: grid;
        grid-template-columns: 200px 1fr;
        font-weight: bold;

        .combinator-select-row {
            flex: 1;
        }

    }

    /* custom select */
    .operator-select {
        display: grid;
        grid-template-columns: 100px 1fr;

        .operator-select-row {
            flex: 1;
        }

    }

    /* custom select */
    .field-select {
        display: grid;
        grid-template-columns: 300px 1fr;

        .field-select-row {
            flex: 1;
        }

        .custom-react-querybuilder__menu-list {
            max-height: 200px;
        }
    }
    /* custom select */
    .value-select {
        display: grid;
        grid-template-columns: 100px 1fr;

        .value-select-row {
            flex: 1;
        }

    }

    .combinator-select,
    .operator-select,
    .field-select,
    .value-select {
        .select2-selection__menu-list {
            padding: 10px;
            max-height: 200px;
            overflow: auto;
        }

        .custom-react-querybuilder__single-value {
            top: 53% !important;
            position: absolute;
            color: #333;
        }

        @media (max-width: 650px) {
            grid-template-columns: 250px 1fr;
        }
    }
}

/*# sourceMappingURL=query-builder.css.map */
