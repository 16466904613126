table.loadingTable {
	width: 100%;
	tr {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		td {
			height: 50px;
			vertical-align: middle;
			padding: 8px;
			span {
				display: block;
			}
			&.tdtext {
				// padding-right: 100px;
				span {
					width: 50%;
					height: 12px;
					background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
					background-size: 500px 100px;
				}
			}
		}
	}
}
