.record-live-meeting {
    &__header {
        display: flex;
        gap: 30px;
        justify-content: center;
        align-items: center;
        padding: 15px 25px;
        background-color: white;

        .button-tooltip-container {
            position: relative;

            &[data-tooltip] {
                &:after {
                    position: absolute;
                    top: 105%;
                    background-color: white;
                    color: black;
                    border: 1px solid black;
                    padding: 5px;
                    padding-top: 7px;
                    content: attr(data-tooltip);
                    font-family: "Gilroy", sans-serif;
                    font-weight: normal;
                    width: max-content;
                    transition: opacity 0.75s ease-in-out;
                    -webkit-transition: opacity 0.75s ease-in-out;
                    z-index: 1;
                    display: none;

                    left: calc(50% - 70px);
                }

                &.tooltip-width-22:after {
                    left: calc(50% - 67px);
                }

                &.tooltip-width-24:after {
                    left: calc(50% - 70px);
                }

                &.tooltip-width-41:after {
                    left: calc(50% - 130px);
                }

                &.tooltip-width-49:after {
                    left: calc(50% - 150px);
                }

                &.tooltip-width-62:after {
                    left: calc(50% - 200px);
                }
            }

            &[data-tooltip]:hover:after {
                display: unset;
            }
        }

        button.recording-button {
            width: 150px;
            height: 50px;
            font-size: 16px;
            background-color: transparent;
            color: #808080;
            border: 1px solid #d0d0d0;
            border-radius: 0.25rem;

            &:hover:not([disabled]) {
                background-color: darken(white, 5%);
            }

            &[disabled] {
                color: #d3d3d3;
                border-color: #d3d3d3;
            }

            .spinner-border {
                vertical-align: -0.5em;
            }

            &.start-new-meeting {
                width: 200px;
            }
        }

        &.recording {
            background-color: $success;

            button.recording-button {
                color: white;

                &:hover {
                    background-color: lighten($success, 5%);
                }
            }
        }
    }

    &__title {
        margin: 0;
    }

    &__content {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
        align-items: flex-start;
        margin: 45px;
        padding-bottom: 45px;

        &.meeting-selected {
            align-items: flex-start;
        }

        .switch__wrapper__label {
            display: flex;
            align-items: center;

            .choose-audio-input {
                padding: 10px;
                margin-right: -10px;
                background-color: transparent;
                border: none;

                &:hover, &:focus {
                    background-color: transparent;
                    border: none;
                    outline: none;
                }

                i {
                    color: black;
                }
            }

            .choose-audio-input-menu {
                min-width: fit-content;

                .dropdown-item {
                    padding: 10px 20px;

                    i {
                        padding: 0 5px;
                    }
                }
            }
        }
    }

    &__recording-in-progress-preview {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .video-switches {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 20px;

            .switch-warning-icon {
                margin-left: 5px;
                color: $red;
                position: relative;

                &[data-tooltip]:after {
                    background-color: white;
                    color: black;
                    border: 1px solid black;
                    padding: 5px;
                    padding-top: 7px;
                    content: attr(data-tooltip);
                    font-family: "Gilroy", sans-serif;
                    font-weight: normal;
                    position: absolute;
                    left: -50px;
                    bottom: 110%;
                    width: max-content;
                    transition: opacity 0.75s ease-in-out;
                    -webkit-transition: opacity 0.75s ease-in-out;
                    z-index: 1;
                    display: none;
                }

                &[data-tooltip]:hover:after {
                    display: unset;
                }
            }

            .audio-level-container {
                width: 5px;
                height: 40px;
                border: 1px solid lightgreen;
                border-radius: 5px;
                position: relative;
                margin-right: -8px;

                .audio-level-indicator {
                    width: 5px;
                    border-radius: 5px;
                    background-color: lightgreen;
                    position: absolute;
                    bottom: -1px;
                    left: -1px;
                }
            }

            .audio-level-placeholder {
                width: 5px;
                height: 40px;
                visibility: hidden;
            }
        }

        .video-warning {
            padding: 10px 20px;
            padding-top: 12px;
            background-color: palegoldenrod;
            border: 1px solid darken(palegoldenrod, 10);
            border-radius: 5px;
        }

        .meeting-video {
            display: none;
        }

        .video-preview {
            position: relative;

            canvas {
                background-color: black;
                width: 100%;
            }

            .secondary-view-area {
                position: absolute;

                &:hover {
                    border: 2px solid blue;
                    cursor: pointer;
                }
            }
        }
    }

    &__meetings-data {
        padding: 20px;
        width: 60%;
        min-height: 350px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;

        h4 {
            margin-bottom: 0;
            min-height: 1.2em;

            &.back-link {
                cursor: pointer;
            }
        }


        .todays-meetings {
            width: 100%;
            margin-bottom: 10px;
            text-align: center;

            .loading-meetings-message,
            .no-meetings-message {
                margin-top: 40px;
            }

            .spinner-border {
                margin: 20px;
            }

            .todays-meetings-list {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;

                padding: 20px;
                max-height: calc(50vw * 9 / 16);
                overflow-y: scroll;
                border: 1px solid #ced4da;
                border-radius: 10px;
            }

            .todays-meeting-item {
                padding: 10px;
                border: 1px solid lightgray;
                border-radius: 5px;
                display: flex;
                width: 100%;
                cursor: pointer;

                &.new-meeting {
                    justify-content: center;
                    align-items: baseline;
                    gap: 5px;
                    margin-top: 10px;
                }

                .todays-meeting-left, .todays-meeting-right {
                    padding: 10px;
                    width: 100%;
                }

                .todays-meeting-start-time {
                    color: #34c38f;
                    font-weight: bold;
                    margin-bottom: 0;
                }

                hr {
                    width: 0px;
                    height: auto;
                    border: 1px solid #ced4da;
                    margin: 0 10px;
                }

                img {
                    margin: 5px;
                    transform: translateY(-2px);
                }

                .todays-meeting-name {
                    font-weight: bold;
                }
            }
        }

        .meeting-title {
            margin-bottom: 15px;
        }
    }

    &__recordings-link {
        font-weight: bold;
        text-decoration: underline;
    }

    &__finished-recording-preview-container {
        background-color: black;
        width: 100%;
        position: relative;

        .spinner-border {
            position: absolute;
            top: calc(50% - 16px);
            left: calc(50% - 16px);
        }
    }

    &__finished-recording-preview {
        width: 100%;
        margin-top: 20px;

        video {
            width: 100%;
            background-color: black;
        }
    }

    &__error-modal-message {
        p {
            margin-bottom: 0.5rem;
        }
    }
}

button[disabled] {
    cursor: unset;
}


@media(max-width: 768px) {
    .record-live-meeting {
        &__header {
            position: sticky;
            top: 0;

            .button-tooltip-container {
                &[data-tooltip]:after {
                    visibility: hidden;
                }

                &.long-tooltip[data-tooltip]:after {
                    font-size: 0.7rem;
                    left: calc(50% - 250px);
                    visibility: unset;
                }
            }

            .hide-on-mobile {
                display: none;
            }

            .start-new-meeting {
                text-wrap: nowrap;
                padding: 0 10px;
            }

            &.meeting-finished {
                gap: 20px;
            }
        }

        &__title {
            font-size: 1.2rem;
            text-wrap: nowrap;
        }

        &__content {
            flex-direction: column-reverse;
        }

        &__meetings-data {
            width: 100%;
            min-height: 40vh;

            .meeting-title {
                margin-bottom: 0;
            }


            .participant-data {
                flex-direction: column;

                .email, .first-name, .last-name, .phone {
                    width: 100%;
                }
            }
        }
    }

    .crisp-client {
        display: none;
    }

    .topnav .dropdown-menu .dropdown-item {
        padding-left: 0;
    }
}
