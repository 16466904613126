/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $gray-300;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $gray-400;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $gray-500;
}
