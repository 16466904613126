.meeting-page {
  @extend .container-lg;
  max-width: 1450px !important;
  padding-bottom: 50px;

  .meeting-header {
    display: grid;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 14px;
    }

    &__title {
      margin: 0;
      padding: 0;
      color: #000;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      @media (max-width: 1350px) {
        margin-left: 0;
      }

      @media (max-width: 1300px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
      }

      .meeting-filters {
        gap: 10px;

        @media (max-width: 1300px) {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 5px;
        }
      }

      .global-date-filter .dropdown-menu {
        right: auto;
        left: 0;
      }

      @media(min-width: 1301px) {
        .global-date-filter .dropdown-menu {
          left: auto !important;
          right: 0 !important;
        }
      }
    }

    &__filter {
      min-width: 150px;

      &:last-child {
        margin: 0;
      }

      button.btn.btn-primary {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        width: 100%;
        height: 38px;
        padding: 6px 6px 6px 16px;
        color: #000000!important;
        font-weight: 600;

        &:focus {
          box-shadow: none;
          border: 1px solid #2684FF;
        }

        i {
          margin-left: auto;
          line-height: 1;
          color: hsl(0,0%,80%);
          font-size: 24px;
        }
      }

      @media (max-width: 1300px) {
        width: 100%;
      }

      @media (max-width: 1150px) {
        margin: 0 0 5px 0;
        padding-left: 0;
      }

      .select2-selection__control {
        border: none;
      }

      .select2-selection__value-container {
        justify-content: start;
        padding-left: 16px;
      }

      .select2-selection__single-value, .select2-selection__value-container--is-multi, .select2-selection__placeholder {
        color: #000000!important;
        font-weight: 600;
      }

      @media (max-width: 1300px) {
        .select2-selection__menu {
          position: absolute !important;
          top: 100% !important;
          left: 0 !important;
          right: 0 !important;
        }
      }
    }

    &__button {
      @extend .btn, .btn-primary;
      padding: 7px 15px !important;

      @media (max-width: 1250px) {
        padding: 10px 15px;
      }
    }

    &__calendar-button {
      color: black;
      font-size: 15px;
      text-decoration: underline;
    }

    &__calendar-button:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .short {
    min-width: 100px;
  }

  .medium {
    min-width: 125px;
  }

  .long {
    min-width: 150px;
  }

  .recording-page-content {
    margin-left: 25px;
  }

  .placeholder-div {
    height: 24px;
  }

  .meeting-sub-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 32px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    &__result-overview {
      display: flex;
      justify-content: space-between;
      margin: 25px 0 0 0;
      color: $green;
      font-size: 16px;
      font-weight: 600;

      @media (max-width: 1300px) {
        flex-direction: column-reverse;
        gap: 15px;
        margin-bottom: 10px;
      }
    }

    &__quick-filters {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;

      &__title {
        font-size: 16px;
        font-weight: 600;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &__filter {
        @extend .btn, .btn-secondary;
        background: $gray-200;
        border-color: $gray-200;
        padding: 4px 16px;
        color: $black!important;
        font-weight: 500;

        &:hover, &:focus {
          box-shadow: none;
          background: $gray-200;
          border-color: $gray-200;
          color: $black!important;
        }

        &--active {
          background: $cyan;
          border-color: $cyan;
          color: $white!important;

          &:hover, &:focus {
            box-shadow: none;
            background: $cyan;
            border-color: $cyan;
            color: $white!important;
          }
        }
      }
    }
  }

  .filter-row {
    display: block;

    &__filters {
      justify-content: flex-start;
      margin-bottom: 16px;
      padding: 0;

      &__apply {
        max-height: 32px;
        height: 32px;

        button {
          padding: 2px 16px;
        }
      }
    }
  }

  .meeting-search {
    @extend .card;
    margin: 0;

    &__icon {
      @extend .input-group-prepend;

      & > * {
        border: none;
        background: none;
        i {
          font-size: 18px;
        }
      }
    }

    &__input {
      @extend .form-control;
      border: none;
      background: #ffffff;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .meeting-pagination {
    margin: 32px 0;

    .pagination {
      margin: 0!important;
    }
  }
}

.meeting-list {
  @extend .card;
  width: 100%;
  margin-top: 16px;
  padding: 8px 32px;

  &:empty {
    padding: 0;
  }

  &__loading {
    display: flex;
    justify-content: center;
  }

  &__no-results {
    display: flex;
    justify-content: center;
    font-size: 16px;
  }

  .meeting-tabs {
    @extend .row;
    display: flex;
    flex-direction: row;
    padding: 16px 0;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: start;
    }

    &__tab {
      @extend .btn;
      margin: 0 32px 0 0;
      padding: 0;
      font-size: 22px;
      font-weight: 600;
      transition: none;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        color: $green;
      }

      &--active {
        color: $green;
      }
    }
  }

  .calendar-meeting-hover:hover {
    border: 1px solid #34c38f;
  }

  .meeting {
    @extend .row;
    margin-bottom: 8px;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid $gray-400;
    border-radius: 5px;

    @include media-breakpoint-down(xs) {
      padding: 8px 0;
    }

    &--recording {
      .meeting__info {
        padding: 10px;

        &--thumbnail-and-general-info {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          gap: 20px;

          @include media-breakpoint-down(xs) {
            justify-content: center;
            align-items: center;
            flex-direction: column;
            align-self: flex-end;
          }

          @media(min-width:767px){
            flex: 0 0 48%;
            max-width: 48%;
          }
        }

        &__thumbnail {
          position: relative;
          height: 140px;
          border-radius: 5px;
          background-color: $gray-200;
          border: 2px solid #0000001c;

          &:not(.meeting__info__thumbnail--no-recording):hover {
            border: 2px solid #34C38F;
          }

          @include media-breakpoint-down(xs) {
            width: 100%;
          }

          @media(min-width:767px){
            flex: 0 0 50%;
            max-width: 50%;
          }

          svg {
            position: absolute;
            width: 76px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.8;
            z-index: 1;
            pointer-events: none;

            :first-child {
              fill: $green;
            }
          }

          .carousel {
            height: 100%;
            .carousel-inner {
              height: 100%;

              .carousel-item {
                overflow: hidden;
                height: 100%;
                img {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                }
              }
            }
          }

          &__link {
            width: 100%;
            height: 100%;
            display: block;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }

          &__no-recording {
            text-align: center;
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 15px;

            &__info {
              margin-top: auto;
              font-weight: 600;

              &:only-child {
                margin-bottom: auto;
              }
            }

            &__appearance {
              margin-bottom: auto;
            }

            &__live {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: auto;
              gap: 5px;

              .red-circle-animation {
                width: 10px;
                height: 10px;
                background-color: red;
                border-radius: 50%;
                animation: blink 2s infinite;
              }

              @keyframes blink {
                50% {
                  opacity: 0;
                }
              }
            }
          }
        }

        &__general-info {
          display: flex;
          flex-direction: column;
          padding-top: 10px;
          gap: 10px;
          height: 100%;

          @include media-breakpoint-down(xs) {
            align-items: center;
            justify-content: center;
          }
        }

        &__meeting-type {
          font-weight: 700;
          font-size: 14px;
          line-height: 14px;
        }

        &__name {
          color: $black;
          font-weight: 700;
          font-size: 16px;
          line-height: 16px;
          word-break: break-all;

          @include media-breakpoint-down(xs) {
            text-align: center;
          }
        }

        &__time-info {
          font-size: 14px;
          font-weight: 700;
          line-height: 14px;

          &--created-at {
            color: $green;
          }

          &--duration {
            color: $gray-600;
          }
        }

        &__actions {
          display: flex;
          flex-direction: row;
          margin-top: auto;
          gap: 3px;

          @include media-breakpoint-down(xs) {
            margin-top: 10px;
          }

          &--note {
            height: 25px;
            padding: 1px 5px;
            color: $gray-800 !important;
            background-color: $gray-200;
            border-color: $gray-200;
            font-weight: 600;
            font-size: 12px;
            line-height: 10px;
          }

          &--more {
            height: 25px;
            width: 25px;

            > button {
              height: 100%;
              padding: 1px 5px;
              color: $gray-800 !important;
              background-color: $gray-200;
              border-color: $gray-200;
            }
          }
        }

        &__statistics {
          &__item {
            width: 100%;
            padding: 0 20px 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $black;
            font-weight: 500;
            font-size: 11px;

            &--score {
              font-weight: 700;
              font-size: 14px;
            }
          }
        }

        &--participation {
          gap: 8px;

          @media(min-width:767px){
            flex: 0 0 28%;
            max-width: 28%;
          }

          @media(max-width:767px){
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
          }
        }

        &__creator-info {
          font-weight: 500;
          font-size: 10.4615px;
          line-height: 12px;
        }

        &--statistics {
          @media(min-width:767px){
            flex: 0 0 24%;
            max-width: 24%;
            gap: 4px;
          }
          @media(max-width:767px){
            width: 100%;
          }
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      border-left: 1px solid $gray-400;
      padding: 8px 16px;

      &:first-child {
        border-left: none;
      }

      @include media-breakpoint-down(sm) {
        border-left: none;
      }

      &--date {
        @extend .col-12, .col-md-2;
        align-items: center;
        color: $green;
        font-size: 18px;
        font-weight: 600;
      }

      &--creator {
        @extend .col-12, .col-md-3;
      }

      &--creator-and-score {
        @extend .col-12, .col-md-5;
        flex-direction: row;
        justify-content: start;
        gap: 32px;

        @include media-breakpoint-down(xs) {
          gap: 8px;
        }
      }

      &--participation {
        padding: 0 2% 0 4% !important;

        @extend .col-12, .col-md-3;
        .meeting__info__user-name {
          width: 100%;

          @include media-breakpoint-down(xs) {
            justify-content: center;
          }
          span {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

  &--action {
    @extend .col-12, .col-md-4;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 0 40px 0 32px;

    @include media-breakpoint-down(md) {
      padding: 0 16px;
    }
  }

  &__participants {
    color: $gray-600;
    font-weight: 600;
    font-size: 12px;

    ul {
      padding: 0;
      margin: 0;
      gap: 6px;
      line-height: 1.5rem;
      li {
        word-break: break-all;
        i {
          margin-right: 0.5rem;
        }
      }
    }
  }

  &__creator-company {
    color: $black;
    font-size: 14px;
    font-weight: 600;
  }

  &__company-name {
    font-size: 12px;
    font-weight: 600;
    padding: 1% 0 1% 0;
    color: $black;
    text-align: center;
  }

  &__creator-link {
    color: $cyan;
    font-weight: 600;
    font-size: 12px;
  }

  &__show-more {
    @extend .btn, .btn-link;
    margin: 8px 0;
    padding: 0;
    text-align: start;
    text-decoration: underline;
    font-weight: 500;
  }

  &__action-join, &__action-analytics {
    @extend .btn, .btn-success;
    width: 100%;
    font-weight: 600;

    &:disabled {
      background: $gray-600;
      border-color: $gray-600;
    }
  }

  &__action-analytics {
    display: none;
  }

  &__score {
    background: $gray-200;
    border-radius: 50%;
    min-width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: $cyan;
    font-size: 14px;
  }
}
}
}

.modal-body {
.meeting__info__user-name {
display: flex;
align-items: center;
margin-bottom: 8px;
font-weight: 600;

img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
}
}

.meeting-tooltip {
.tooltip {
  width: 360px;
  opacity: 1;
  z-index: 1000;
}

.tooltip-inner {
  box-shadow: rgba(71, 88, 114, 0.1) 0px 2px 6px;
  border-radius: 6px;
  padding: 32px 24px 16px 24px;
  background: #ffffff;
  max-width: 331px;
  color: #000000;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before {
border-right-color: transparent;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before {
border-top-color: transparent;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
border-bottom-color: transparent;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before {
border-left-color: transparent;
}

&__header {
display: flex;
justify-content: space-between;
align-items: center;

&__title {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
}

&__score {
  background: $gray-200;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: $cyan;
  font-size: 18px;
}
}

&__table {
margin: 16px 0;

table {
  width: 100%;
  border: none;
  text-align: left;
  color: #74788d;
  font-size: 12px;

  thead {
    color: #000000;
  }

  tbody {
    tr {
      td:last-child {
        color: $cyan;
      }
    }
  }
}
}

&__type {
display: flex;
flex-direction: row;
gap: 8px;
margin-bottom: 16px;
font-size: 14px;
font-weight: 600;

&__value {
  color: $green;
}
}

&__help {
img {
  vertical-align: middle;
  float: left;
  padding-right: 10px;
}

margin-bottom: 8px;
background:  #F7F7F7;
border: 1px solid $gray-400;
border-radius: 4px;
padding: 16px;
text-align: left;
width: 284px;
height: 70px;
}
}

.email-tooltip {
  .tooltip-inner {
    box-shadow: rgba(71, 88, 114, 0.1) 0px 2px 6px;
    border-radius: 6px;
    background: #eff2f7;
    color: #000000;
    height: 50px;
  }

  .tooltip-email {
    text-align: center;
    font-weight: 500;
  }
}

.user-green {
  color: $green;
}

.tooltip-widget-title {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $green;
}

.tooltip-widget-description {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  font-size: 14px;
}

.tab {
  font-size: 22px;
  font-weight: 600;
  transition: none;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    color: $green;
  }

  &--active {
    color: $green;
  }
}

@keyframes blink {
  from { opacity: 0; }
  to { opacity: 1; }
}

.dot {
  animation: blink 1.5s infinite;
  margin-right: 5px;
}

.dot:nth-child(1) { animation-delay: 0s; }
.dot:nth-child(2) { animation-delay: 0.5s; }
.dot:nth-child(3) { animation-delay: 1s; }
