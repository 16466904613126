.apexcharts-yaxis-label {
  max-width: 750px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  &.apexcharts-canvas text {
    color: red !important;

  }
}

.truncated-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100px; /* Adjust the max-width as needed */
}

.truncated-label:hover::after {
  content: attr(title);
  position: absolute;
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 10;
}

.truncated-label {
}

.truncated-label.margin-left {
  margin-left: 15px !important;
}
