$comments-color-dark-grey: #8f8f8f;
$comments-font-size: 14px;

.snippets {
  position: relative;

  *:not(.fa) {
    font-family: 'roboto';
  }
  a {
    &:hover {
      text-decoration: underline !important;
    }
  }

  .title {
    padding: 40px 0 34px;
    margin: 0;
    color: #000;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
  }

  .snippet-title {
    font-weight: bold;
    color: black;
    word-break: break-word;
  }

  .snippet-wrapper {
    max-width: 820px;
    padding: 20px 20px 18px;
    border-radius: 10px;
    margin-bottom: 32px;
    background-color: #fff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

    &:last-child {
      margin-bottom: 15px;
    }

    .snippet-details {
      
        font-size: $comments-font-size;
        color: $comments-color-dark-grey;
      
    }

    .btn {
      width: 100%;
      padding: 7px 24px;
      border-radius: 2px;
      font-size: $comments-font-size;
      box-shadow: none !important;
    }

    .snippet-buttons {
      display: flex;
      justify-content: space-between;

      .btn {
        width: 45%;
        padding: 7px 24px;
        border-radius: 2px;
        font-size: $comments-font-size;
        box-shadow: none !important;
      }

      a {
        color: #FFFFFF !important;

        &:hover {
          color: #495057 !important;
          text-decoration: none !important;
        }
      }
    }

  }
}
