.page-title-box {
    display: none !important;
}

body {
    background: rgb(245, 248, 250);
}

#layout-wrapper {
    background-color: #FAFAFA;
}

.custome-horizontal-header {
    background-color: none !important;
    position: relative !important;
    .navbar-header {
        background-color: #FAFAFA;
        .presentation-header {
            margin-top: 115px !important;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .app-search {
                position: static;
                display: flex !important;
            }
            .import-wrap {
                display: flex;
                button {
                    background-color: #fff;
                    border: 0;
                    padding: 10px;
                    width: 126px;
                    height: 54px;
                    text-align: center;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                    border-radius: 5px;
                    margin-right: 20px;
                    font-size: 18px;
                    line-height: 18px;
                    color: #b5b5b5;
                    img {
                        margin-left: 10px;
                    }
                }
            }
        }
        .app-search {
            margin-top: 20px;
            position: absolute;
            left: 0px;
            right: 0px;
            width: 440px;
            margin: 0 auto;
            @media (max-width: 991.98px) {
                display: block !important;
            }
            @media (max-width: 580px) {
                width: 90%;
                margin: 0 auto;
            }
            .form-control {
                max-width: 437px;
                height: 54px;
                color: #b5b5b5;
                font-size: 18px;
                line-height: 21px;
                padding-left: 20px;
                padding-right: 40px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                background-color: #fff;
                border-radius: 5px;
                font-family: "Gilroy";
                font-weight: 500;
                width: 417px;
                @media (max-width: 580px) {
                    width: 100%;
                    font-size: 13px;
                }
            }
            .bx-search-alt {
                left: inherit;
                right: 25px;
                color: #b5b5b5;
                top: 10px;
                font-size: 20px;
                cursor: pointer;
                @media (max-width: 580px) {
                    right: 15px;
                }
            }
        }
        .navbar-brand-box {
            @media (max-width: 767px) {
                display: none;
            }
            .logo-light {
                span.logo-sm {
                    @media (max-width: 991.98px) {
                        display: block !important;
                    }
                }
            }
        }
    }
    .waves-effect {
        display: none;
    }
}

body[data-topbar="dark"] {
    #page-topbar.custome-horizontal-header {
        background-color: none !important;
    }
}

body[data-sidebar="dark"] {
    .navbar-brand-box.custome-box {
        background: #00318F !important;
        padding: 0;
    }
}

.logo-box {
    position: fixed;
    left: 0;
    top: 0;
    padding: 0;
    img {
        padding-top: 20px;
    }
}

ul {
    li {
        list-style-type: none;
    }
}

.container {
    @media (min-width: 1200px) {
        max-width: 1240px;
    }
}

body[data-sidebar="dark"].vertical-collpsed .custome-vertical-menu #sidebar-menu>ul>li:hover>a {
    img {
        opacity: 1;
    }
}

body[data-sidebar="dark"].vertical-collpsed .custome-vertical-menu #sidebar-menu>ul ul {
    background: #00318F !important;
}

.app-search .form-control::-webkit-input-placeholder {
    /* Edge */
    color: #b5b5b5 !important;
}

.app-search .form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b5b5b5 !important;
}

.app-search .form-control::placeholder {
    color: #b5b5b5 !important;
}

.page-content {
    padding: 0 25px;
    // @media (max-width: 991.98px) {
    //     padding-left: 8% !important;
    // }
    // @media (max-width: 767px) {
    //     padding-left: 10px !important;
    //     padding-bottom: 120px !important;
    // }
}

.card {
    border-radius: 5px;
}

// dashboard top 3 box
.top-info-box {
    // margin-top: -5px;
    @media (max-width: 991px) {
        margin-top: 0;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// .top-info-box {
//     padding-right: 30px;
//     padding-left: 30px;
//     margin-bottom: 15px;
//     &:first-child {
//         padding-left: 15px;
//     }
//     &:last-child {
//         padding-right: 15px;
//     }
//     @media (max-width: 1199px) {
//         padding-right: 15px;
//         padding-left: 15px;
//         margin-bottom: 0px;
//     }
//     @media (max-width: 991px) {
//         margin-bottom: 10px;
//     }
// }
.container {
    @media (min-width: 1200px) {
        max-width: 85%;
    }
}

// scrollbar-custom-css
.scroller {
    scrollbar-color: #a2adb7 white;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    //overflow-x: hidden !important;
    cursor: pointer;
}

.scroller::-webkit-scrollbar {
    width: 4px;
    height: 3px;
}

.scroller::-webkit-scrollbar-track {
    background: white;
}

.scroller::-webkit-scrollbar-thumb {
    background: #a2adb7;
    border-right: 1px solid white;
    border-radius: 50px;
}

iframe body div {
    padding: 0 !important;
}

.navbar-header {
    height: 0 !important;
}

h1 {
    font-size: 30px;
    line-height: 35px;
    color: $color-blue;
    margin-bottom: 50px;
    margin-top: 50px;
    @media (max-width: 767px) {
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

.nav {
    margin-bottom: 40px;
    border: 0;
    margin-top: -15px;
}

.nav-tabs {
    .nav-item {
        &.show {
            .nav-link {
                background-color: transparent;
                border: 0;
                color: #292929;
            }
        }
    }
    .nav-item {
        .nav-link {
            font-size: 24px;
            color: #bbbbbb;
            border: 0;
            padding-left: 0;
            padding-right: 2rem;
            &.active {
                background-color: transparent;
                border: 0;
                color: #292929;
            }
        }
    }
}

.comman-tital {
    color: $color-blue;
    font-size: 25px;
    font-weight: 600;
    font-family: "Gilroy";
    margin-bottom: 0px;
    font-weight: 500;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 15px;
}

.header-row {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 24px;
    @media screen and (max-width: 767px) {
        margin-bottom: 0px;
    }
}

.btn-fill {
    width: 145px;
    height: 35px;
    line-height: 22px;
    border-radius: 5px;
    border: 1px solid #ededed;
    background-color: #fff;
    font-size: 13px;
    color: #3d3d3d;
    padding: 5px;
    img {
        float: right;
        margin-top: 4px;
        margin-right: 5px;
    }
}

.loaderImage {
    width: 50px;
    height: 50px;
    align-self: center;
}

.iframe-block-none {
    display: none;
}

.iframe-block-show {
    display: block;
    margin: 0 auto;
}

.loader-wrapper {
    margin: 50px 0;
}

.no-result {
    font-size: 18px;
    font-weight: 500;
}

.btn-save {
    width: 120px;
}

.profile-tab {
    .btn-save {
        margin-bottom: 10px;
        @media (max-width: 361px) {
            margin-left: 10px;
        }
    }
}

.btn-pw {
    width: 140px;
    @media (max-width: 361px) {
        margin-right: 0 !important;
    }
}

.disabled {
    pointer-events: none;
}

.crete-btn {
    width: 147px;
}

.dropdown-wrapper {
    display: flex;
    flex-direction: row;
    .recent-use {
        margin-right: 20px;
    }
}

.checkbox-text {
    cursor: none;
}

.checkbox-wrapper {
    margin: 5px auto;
    position: relative;
    label {
        position: relative;
        min-height: 25px;
        padding-left: 30px;
        margin-bottom: 0;
        font-weight: normal;
        padding-right: 15px;
        cursor: pointer;
        span {
            top: 50%;
            transform: translateY(-50%);
        }
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 18px;
            height: 18px;
            transition: transform 0.28s ease;
            background: #f5f5f5;
        }
        &:after {
            content: "";
            display: block;
            width: 10px;
            height: 5px;
            border-bottom: 2px solid $color-blue;
            border-left: 2px solid $color-blue;
            transform: rotate(-45deg) scale(0);
            transition: transform ease 0.25s;
            position: absolute;
            top: 7px;
            left: 6px;
        }
    }
    input[type="checkbox"] {
        width: auto;
        opacity: 0.00000001;
        position: absolute;
        left: 0;
        margin-left: -20px;
        &:checked~label {
            &:after {
                transform: rotate(-45deg) scale(1);
            }
        }
        &:focus+label::before {
            outline: 0;
        }
    }
}

.user-modal {
    .team-role {
        .dropdown-item {
            padding: 0px 10px;
        }
    }
}

.role {
    .user-role {
        .dropdown-menu {
            min-width: 30rem;
            @media (max-width: 767px) {
                min-width: 18rem;
            }
        }
    }
}

.role-dropdown {
    h4 {
        margin-bottom: 3px;
    }
    div {
        white-space: pre-line;
    }
}

.auto-join-bot-option-dropdown {
  width: 450px;
  height: auto;
}

.auto-join-bot-option-item {
  padding: 5px 10px !important;
  h4 {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
  }
  div {
    white-space: pre-wrap;
    font-size: 12px !important;
    font-weight: 300;
  }
}

.drop-wrapper {
    display: inline-flex;
    margin-bottom: 20px;
    .col {
        margin-top: 10px;
    }
}

.btn-edit {
    margin-right: 10px;
}

.delte-btn {
    width: 53px;
}

.remove-btn {
    width: 127px;
}

.long-title {
    width: 100px;
}

.pos-rel {
    position: relative;
    display: inline-block;
    @media (max-width: 767px) {
        line-height: 38px;
    }
}

body[data-sidebar="dark"] {
    .custome-vertical-menu {
        #sidebar-menu {
            ul {
                li {
                    .notification {
                        p {
                            left: 7px;
                            top: -3px;
                            @media (max-width: 767px) {
                                top: 5px;
                                line-height: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

#sidebar-menu {
    ul {
        li {
            a {
                &.active {
                    img {
                        filter: invert(90%) sepia(10%) saturate(928%) hue-rotate(356deg) brightness(104%) contrast(108%);
                    }

                    i {
                        color: #FFFFFF !important;
                        opacity: 1 !important;
                    }
                    span {
                        opacity: 1 !important;
                    }
                }

                &:hover {
                    img {
                        filter: invert(20%) sepia(22%) saturate(5974%) hue-rotate(247deg) brightness(81%) contrast(93%);
                    }

                    i {
                        color: #00318F !important;
                        opacity: 1 !important;
                    }
                    span {
                        opacity: 1 !important;
                    }
                }

                img {
                    margin-right: 12px;
                    filter: invert(72%) sepia(27%) saturate(357%) hue-rotate(221deg) brightness(82%) contrast(84%);
                }

                i {
                    margin-right: 0;
                    font-size: 17px;
                    opacity: 1;
                    color: #f5f5f5 !important;
                }

                span {
                    vertical-align: middle;
                }
            }
        }
    }
    .has-arrow {
        &:after {
            line-height: 32px;
        }
    }
    .sub-menu {
        .has-arrow {
            &:after {
                line-height: 19px;
            }
        }
    }
}

body[data-sidebar="dark"] {
    &.vertical-collpsed {
        .custome-vertical-menu {
            #sidebar-menu {
                ul {
                    li {
                        .notification {
                            p {
                                @media screen and (max-width: 767px) {
                                    right: -4px;
                                }
                            }
                        }
                        .fa-fw{
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
    }
}

// .md-tooltip.md-show {
//     transition: all .4s cubic-bezier(.25,.8,.25,1);
//     transition-duration: .15s;
//     -webkit-transform: scale(1);
//     transform: scale(1);
// }
.vertical-collpsed {
    .vertical-menu {
        #sidebar-menu {
            &>ul {
                &.user-menu-bottom {
                    &>li {
                        &:hover {
                            &>a {
                                padding: 15px 10px;
                            }
                        }
                        &.user-pic {
                            p {
                                display: none;
                            }
                            .signle-user {
                                p {
                                    display: block;
                                }
                                margin-top: 5px;
                                @media (max-width: 767px) {
                                    margin-top: 0;
                                }
                            }
                            a {
                                transform-origin: unset;
                                min-height: 30px;
                                padding: 8px 10px;
                            }
                            &:hover {
                                &>a {
                                    transition: none;
                                    transform: none;
                                    width: auto;
                                }
                                .sub-menu {
                                    /*top: -20px;*/
                                    bottom: 0;
                                    top:inherit;
                                }
                            }
                        }
                    }
                }
                &>li {
                    a {
                        -webkit-transform-origin: left center;
                        transform-origin: left center;
                        &.active {
                            &:hover {
                                color: #fff !important;
                            }
                        }
                    }
                    &:hover {
                        &>a {
                            background-color: #00318F;
                            color: #fff;
                            text-align: left;
                            padding: 15px 16px;
                            transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
                            transition-duration: 0.3s;
                            -webkit-transform: scale(1);
                            transform: scale(1);
                            border-radius: 5px;
                            @media (max-width: 767px) {
                                transition: none;
                                transform: none;
                            }
                            &.notification {
                                @media (max-width: 767px) {
                                    padding: 5px 16px;
                                }
                            }
                        }
                    }
                    &>a {
                        text-align: center;
                        img {
                            margin: 0;
                            @media (max-width: 767px) {
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

.custome-vertical-menu {
    &>.full-sidebar {
        width: 100%;
    }
    .metismenu {
        width: 100%;
        @media (max-width: 767px) {
            width: auto;
        }
    }
}

.metismenu {
    .menu-title {
        display: none;
    }
    li {
        @media (max-width: 767px) {
            width: auto;
        }
        @media (max-width: 520px) {
            width: auto;
        }
        a {
            @media (max-width: 767px) {
                text-align: center;
            }
            text-align: left;
            img {
                margin: 0 auto;
            }
        }
    }
    &.user-menu-bottom {
        li {
            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }
}

// .btn-upload {
//     width: 75px;
// }

.table-userlist {
    .table-loader {
        position: absolute;
        top: 35%;
        left: 35%;
    }
    .card {
        min-height: 300px;
    }
}

.integrations-wrapper {
    div {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;

        img {
            width: 20px;
            height: 20px;
        }
    }
}

.right-icon {
    position: absolute;
    right: 5px;
    top: -5px;
    width: 20px;
    height: 20px;
    background: $color-blue;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-width: 10px;
        filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(244deg) brightness(102%) contrast(101%);
    }
}
.block {
    display: block !important;
    color: $color-blue !important;
    font-weight: 700 !important;
    padding-left: 0 !important;
    opacity: 1 !important;
}

.iframe-wrapper {
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: 99;
    }
}
.btn-hide{
    opacity: 1;
}
.multi-btn {
    margin-right: 5px;
}
.view-edit-drop{
    padding: 0;
    outline: none !important;
    cursor: pointer;
    *{
      outline:none !important;
      &:focus,
        &:active{
            outline: none !important;
            box-shadow: none !important;
        }
    }
    .btn-secondary{
        padding: 0px;
        outline: none;
        &:focus,
        &:active{
            outline: none;
            box-shadow: none !important;
        }

    }
    .view-btn{
        margin-bottom: 0px;
        line-height:1.9;
        height: 16px;
    }
    .dropdown-menu{
        min-width: 5rem;
        &.show{
            top: 22px !important;
        }
    }
}
.inner-statics-page {
    h1 {
        margin-top: 40px;
        &::first-letter{
            text-transform: capitalize;
        }
    }
    .back-btn {
        cursor: pointer;
        margin-bottom: 20px;
    }
}
.pointerEvent {
    cursor: pointer;
}
.toggleWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .nav {
        padding-right: 107px;
    }
    // .custom-switch {
    //     position: absolute;
    //     top: 0;
    //     right: 12px;
    // }
    @media screen and (max-width: 767px) {
        margin-bottom: 30px;
        .nav {
            padding-right: 0px;
        }
        // .custom-switch {
        //     position: relative;
        //     left: 5px;
        // }
    }
}
.loginas-modal {
    .search-login-dropdown {
        p {
            border: 1px solid;
            padding: 10px;
            cursor: pointer;
        }
    }
    .search-login-dropdown {
        position: absolute;
        padding: 40px 50px;
        background: #ffffff;
        width: 95%;
        text-align: left;
        z-index: 99999;
        max-height: 500px;
        overflow-y: scroll;
        box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.05);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        @media screen and (max-width: 767px) {
            max-height: 300px;
        }
    }
}
table {
    border: 1px solid #eff2f7;
    tr {
        font-weight: 700;
        opacity: 0.9;
    }
}
.error-text {
    width: 60%;
    margin: 0 auto;
}
.fonts-wrapper{
    &.radio-wrapper {
        display: block;
        padding: 0;
        border: none;
        label{
            margin-right: 5px;
        }
    }
}
.error-mes {
    border-color: $red-error;
}
.no-result {
    margin-top: 80px;
    margin-bottom: 80px;
}
.invalid-input {
    border: 1px solid #f46a6a !important;
    svg {
        color: $red-error;
    }
}
.invalid-input-text{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f46a6a;
}
.form-control {
    padding-top: 10px;
    &.is-invalid {
        &.select-box {
            background-image: none;
        }
    }
}

.top-info-box{
    @media(max-width:1080px){
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media(max-width:767px){
        flex: 0 0 100%;
        max-width: 100%;
    }
}
#hubspot-messages-iframe-container.widget-align-right {
    right: 20px !important;
    @media screen and (max-width: 767px) {
        bottom: 50px !important;
        right: 20px !important;
        iframe{
            height: 93% !important;
        }
    }
}
.alert {
    min-height: 45px;
}
@media screen and (min-width: 992px) {
    .alert-height {
        padding-top: 0;
        h1 {
            margin-top: 33px;
            margin-bottom: 30px;
        }
    }
}
.show-alert {
    opacity: 1;
    @media screen and (max-width: 992px) {
        display: block;
    }
}
.hide-alert {
    opacity: 0;
    @media screen and (max-width: 992px) {
        display: none;
    }
}

.hidden {
    display: none !important;
}

.transparent {
    opacity: 0 !important;
}

.btn-save.share-link-modal {
    width: 170px;
}
