.filter-btn-group {
    justify-content: flex-end !important;
}
@media (max-width: 991px) {
    .filter-btn-group {
        justify-content: flex-start !important;
        margin-bottom: 22px;
    }
}
.analytic-row {
    @media (max-width: 767px) {
        .nav-tabs {
            display: flex;
            // flex-wrap: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;
            -ms-overflow-style: none; // IE 10+
            overflow: -moz-scrollbars-none; // Firefox}
            scrollbar-width: none !important;
            &::-webkit-scrollbar {
                width: 0 !important;
            }
        }
        .comman-tital {
            padding-top: 0px;
        }
    }
    @media screen and (max-width: 991px) {
        .btn-in-responsive {
            float: left !important;
            margin-bottom: 10px;
            .float-left.d-flex {
                display: block !important;
                .my-auto.ml-5.mr-2 {
                    margin-left: 0px !important;
                    margin-top: 10px !important;
                    margin-bottom: 10px !important;
                }
            }
        }
    }
    .search-filter-main{
        .left-main{
            @media(max-width:767px){
                width: 100%;
            }
            .dropdown{
                margin-right: 0.5rem;
                .btn{
                    width: 100%;  
                }
                .dropdown-menu{
                    min-width: 100%;
                }
            }
        &.presentation-left{
            .dropdown{
                width: auto;
            }
        }
        }
        .right-main{
            @media(max-width:767px){
                width: 100%;
                margin-top: 20px;
                
            }
            .right-inner{
                display: flex;
                @media(max-width: 545px){
                    flex-wrap: wrap;
                    width: 100%;
                }
             .date-right{
                 margin-left: 45px;
                 @media(max-width: 545px){
                     margin-left: 5px;
                     width: 100%;
                 }
             }
             .team-dropdown{
                 @media(max-width: 545px){
                     width: 100%;
                 }
             }
             
             .date-main{
                 display: flex;
                 @media(max-width:545px){
                     margin-top: 20px;
                     width: 40%;
                     .dropdown{
                         width: 330px;
                     }
                 }
                 @media(max-width:440px){
                     width: 60%;
                 }
             }
             .team-dropdown{
                 @media(max-width:545px){
                     width:100%;
                     margin-right: 0px !important;
                     margin-top: 10px;
                 }
             }
             
            }
            
        }
        .right-main-presentation{
            @media(max-width:767px){
                float: left;
                width: 100%;
                margin-top: 20px;
            }

            .presentation-right-inner{
                display: flex;
                @media(max-width:580px){
                    flex-wrap: wrap;
                }

                .presentation-date{
                    display: flex;
                }
                .presentation-box{
                    @media(max-width: 580px){
                        width: 48%;
                        margin-bottom: 15px;
                    }
                    @media(max-width:490px){
                        width: 100%;
                        margin-right: 0px !important;
                    }
                }

            }

        }
    }
    .nav-tabs{
        @media(max-width:767px){
            margin-bottom: 15px;
        }
        .nav-item{
            .nav-link{
                @media(max-width: 990px){
                    font-size: 19px;
                }
                @media(max-width:767px){
                    font-size: 16px;
                    padding: 0.5rem 0.5rem;
                    
                }
            }
            @media(max-width:767px){
                // max-width: 125px;
                position: relative !important;
                flex-wrap: wrap;
                &:first-child {
                    .nav-link{
                    padding-left: 0;
                    }
                }
            }
        }
    }
    .filter-panel{
        margin-top: 5px !important;
    }
}
.dashboard-tab{
    &.card{
        display: none;
    }
};

.dashboard-tab{
    &.card{
        display: none;
    }
};

