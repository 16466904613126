.chatbot-input {
  height: 30px;
  background-color: $light;
  border-color: $light;
  padding-right: 45px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:hover{
    border-color: #DFDFDF;
  }

  &:focus {
    border-color: #bbafaf;
    box-shadow: 0 0 5px #888484(0, 123, 255, 0.5);
  }
}

.input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.ai-chat-wrapper {
  input {
    padding: 20px 10px 20px 10px;
    background-color: white;
  }
}

.chatbot-input {
  padding: 0px 20px 0px 10px;
  width: 100%;
}

.send-chat-button {
  background: none;
  border: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  line-height: 40px;
  padding: 0;
  cursor: pointer;
  outline: none;
}

.chat-container {
  display: flex;
  height: 90vh;
  max-width: 99%;
  background: #f9f9f9;
  border-radius: 10px;
  min-width: 320px;
}

.chat-sidebar {
  width: 250px;
  background: #e6e6e6;
  overflow-y: auto;
  border-radius: 0 0 0 10px;
}

.chat-sidebar-header {
  padding: 0 17px;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-sidebar-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-sidebar-loader img {
  width: 100px;
  height: 100px;
}

.chat-topic-list {
  list-style: none;
  margin: 0;
  padding: 10px;
}

.chat-topic-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  position: relative;
}

.chat-topic-item span {
  word-break: break-word;
}

.activeChat {
  font-weight: bold;
}

.chat-main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-main-header {
  padding: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.chat-message-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  &.chat-messages {
    display: 0;
  }
}

.assistant-placeholder-loader {
  img{
    width: 40px;
    height: 40px;
  }
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: flex-start;
}

.chat-message {
  min-width: 50px;
  padding: 10px 14px;
  margin: 5px;
  font-size: 16px;
  line-height: 1.4;
  position: relative;
  border-radius: 18px;
}

.chat-message.outgoing {
  background: #DCDCDC;
  color: black;
  min-width: 50px;

  align-self: flex-end;
  border-top-left-radius: 5px;
}

.chat-message.incoming {
  background: #E0E0E0	;
  color: black;
  min-width: 50px;

  align-self: flex-start;
  border-top-left-radius: 5px;
}

.chatbot-input-container {
  display: flex;
  padding: 10px 10px 10px 0;
  background: #e6e6e6;
}

.chatbot-message-input {
  flex: 1;
  padding: 10px;
  width: 800px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: calc(100% - 20px);
}

.chat-send-button {
  padding: 10px 15px;
  margin-left: 10px;
  color: #F5F8FA;
  background-color: #333333 !important;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #494848 !important;
  }

  &:disabled,
  button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.close-chat-button {
  border: none;
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
  width: 30px;
  margin: 5px;
  background-color: transparent;
}

.message-sender-name {
  font-size: 12px;
  color: #6c757d;
  margin-bottom: 2px;
  text-align: left;
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.edit-chat-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin: 8px 7px 0 15px;
  font-weight: 500;
}

.edit-chat-topic {
  width: 14px !important;
  height: 14px !important;
  margin: 0 0 3px 8px !important;
  border-radius: 0 !important;
}

.chat-dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  padding: 5px;
  z-index: 100;
}

.dropdown-chat-item {
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
    color: blue;
  }
}

.icon-grip-dots {
  padding: 6px;

  &:hover {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.2s ease-in-out;
    border-radius: 50%;
  }
}

