.calendar {
    margin: 10px 0;

    .fc {
        .fc-timegrid-slot:not(:first-child),
        .fc-timegrid-axis:not(:first-child) {
            height: 50px;
        }

        .fc-timegrid-slot {
            border: none;
        }

        .fc-timegrid-slot-frame {
            border: none;
        }

        .fc-timegrid {
            border-top: none;
        }

        .fc-timegrid-slots{
            z-index: initial !important;
        }
    }

    .custom-event{
        height: inherit;
        padding: 5px;
        border-radius: 2px;
        display: flex;
        align-items: start;
        justify-content: space-between;
        overflow: hidden;

        .title {
            font-size: 15px;
            word-wrap: break-word;
            color: white;
        }

        &.border-blue{
            border-left: 5px solid blue;
        }

        &.border-green{
            border-left: 5px solid green;
        }

        &.border-red{
            border-left: 5px solid red;
        }

        p{
            margin: 0;
        }

        .event-icon{
            width: 24px;
            height: 24px;
        }
    }

    .no-calendar-events-message {
        font-size: 16px;
        display: flex;
        justify-content: center;
        padding: 1rem;
    }

    .meeting {
        flex-wrap: nowrap;
        height: 100px;

        i.fa.fa-check {
            transform: translateY(2px);
        }
    }

    @media(max-width: 1024px) {
        .meeting {
            flex-direction: column;
            height: unset;

            .meeting__info {
                border-left: none;
                width: 100%;
                max-width: none;

                > div {
                    margin: 10px auto;
                }

                &:not(:first-child) {
                    border-top: 1px solid lightgray;
                }
            }
        }
    }

    .meeting__info--date {
        text-align: center;
    }

    .meeting__info--participation:not(.meeting__info--more) {
        padding: 0 2% 0 3% !important;
    }

    .meeting__info--more {
        max-width: 55px;
        display: flex;
        align-items: center;

        button {
            width: 30px;
            height: 30px;
            background-color: #e8e8e8;
            border: none;
            padding: 0;

            i {
                color: #7c7c7c;
                transform: translateY(1px);
            }
        }
    }

    &__tabs {
        display: flex;
        justify-content: flex-start;
        gap: 40px;
        width: 100%;
        padding: 20px 0;
        font-size: 15px;
        font-weight: bold;
    }

    &__pagination {
        padding: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-family: Arial, sans-serif;

        button {
            border: none;
            background: none;
            padding: 8px 12px;
            font-size: 12px;
            cursor: pointer;
            border-radius: 4px;
            color: #000;
            transition: all 0.3s ease-in-out;
        }

        button:hover {
            background-color: #f0f0f0;
        }

        button.active {
            background-color: #34C38F;
            color: #fff;
            font-weight: bold;
            border: 1px solid #34C38F;
        }

        .pagination__ellipsis {
            padding: 8px 12px;
            font-size: 14px;
            color: #34C38F;
            font-weight: bold;
            pointer-events: none;
        }

        button:disabled {
            color: #ccc;
            cursor: not-allowed;
        }

        .pagination__ellipsis:hover {
            background: none;
            cursor: default;
        }

        .pagination__arrow {
            font-size: 20px;
            color: #34C38F;
        }

        .pagination__arrow:disabled {
            color: #ccc;
        }
    }
}

.calendar-page-override-dirty .select2-selection__menu {
    left: 0 !important;
}

.pseudo-link {
    cursor: pointer !important;
    &:hover {
        text-decoration: underline !important;
    }
}

.calendar-event-modal {
    .modal-header {
        border-bottom: 1px solid #e7ecf3;
    }

    .modal-body {
        padding: 15px 25px 25px 25px;
    }

    .calendar-event-modal-navlink {
        padding: 10px;

        &:hover {
            background-color: #EEE;
        }
        &.current {
            background-color: #E6E6E6;
        }
    }

    .change-meeting-owner {
        text-decoration: underline;

        &.participant-list-item {
            margin: 10px 0;
        }
    }

    .video-url-link {
        text-decoration: underline !important;
        font-weight: 400;
        line-break: anywhere;
    }

    .event-description {
        padding: 10px;
        max-height: 500px;
        font-family: "Gilroy", sans-serif;
        font-size: 10pt;
    }

    .event-log, .no-event-log {
      overflow-y: scroll;
      border: 1px solid #EEE;
      margin-top: 20px;
      max-height: 500px;
    }

    .event-log-entry {
      padding: 2px 5px;

      &:nth-child(odd) {
        background-color: #E8E8E8;
      }
    }

    .debug-individual-event-logs {
        margin: 20px 0;

        label {
            margin-bottom: 0;
        }

        .event-log, .no-event-log {
            margin-top: 5px;
        }
    }

    .modal-data-group {
        margin: 10px 0;
    }

    .dropdown {
        display: inline;
        margin-left: 1rem;
    }

    .dropdown-menu {
        border: 1px solid lightgray;
    }

    .dropdown-item:hover {
        background-color: #e4e4e4;
    }

    .confirm-setting-owner {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .buttons-container {
            display: flex;
            justify-content: space-evenly;
            width: 100%;

            button {
                width: 100px;
                height: 50px;
                font-size: 20px;
            }
        }
    }

    .owner-candidate-good {
        color: var(--green);
        font-weight: bold;
    }

    .owner-candidate-bad {
        color: saturate(darken(red, 15%), 40%);
    }
}
