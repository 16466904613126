.filter-title {
  text-decoration: underline;
  font-size: 13px;
  font-weight: 600;
}

.main-navbar-items {
  display: flex;
  align-items: center;
  margin: 25px 0 0 16px;
}

.snippet-table {
  max-height: calc(80vh - 100px);
  overflow-y: auto;
  overflow-x: auto;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.td-wrap-text {
  word-wrap: break-word;
  white-space: normal !important;
}

.underline-on-hover:hover {
  text-decoration: underline;
  cursor: pointer;
}

.meeting__info__actions--modal {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1px 5px;
  color: #343a40;
  background-color: #eff2f7;
  cursor: pointer;
  label {
    margin-bottom: 0;
    cursor: pointer;
  }
}
