//
// Google font - Roboto
//
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');
@font-face {
    font-family: 'Gilroy';
    src: url('../../../fonts/page-fonts/Gilroy-Bold.woff2') format('woff2'), url('../../../fonts/page-fonts/Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../../fonts/page-fonts/Gilroy-Heavy.woff2') format('woff2'), url('../../../fonts/page-fonts/Gilroy-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../../fonts/page-fonts/Gilroy-Light.woff2') format('woff2'), url('../../../fonts/page-fonts/Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../../fonts/page-fonts/Gilroy-Regular.woff2') format('woff2'), url('../../../fonts/page-fonts/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../../fonts/page-fonts/Gilroy-Medium.woff2') format('woff2'), url('../../../fonts/page-fonts/Gilroy-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url("../../../fonts/Roboto/Roboto-Medium.ttf") format('truetype');
  }

.fonts-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border: 1px solid #ced4da;
    border-radius: 0;
    border-bottom: 0;
    &:nth-child(2) {
        border-radius: 5px 5px 0 0;
    }
    &:last-child {
        border-bottom: 1px solid #ced4da;
        border-radius: 0px 0px 5px 5px;
    }
    @media(max-width: 767px) {
        flex-direction: column;
    }
    .left {
        display: flex;
        // .fontname-wrap {
        //     width: 250px;
        // }
        @media(max-width: 767px) {
            flex-direction: column;
        }
        .fontweight-wrap,
        .fontDisplay-wrap {
            // width: 130px;
            .dropdown-toggle {
                width: 100%;
                text-align: left;
                border: 1px solid #ced4da;
            }
        }
        .fontstyle-wrap {
            .radio-wrapper {
                display: flex;
                // width: 170px;
                border: 1px solid #ced4da;
                border-radius: 5px;
                padding: 7px 10px;
                height: 36px;
                @media(max-width: 767px) {
                    margin-bottom: 15px;
                }
                .form-check {
                    margin-right: 15px;
                    padding-left: 0;
                    .form-check-input {
                        margin-top: 3px;
                    }
                }
            }
        }
        .font-block {
            margin-right: 10px;
            @media(max-width: 767px) {
                margin-right: 0;
                margin-top: 15px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .font-style-row {
            overflow-x: auto;
            .list-group {
                flex-direction: row;
                width: 400px;
                height: 32px;
                align-items: center;
                .list-group-item {
                    border: 0;
                    border-right: 1px solid #ced4da;
                    border-radius: 0;
                    display: flex;
                    align-items: center;
                    padding: 0 10px;
                    flex-grow: 1;
                    &:last-child {
                        border-right: 0;
                    }
                    img {
                        max-width: 16px;
                        margin-right: 10px;
                    }
                    p {
                        margin: 0;
                    }
                }
            }
            &.extrabold {
                label {
                    text-transform: none;
                    font-weight: 900;
                }
            }
            &.medium {
                label {
                    text-transform: none;
                    font-weight: 500;
                }
            }
            &.semibold {
                label {
                    text-transform: none;
                    font-weight: 600;
                }
            }
        }
    }
    .right {
        label {
            @media(max-width: 767px) {
                display: none;
            }
        }
    }
    label {
        text-transform: capitalize;
    }
    .form-group {
        margin-bottom: 0;
    }
    .font-dropdown {
        width: 100%;
    }
    .btn-wrapper {
        margin-bottom: 0;
        margin-top: 14px;
        .btn-Save {
            margin-right: 10px;
        }
    }

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    @media(max-width: 1300px) {
        line-height: 24px;
    }
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 13px;
    height: 13px;
    border: 1px solid #3e3e3e;
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 7px;
    height: 7px;
    background: #3e3e3e;
    position: absolute;
    top: 7px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}}
