@import "./icons.scss";
@import "./bootstrap.scss";
@import "./app.scss";


* {
  font-family: "Gilroy", sans-serif;
}
#sidebar-menu {
  ul {
    li {
      a {
        i {
          text-align: left;
          width: 16px;
        }
      }
    }
  }
}

.noti-icon .badge {
  left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}

.dz-message {
  text-align: center;
  padding: 30px;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}

.task-box {
  border: 1px solid $border-color;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}

.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
}

.input-color {
  color: $input-color !important;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.custom-height-main {
  min-height: calc(100vh - 0px);
}

.btn-google {
  padding: 8px 10px;
  background-color: $white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  box-shadow: RGB(0 0 0 / 24%) 0px 2px 2px 0px, RGB(0 0 0 / 24%) 0px 0px 1px 0px;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  span {
    display: inline-block;
    margin-left: 15px;
  }
}

.modal-import {
  .modal-content {
    .modal-body {
      & > div {
        .import-wrap {
          padding: 40px 0 60px;
          display: block;
          .btn-upload {
            width: 110px;
            display: inline-block;
          }
        }
      }
    }
  }
}

.file-support-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;

    li {
      text-transform: uppercase;
      background: #e4e4e4;
      color: #757474;
      font-size: 10px;
      line-height: 10px;
      margin: 0 4px;
      padding: 3px 5px;
      border-radius: 3px;
    }
  }
}

.succss-avatar-md {
  width: 10.5rem;
  height: 10.5rem;

  .succss-img {
    width: inherit;
    height: inherit;
  }
}

.upload-modal {
  max-width: 800px;
  @media (max-width: 1023px) {
    max-width: 700px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin: 15px;
    max-width: 100%;
  }

  .back-btn {
    min-width: 120px;
    position: absolute;
    left: 16px;
    top: 16px;
    margin: 0 !important;
    @media (max-width: 767px) {
      min-width: 60px;
    }
  }

  .import-wrap {
    background: #fafafa;
  }

  &.modal-import {
    h2 {
      font-size: 20px;
    }
  }

  .uplodedFile-list {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    &.folder-list {
      li {
        flex: 0 0 25%;
        max-width: 25%;
        @media (max-width: 767px) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        div {
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: 500;
          padding: 10px;
          cursor: pointer;
          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-left: 5px;
          }
        }
      }
    }
    li {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 10px;
      @media (max-width: 767px) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      @media (max-width: 575px) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
      @media (max-width: 480px) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      a,
      div {
        background: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        display: block;
        height: 166px;
        font-weight: 500;
        border: 1px solid #ccc;
        border-radius: 4px;
        position: relative;
        overflow: hidden;

        @media (max-width: 1023px) {
          height: 141px;
        }

        @media (max-width: 767px) {
          height: 104px;
        }

        h6 {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin: 0;
          min-height: 25px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 10px;
          border-top: 1px solid #ccc;
          text-align: left;
        }

        i {
          display: block;
          height: calc(100% - 36px);
          padding: 10px 10px 0;
          background: #efefef;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
  }
}

.custom-layout-picker {
  .bg-center {
    .select2-selection__control {
      min-width: 100px;
    }
  }
}

.breadcrumb {
  background: transparent;
  padding: 0;
  a {
    &.breadcrumb-item {
      color: #636678;
      &:hover {
        color: $header-dark-bg;
      }
    }
  }
  .breadcrumb-item {
    color: $header-dark-bg;
  }
}

.uplodedFile-list {
  .isDisabled {
    user-select: none;
    position: relative;
    opacity: 0.5;
    a {
      position: relative;
      cursor: not-allowed;
    }
  }
  .upload-ppt {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: 0;
    &:hover {
      .hover-btn {
        display: block;
        padding-top: 25px;
        background-color: RGB(0 0 0 / 42%);
        backdrop-filter: blur(5px);
        z-index: 5;
        span {
          margin-bottom: 10px;
          display: block;
          font-weight: bold;
          color: #fff;
          text-transform: uppercase;
        }
        .btn-group {
          border: 0;
          background-color: transparent;
          .btn {
            text-transform: uppercase;
            font-weight: bold;
            &:hover {
              color: #fff;
              opacity: 1;
            }
          }
        }
      }
    }
    .hover-btn {
      display: none;
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }

  .file {
    &-icon {
      position: relative;
    }

    &-extension {
      position: absolute;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      top: 5px;
      left: 5px;
    }
  }
}

.dragging {
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.99999;
  max-width: 250px;
  min-width: 250px;
  word-break: break-all;
  .presentation-card-title {
    width: 100%;
    display: block;
    font-size: 12px;
    padding: 20px;
    z-index: 2;
    background-color: #cfe0ff;
    box-shadow: 0px 0px 13px #0000007d;
    color: #06388f;
    cursor: pointer;
    img {
      margin-right: 5px;
      position: relative;
      top: -2px;
    }
  }
  p {
    display: none;
  }
}
.dragging-multiple {
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  z-index: 11111;
  max-width: 250px;
  min-width: 250px;
  word-break: break-all;
  position: relative;

  .presentation-card-title {
    width: 100%;
    display: block;
    padding: 20px;
    z-index: 2;
    background-color: #cfe0ff;
    margin-bottom: 0px;
    transform: translate(0px 0px);
    box-shadow: 0px 0px 13px #0000007d;
    color: #06388f;
    img {
      margin-right: 5px;
      position: relative;
      top: -2px;
    }
  }

  p {
    position: absolute;
    top: -10px;
    left: inherit;
    right: 0px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: rgb(245, 65, 65);
    text-align: center;
    font-weight: 500;
    color: #fff;
    z-index: 99999;
    padding: 2px;
    display: block;
    line-height: 21px;
  }
}

.sticky-select-file-btn {
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0;
  font-weight: 700;
  z-index: 99;
  i {
    font-size: 27px;
  }
}

.lazyloader {
  height: 100vh;
  background: #f1f1f1;

      &__no-background{
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }

  .lazyloader-wraaper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.drophere-text {
  color: grey;
  font-size: 36px;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 186px;
  right: 0px;
}

.sidebar-enable {
  .drophere-text {
    left: 50px;
  }
}
.entity-page{
  background: #eee;
  box-shadow: 0px 4px 4px 1px #d7d7d7;
  .row{
    &>div {
      padding-right: 5px;
      padding-left: 5px;
    }
    input{
      color: #3e3e3e;
    }
    .form-group{
      margin-bottom: 0;
    }
  }
}
.entity-wrapper{
  .btn-primary{
    box-shadow: 0px 4px 4px 1px #d7d7d7;
    background: #06388f;
  }
}
.entity-page{
  .filter__control{
    border-radius: 0;
    border: 0;
    .filter__indicator{
      color: #3e3e3e;
      padding: 8px 1px;
    }
    .filter__value-container{
      padding: 2px 4px;
      overflow-y: auto;
      max-height: 85px;
      .filter__placeholder{
        color: #3e3e3e;
      }
    }
  }
}

.search-wrapper{
  position: relative;
  margin-left: 10px;
}

.search-meeting-input{
    height: 38px;
    font-size: 15px;
    line-height: 21px;
    border-radius: 5px;
    font-family: "Gilroy";
    font-weight: 500;
    width: auto;
    margin-right: 20px;
    padding-right: 32px;
    width: 500px;
    text-transform: capitalize;

    @media(max-width:767px){
      width: 395px;
    }
}

.search-wrapper .search-icon{
    left: inherit;
    color: #b5b5b5;
    font-size: 20px;
    position: absolute;
    right: 30px;
    top: 9px;
}
.presentation-infinite, .room-infinite {
  table {
    thead {
      tr th:last-child {
        text-align: right;
      }
    }
  }
}
.presentation-infinite, .room-infinite {
 table {
  th,td {
    a {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        margin-right: 8px;
        width: 15px;
        position: relative;
        top: -1px;
      }
    }
    vertical-align: middle;
  }
}
}
.w-20px {
  width: 20px;
}
.infinite-scroll-component__outerdiv   {
  width: 100%;
}

.btn-clickable, .btn-clickable * {
  color: #193174 !important;
}
tr.selected {
  td {
    background-color: #0238a00a;
  }
}
.google-login-button {
  background-color: #4285f4;
  display: inline-flex;
  align-items: center;
  color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  div.img-container {
    margin-right: 10px;
    background: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 2px;
  }
  span {
    padding: 10px 10px 10px 0px;
    font-weight: 500;
  }
}
.browse-btn {
  padding: 9px 40px;
  font-size: 16px;
  font-weight: 500;
}

/***************************/
/*    Meeting Recording    */
/***************************/
.metting-main{
    padding-bottom: 100px;
  .metting-video-image{
    position: relative;
    height: 220px;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .user-name{
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0px;
      color: #fff;
      font-size: 14px;
      width: 100%;
      text-transform: capitalize;
      padding: 10px;
      text-align: center;
    }
  }
}
.play-stop-btn{
  margin-top: 0px;
  text-align: center;

  a{
    margin: 0px 5px;
    .fa{
      font-size: 18px;
      color: #000;
    }
  }
}

.line-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    width: 9px;
    height: 9px;
  }
}

.client-line-main{
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #F4F4F4;
  margin-top: 15px;
  @media(max-width:767px){
    flex-direction: column;
  }

  h6{
    font-family: "roboto";
    font-size: 16px;
    width: 170px;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: -10px;
    @media(max-width:767px){
      width: 100%;
      margin-top: 0px;
      margin-bottom: 20px;
    }
    span {
      background: $color-blue;
      border-radius: 5.09511px;
      width: 100px;
      display: inline-block;
      padding: 3px 5px;

      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12.2283px;
      line-height: 14px;
      text-align: center;
      color: #FFFFFF;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .client-line{
    background: #e2e0e0;
    height: 3px;
    position: relative;
    width: calc(100% - 0px);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .client-green-line{
      position: absolute;
      background: #5ABB58;
      height: 100%;
    }

  .client-stop-btn{
    background:#3C64F0;
    height: 15px;
    width: 18px;
    border-radius: 7px;
    position: absolute;
    top: -17px;
    cursor: pointer;
  }

  .stop-btn-yellow{
    background: #F5BC00;
    height: 17px;
    width: 17px;
    border-radius: 17px;
    position: absolute;
    cursor: pointer !important;
    top: -18px;
    z-index: 10;

    &:hover {
      box-shadow: $recording-timeline-box-shadow;
      z-index: 20;
    }
  }

  .stop-btn-red {
    background: #FF0000;
    height: 12px;
    width: 12px;
    border-radius: 7px;
    position: absolute;
    cursor: pointer;
    z-index: 0;
    top: -15px;
    z-index: 10;

    &:hover {
      box-shadow: $recording-timeline-box-shadow;
      z-index: 20;
    }
  }

  .participant-speaking{
    background-color: #5f5f5f;
    height: 15px;
    width: 5px;
    border-radius: 7px;
    position: absolute;
    top: -7px;
    cursor: pointer;
  }

  .objection-orange {
    background: #F56700;
    height: 19px;
    width: 19px;
    border-radius: 17px;
    position: absolute;
    cursor: pointer !important;
    top: -19px;
    z-index: 10;

    &:hover {
      box-shadow: $recording-timeline-box-shadow;
      z-index: 20;
    }
  }

  .active-question {
    box-shadow: $recording-timeline-box-shadow;
    z-index: 20;
  }
  }
}

.speaker-line-main{
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #F4F4F4;
  @media(max-width:767px){
    flex-direction: column;
  }

  .speaker-inner-main {
    display: flex;
    position: relative;
    width: calc(100% - 0px);
    cursor: pointer;
    @media(max-width: 960px){
      margin-left: 8px;
    }

    @media(max-width: 960px){
      margin-left: 0px;
    }

    .speaker-stop-btn-yellow{
      background: #F5BC00;
      height: 17px;
      width: 17px;
      position: absolute;
      cursor: pointer;
      border-radius: 17px;
      top: -2px;
      z-index: 1;

      &:hover {
        box-shadow: $recording-timeline-box-shadow;
        z-index: 5;
      }
    }

    .speaker-objection-orange{
      background: #F56700;
      height: 19px;
      width: 19px;
      position: absolute;
      cursor: pointer;
      border-radius: 9px;
      top: -3px;
      z-index: 2;

      &:hover {
        box-shadow: $recording-timeline-box-shadow;
        z-index: 5;
      }
    }

    .speaker-stop-btn-red {
      background: #FF0000;
      height: 12px;
      width: 12px;
      border-radius: 7px;
      position: absolute;
      cursor: pointer;
      top: 1px;
      z-index: 0;

      &:hover {
        box-shadow: $recording-timeline-box-shadow;
        z-index: 5;
      }
    }

    .active-question-speaker {
      box-shadow: $recording-timeline-box-shadow;
      z-index: 5;
    }
  }

  h6{
    font-family: "roboto";
    font-size: 16px;
    width: 170px;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: -2px;
    color:#4b2a82;
    @media(max-width:767px){
      width: 100%;
      margin-top: 0px;
      margin-bottom: 10px;
    }

    span {
      background: #F1F1F1;
      width: 100px;
      display: inline-block;
      padding: 3px 5px;
      border-radius: 5.09511px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12.2283px;
      line-height: 14px;
      text-align: center;
      color: #000000;
      white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }
  }

  .speaker-line{
    position: absolute;
    background: #555555;
    width: 15%;
    height: 14px;
    border-radius: 12px;
    margin-bottom: 20px;
    margin-right: 15px;
    margin-left: 0;
    display: flex;
    align-items: center;
    @media(max-width:767px){
      margin-left: 0px;
    }
  }
}

.force-speaker-color h6 span {
  background: #F1F1F1;
  color: #000000;
}

.force-client-color h6 span {
  background: $color-blue;
  color: #FFFFFF;
}

.swap-participants-dropdown {
  width: 170px;

  h6 {
    width: 100%;
    margin-bottom: 0;
  }

  .dropdown-menu {
    z-index: 1005;
    padding: 0;

    .dropdown-item {
      padding-top: 12px;
      height: 44px;
      text-align: center;
    }

    .dropdown-header {
      font-size: 12px;
      padding: 8px;
      margin-top: 6px;
      height: 28px;
      color: black;
      text-align: center;
    }

    h6 {
      text-transform: none;
    }
  }
}

.video-player-modal-text {
  font-family: "Gilroy", sans-serif;
  margin: 0;
  padding: 15px;
  font-size: 15px;
  text-align: justify;
  font-weight: 500;
}

.time-main{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  span{
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 600;
    color: #636678;;
  }
}

.metting-main{
  background:#FAFAFA;
  margin: 15px 12px;
  padding-top: 15px;
  @media(min-width:1100px){
    .container {
      max-width: 1200px;
    }
  }

  @media(min-width:1400px){
    .container {
      max-width: 100%;
    }
  }
}

.video-line-main{
  position: relative;
  padding: 20px 0px 35px;
  // cursor: pointer !important;

  .video-line-progress{
    position: absolute;
    height: 100%;
    top: 0;

    .progress{
      background: transparent;

      .bg-success{
        width: 1% !important;
        background: transparent !important;

        &::before{
          content: "";
          position: absolute;
          background: #bda8d2;
          width: 1px;
          height:100%;
          left: 0;
          top: -15px;
        }
      }
    }
  }
}

.rooms_iframe_header {
  text-align: center;
  color: black;
  margin-bottom: 20px;
  font-size: 36px;
}

.video-line {
  width: calc(100% - 146px);
  height: calc(100% + 22px);
  position: absolute;
  top: 0;
  left: 146px;
  display: block;
  border-left: 1.3px solid #e9e9e9;

  @media(max-width:1100px){
    left: 102px;
    width: calc(100% - 102px);
  }

  @media(max-width:767px){
    left: 0px;
    width: calc(100% - 0px);
  }

  .video-inner-line {
    height: calc(100% - 58px);
    width: 1px;
    background: #5A5A5A;
    position: absolute;
    left: 0%;
    top: 10px;
    z-index: 1;

    &::before{
      content: "";
      height: 1.5px;
      width: 12px;
      background: #5A5A5A;
      top: 0px;
      left: -5px;
      position: absolute;
    }

    &:after{
      content: "";
      height: 1.5px;
      width: 12px;
      background: #5A5A5A;
      bottom: 0;
      left: -5px;
      position: absolute;
    }

    span {
    left: -16px;
    top: -16px;
    position: absolute;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13.0297px;
    line-height: 15px;
    color: #3A3A3A;
    }
  }
}

.playing-transition {
  transition: all .15s linear;
  -webkit-transition:all .15s linear;
  -moz-transition:all .15s linear;
  -o-transition:all .15s linear;
}

.video-thumbnail {
  width: calc(100% - 150px);
  height: 35px;
  left: 150px;
  display: flex;
  flex-direction: column;
  position: relative;

  span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12.2283px;
    line-height: 14px;
    color: #000000;
    margin-bottom: 13px;
  }

  @media(max-width:767px){
    flex-wrap: wrap;
  }

  @media(max-width:1100px){
    left: 101px;
    width: calc(100% - 101px);
  }

  @media(max-width:767px){
    left: 0px;
    width: calc(100% - 0px);
  }

  .video-thumbnail-inner{
    width: 70px;
    height: 45px;
    padding: 0px 5px;

    @media(max-width:767px){
      width: auto;
      height: 35px;
      margin-bottom: 5px;
    }

    img{
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}
.header-control {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  div.title {
    font-weight: 600;
    font-size: 14px;
    display: flex;

    .attendee-header{
      font-family: "roboto";
      width: 150px;
      font-style: normal;
      font-weight: 500;
      font-size: 12.2283px;
      line-height: 14px;
      color: #000000;
    }

    .timeline-header{
      font-family: "roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12.2283px;
      line-height: 14px;
      color: #000000;
    }
  }
}

.meeting-name-header {
  font-family: "roboto";
  color: #000;
  font-style: normal;
  font-weight: 500;
  font-size: 30.5706px;
  line-height: 36px;
}

.header-dropdown {
  min-width: 180px;
  width: auto;
  margin-right: 20px;

  @media(max-width:767px){
    min-width: 100%;
    margin-bottom: 7px;
    margin-right: 0;
  }
  >div{
    background-color: #f8f8fb;
    border-radius: 0;
    border: 0;
    outline: none;
    box-shadow: none;
    text-align: center;
    color: $black;
    >div{
      justify-content: center;
      >span{
        width: 0;
      }
    }
  }
}

.video-container-bg {
  background:#2F2F2F !important;
}

@media(min-width:1100px) {
  .video-container.minimized {
    position: fixed;
    z-index: 10001;
    right: 0;
    bottom: 60px;
    width: 268px;
    margin: 16px;
    padding: 0;
    border: 1px solid #2F2F2F;
    background:#2F2F2F !important;
  }
}

.video-player-player-box {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 891px;
  text-align: center;
  margin: 0 auto;
}

.video-react {
  background-color: transparent;
}

.metting-main {
  video {
    margin-top: -2px;
  }
  .minimized {
    video {
      margin-top: 0;
      max-height: 500px;
    }
  }
}

.max-width-50px {
  max-width: 45px
}

.presentation-card.rooms-card:hover {
  border: 2px solid #387cff2e;
}

.btn-open-room {
  z-index: 999;
  font-size: 15px;
  font-weight: 500;
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  width: fit-content;
}

.btn-open-room:hover {
  opacity:0.9;
  color: #fff;
  background-color: $color-blue;
  border-color: $color-blue;
}

.hovering:after {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(5px);
}

.h-90vh {
  height: 90vh;
}

.fs-3 {
  font-size: 3rem;
}

.fs-12px {
  font-size: 12px;
}

.btn-transparent {
  padding: 0;
  background: transparent;
  border: 0;
  img{
    width: 37px;
    border: 1px solid #cccccc;
  }
}

.color-input, .color-input:hover {
  padding: 0.47rem 0.75rem;
  height: calc(1.5em + 0.94rem + 2px);
  border: 1px solid #cccccc;
  width: 148px;
}

.btn-reset {
  background: #cccccc;
  border: 1px solid #cccccc;
  color: #403c3c !important;
  padding: 8px 25px;
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.default-brand{
  padding-right: 5px !important;
}

#timeline-wrapper {
  overflow: hidden;
  padding-top: 5px;
  margin-bottom: 16px;
}
#meeting-ai-page {
  flex-direction: row;
  @media (max-width: 480px) {
    flex-direction: column;
  }

  .meeting-right-sidebar {
    min-width: 300px;
    max-width: 300px;
    padding-top: 2px;
    @media (max-width: 767px) {
      display: none !important;
    }

    *:not(.fa) {
      font-family: 'Roboto';
      font-size: 14px;
      margin-top: 0px;

      .small-comment {
        font-size: 12px;
      }
    }

    .tab-wrapper{
      &.card{
        box-shadow: none;
        background: transparent;
      }
    }
  }

  .nav-tabs {
    padding-left: 35px;

    a {
      padding-top: 0;
    }

    .nav-item {
      margin-right: 50px;
      &:last-child {
        border-right: 0 !important;
      }

      a{
        padding-right: 0;
        font-size: 16px;
      }
    }

    .nav-link  {
      color: #495057;
      font-family: 'Roboto';
      font-size: 16px;
      font-weight: 500;
      &.active {
        color: $color-blue;
      }
    }
  }
}

.transcription {
  margin-top: 1rem;

  .chat .transcript {
    padding-right: 0;

    .col-lg-12 {
      padding-right: 0;
    }
  }
}

.tab-transcript,
.tab-summary,
.tab-questions,
.tab-summary,
.tab-playbook,
.tab-data_insights,
.tab-chat {
  background-color: #FFFFFF;
  padding: 0 !important;
  margin-top: 15px;
}

.video-player-switch-component {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin: 0;

  @media (max-width: 1450px) {
    margin-left: 5px;
  }

  .switch {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      gap: 5px;

      &__label {
        font-size: 14px;
        margin: 0;
      }
      &__switch-button {
        width: 50px;
        height: 23px;
        margin: 0;

        .slider:before {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.video-player-tab-content-wrapper {
  border-radius: 5px;
  box-shadow: rgba(71, 88, 114, 0.1) 0 2px 6px;
  padding: 10px 15px;
}

.transcript {
  &__nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: rgba(71, 88, 114, 0.1) 0px 2px 6px;
    padding: 20px 25px !important;

    .nav-item:hover {
      background: transparent;
    }

    .modal-dropdown {
      width: auto;
    }

    .csv-button {
      position: absolute;
      right: 15px;
      padding: 5px 5px 10px;
      top: 5px;
    }

    &__action-buttons {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 1450px) {
        justify-content: center;
        align-items: flex-start;
        flex-direction: column !important;
        gap: 10px
      }

      @media (max-width: 1050px) {
        font-size: 12px !important;
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: nowrap;
        gap: 5px;
      }

      @media (min-width: 750px) and (max-width: 1050px) {
        flex-direction: column;
        align-items: flex-end;
      }
    }

    .tab-navigation {
      display: flex !important;
      justify-content: space-between;
      gap: 50px;
      align-items: center;
      padding: 10px 0 !important;
      margin: 0;
      flex-wrap: wrap;

      @media (max-width: 1850px) {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, auto);
        gap: 30px;
      }

      @media (max-width: 1450px) {
        font-size: 14px !important;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, auto);
        gap: 20px;
      }

      @media (max-width: 1050px) {
        padding: 5px 0;
        font-size: 12px !important;
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: nowrap;
        gap: 5px;
      }
    }
  }

  .transcript-search {
    @extend .card;
    margin: 0;

    &__icon:first-child {
      i {
        color: $gray-800;
      }
    }

    &__icon {
      @extend .input-group-prepend;

      color: $gray-400;
      & > * {
        border: none;
        background: none;
        i {
          font-size: 18px;
        }
      }

      i {
        color: $gray-400;
        &:hover {
          color: $gray-800;
        }
      }

      & .disabled {
        color: #f5f8fa;
      }
    }

    &__input {
      @extend .form-control;
      border: none;
      background: #ffffff;
      font-size: 16px;
      font-weight: 400;
    }

    &__result {
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }

  .transcript__item {
    margin: 10px 0;
    cursor: pointer;
    white-space: break-spaces;

    .badge {
      border-radius: 5.09511px;
      width: 100px;
      height: fit-content;
      display: inline-block;
      padding: 3px 5px;
      margin: 0 4px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12.2283px;
      line-height: 14px;
      text-align: center;
      color: #FFFFFF;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

    }

    &--details {
      width: 100%;
      justify-content: space-evenly;

      @media (max-width: 992px) {
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
    }

    div {
      display: flex;
      flex-wrap: wrap;
      padding-right: 0;
      padding-left: 0;
      font-size: 13px;
      line-height: 20px;
    }

    hr {
      color: #eff2f7;

      @media (min-width: 992px) {
        display: none !important;
      }
    }

    &.active {
      color: $color-blue;

      span.active {
        font-weight: 700;
      }
    }

    span.highlighted {
      background-color: $yellow;
      height: fit-content;
    }

    &__speaker {
      font-weight: 600;
    }
  }

  &-summary {
    padding: 0;
    text-align: justify;

    &-text {
      font-size: 16px;
      line-height: 20px;
      font-weight: normal !important;
    }

    &-section {
      margin: 10px;

      &-label {
        font-weight: 600;
      }

      &-list {
        li {
          list-style-type: disc;
        }
      }
    }
  }

  .gpt-prompt-page {
    margin: 15px 0;
    
    form {
      display: flex;
      align-items: flex-end;
      gap: 10px;

      .form-group {
        margin-bottom: 0;
        flex-grow: 1;
      }

      .prompt-textarea {
        min-height: 20px !important;
      }
    }
  }

  .gpt-prompt-tab {
    margin: 45px 0 30px 0;

    .gpt-prompt-nav-tab {
      padding-left: 0 !important;
    }
  }
}

.timeline-deleted-notice {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  width: 100%;
  top: -2.5%;
  height: 105%;
  z-index: 1005;

  &.background {
    background-color: black;
    opacity: 0.8;
  }

  &.text {
    font-size: 20px;
    color: white;
  }
}

.match-speakers-to-participants {
  padding: 5px 5px 20px 0;
  scroll-margin: 50px;

  .speaker-match-row {
    margin: 5px 0;
  }

  .speaker-match-actions {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .speaker-label {
    display: inline;
  }

  .longest-speech-region-btn {
    font-size: 10px;
    padding: 2px 5px;
    display: inline;
    margin: 5px 20px;
  }
}

#meeting-ai-column {
  h4 {
    font-weight: 700;
    padding-bottom: 10px;
    font-size: 16px;
    padding-top: 8px;
  }
  @media (max-width: 767px) {
    display: none !important;
  }
}

#meeting-ai-analytics {
  height: 100%;
  padding: 0 30px 32px;
  background: transparent;
  transition: margin-left .4s linear;

  + div {
    margin-left: 0;
    transition: margin-left .4s linear;
  }
  &.open {
    margin-left: 0;
  }

  *:not(.fa) {
    font-family: 'Roboto';
  }
  h2 {
    font-size: 18px;
    font-weight: bold;
  }
  h3 {
    font-size: 14px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 12px;
  }
  .score {
    margin: 20px 0;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
  }
  .bar {
    width: 0;
    height: 8px;
    margin: 2px 0 6px;
    background-color: $color-blue;
    transition: width .4s linear;
  }
  div:last-child {
    .bar {
      margin-bottom: 0;
    }
  }
}

button.share-button {
  position: fixed;
  left: 28px;
  bottom: 20px;
  z-index: 3;
  @media (max-width: 767px) {
    bottom: 73px;
  }

}
.thumbnails-title {
  width: calc(100% - 146px);
  float: right;
  font-weight: bold;
  @media(max-width:767px){
    display: none;
}
}
.thumbnails-line {
  width: calc(100% - 146px);
  margin-bottom: 0;
  float: right;
  display: flex;
  justify-content: space-between;
  @media(max-width:767px){
    display: none;
}

  li {
    margin: 0 4px;
    flex: 1;

    &:first-child { margin-left: 0; }
    &:last-child { margin-right: 0; }
    img {
      max-width: 100%;
      border: 1px solid #bbb;
    }
  }
}

.color-blue {
  color: $color-blue;
}
.color-yellow {
  color: #e8cb31;
}
.font-500 {
  font-weight: 500 !important;
}

.scrollbar-container > .ps__rail-x {   opacity: 0.6; }

#timeline-wrapper {
  overflow: initial;
}

#resizable-rnd-div {
  position: relative !important;
  z-index: 2;

  .rnd-tooltip {
    width: 130px;
    background: #fff;
    position: absolute;
    top: -115px;
    left: 0;
    z-index: 3;
    opacity: 1!important;
    padding: 10px 10px 6px 10px;
    color: #000;
    box-shadow: 5px 5px 20px #00000014;
    font-weight: 700;
    font-size: 13px;
    border: thin solid #dedede;

    div {
      margin-bottom: 3px;
      cursor: pointer;
    }

    div:last-child {
      margin-bottom: 0px;
    }
  }

  .rnd-tooltip:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 103px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
}

.modal-share-snippet {
  .modal-title {
    font-weight: bold;
    font-size: 21px;

    span {
      font-size: 13px;
      color: #4279F0;
    }
  }

  .modal-body {
    label {
      margin-bottom: 0;
      color: #676767;
      font-weight: bold;
    }

    input {
      margin-bottom: 15px;
    }

    textarea {
      margin-bottom: 15px;
    }

    .form-check {
      display: flex;

      label {
        margin-top: 2px;
        color: black;
      }

      margin-bottom: 15px;
    }
  }

  .modal-content {
    width: 600px;
  }

  .modal-title {
    font-weight: bold;
    font-size: 21px;

    span {
      font-size: 13px;
      color: #4279F0;
    }
  }

  .people-with-access-header {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;

    .header-part-1 {
      width: 45%;
    }

    .header-part-2 {
      width: 35%;
    }

    .header-part-3 {
      width: 20%;
      margin-left: 5px;
    }
  }

  .public-access-anyone{
    margin-right: 15px;
  }

  .public-comments{
    border: 1px;
    background: #EBECF0;
    padding: 3px;
    width: 30%;
  }

  .people-with-access {
    display: flex;
    flex-direction: column;

    .public-access-select {
      border: none;
      background-color: white;
      width: 70%;
      height: 50%;

      &:focus {
        outline: 0 !important;
      }

      &:active {
        outline: 0 !important;
      }
    }

    .general-access-text {
      font-size: 15px;
      font-weight: bold;
      padding-top: 35px;
      padding-bottom: 15px;
      margin-right: 157.5px;
    }

    .user-with-access {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 5px;

      .link-icon {
        display: flex;
        width: 100%;
        height: unset;
      }

      .icon-container {
        border-radius: 50%;
        background-color: #E6F4EA;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .earth-icon {
        width: 20px;
        height: 20px;
      }

      .user-picture-container {
        display: flex;
        width: 10%;

        .user-picture {
          width: 35px;
          height: 35px;
          background: #DFDFDF;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            display: inline-block;
            margin: 0;
            vertical-align: top;
            line-height: 34px;
            color: #000000;
            font-weight: 500;
            opacity: 1;
            text-transform: capitalize;
            text-align: center;
          }
        }
      }

      .user-name-email {
        display: flex;
        flex-direction: column;
        width: 35%;
        justify-content: center;

        .content-text {
          margin-left: 5px;
        }

        .user-name {
          font-weight: 700;
          font-size: 15px;
        }

        .user-email {
          color: #808387;
          font-size: 13px;
        }
      }

      .user-comments {
        width: 35%;
      }

      .can-comment {
        display: flex;
        justify-content: center;
        width: 20%;

        input {
          margin-bottom: 0;
        }
      }

      input {
        position: initial;
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .general-access {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 15px;

    .link-icon {
      display: flex;
      margin-top: 15px;
      width: 100%;
    }

    .link-description {
      font-size: 13px;
      font-weight: bold;
      margin-left: 20px;
    }
  }

  .button-link {
    color: #3077D7;
    background-color: white;
    font-weight: bold;
  }

  .button-done {
    color: white;
    background-color: #1A74E8;
    border: 1px solid #1A74E8;
    font-weight: bold;
  }

  .link-icon {
    width: 18px;
    height: 18px;
  }

  .modal-footer {
    justify-content: space-between;
  }

  .modal-footer-share {
    justify-content: space-between;
    padding-left: 0;

    .btn-primary {
      background-color: #4079F0;
      border-color: #4079F0;
    }
  }

  .botton-message-container {
    display: flex;
    .clipboard-message {
      color: #00B388;
      font-weight: bold;
      margin-left: 5px;
      margin-top: auto;
      margin-bottom: auto;
    }
    .btn {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.add-tag-modal-box {
  padding: 14px 16px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .form-body {
    margin-top: 1rem;
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 5px 0 12px;
    justify-content: space-between;

    .col-lg-3 {
      padding-right: 0 !important;
      white-space: nowrap;
    }

    .btn {
      width: 85px;
      padding: 5px 20px;
      margin-right: 6px;
      border-radius: 6px;
      font-size: $comments-font-size;
      box-shadow: none !important;
    }
  }
}
  .tags {
    display: flex;
    flex-direction: column;

    .add-tag {
      position: relative;
      z-index: 2;
      margin-top: 3px;
    }

    .add-tag-button {
      width: 100px;
    }
  }

  .tag {
      display: flex;
      justify-content: start;
      align-items: center;
      border-radius: 20px;
      background-color: #D6D6D6;
      color: #000;
      padding: 3px 0 3px 8px;
      width: fit-content;
      margin: 2px 0;

    .remove-button {
      padding: 2px;
      margin-left: 10px;
      font-size: 12px;
      background-color: #d6d6d6;
      color: #000 !important;
      border-color: #d6d6d6 !important;
    }
  }

.snippet-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: -10px 0 5px 11px;

  .tag {
    border-radius: 10px;
    background-color: #d6d6d6;
    font-weight: 500;
    color: #000;
    margin-right: 5px;
    padding-left: 10px;
  }
}

.tag-error {
  color: $red-error;
  margin: 12px;
}

.calendar-page {
  .select2-selection__menu {
    left: 0;
  }
}

.edit-participants-btn {
  position: absolute;
  margin-top: 0.5rem;
  padding: 0.3rem 0.75rem;
}

.edit-participants-modal {
  .modal-header {
    padding: 1.5rem 2rem;

    .close-chat-button {
      justify-content: center;
      transform: translateX(0.8rem);

      span {
        transform: scale(2);
      }
    }
  }

  .modal-body {
    padding: 0 2rem;
  }

  .modal-footer {
    padding-top: 0;
    padding: 0 1.5rem 0.75rem 0.75rem;
  }
}
