.tagContainer {
  padding-left: 5px;
  border: 1px #CED4DA solid;
  border-radius: 5px;
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px;
}
  
.tagContainer input {
  border: none;
  border-radius: 5px;
  border-color: transparent;
  width: auto !important;
}
  
.tag {
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 10px;
  border: 1px solid #74788d;
  padding-left: 3px;
  border-radius: 5px;
  background-color: #74788d;
  white-space: nowrap;
  color: white;
  border-color: transparent;
}
  
.tag button {
  display: flex;
  padding: 1px 6px;
  border: none;
  background-color: unset;
  cursor: pointer;
  color: white;
}

.no-style {
  background-color: transparent;
  border: none !important;
  
  &:hover {
    background-color: transparent;
  }
  
  &:active {
    border: none !important;
    background-color: transparent !important;
  }
  
  &:visited {
    border-color: transparent !important;
  }
}

.buttonTextColorImportant {
  color: black !important;
  background-color: transparent;
  margin: 2px;
} 
