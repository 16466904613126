.pre-box {
  @media (min-width: 1366px) {
    .col-md-6.col-lg-3 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}

.presentation-cardtext {
  padding: 0px 20px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #7c7c7c !important;
  margin-bottom: 1px;
}

.presentation-cardtitle {
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  margin: 0 0 0px 0;
  font-weight: 600;
  padding-right: 10px;
}

.presentation-dropdown {
  background-color: transparent;
  padding: 0px;
  outline: none !important;
  border: none !important;
  i {
    color: black;
  }
}

.presentation-dropdown:hover,
.presentation-dropdown:focus {
  background-color: transparent;
}

.presentation-cardbody {
  // MAKE SURE IT DOES NOT EFFECT LEFT  ".CARD" -> give main container a class and have it under that
  background-color: #f9f9f9;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0px;
  padding-bottom: 20px;
}

.presentation-cardbody iframe {
  height: 166px;
  background-color: #fff;
  margin-bottom: 12px;
  border-bottom: thin solid #f1f1f1 !important;
}

.presentation-addnewbutton {
  width: 100%;
}

.presentation-search {
  height: 100%;
}

.dashboard-main {
  .folder-menu {
    background-color: #fff;
    box-shadow: rgba(71, 88, 114, 0.1) 0px 2px 6px;
    text-align: center;
    margin-bottom: 25px;
    h3 {
      color: #000000;
      font-size: 12px;
      padding: 15px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Gilroy";
      font-weight: 600;
      img {
        margin-right: 8px;
      }
    }
    &:hover {
      border: 2px solid #387cff2e;
    }
    &.active {
      // background: $color-blue;
      border: 2px solid #387cff;
      &:hover {
        border: 2px solid #387cff;
      }
      // h3 {
      //   color: #fff;
      //   img {
      //     filter: invert(100%) sepia(2%) saturate(0%) hue-rotate(208deg)
      //       brightness(102%) contrast(101%);
      //   }
      // }
    }
  }
  .folder-menu.drag-over {
    border: 2px solid #387cff2e;
  }
  .card-top-part {
    width: 100%;
    height: 142px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    border-radius: 5px 5px 0px 0px;
    padding: 0px;
    p {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      font-family: "Gilroy";
    }
    img {
      width: 89px;
      height: 26px;
      margin: 0 auto;
      display: block;
    }
  }
  .sales-part {
    background: #1b1b1b;
    text-align: center;
  }
  .offer-part {
    background: #8b31ca;
    text-align: center;
  }
  .sales-blue-part {
    background: #319cca;
    img {
      position: absolute;
      left: 20px;
      top: 20px;
      width: 51px;
      height: 15px;
    }
  }
  .other-part {
    background: #3a3a3a;
    align-items: flex-end;
    align-content: flex-end;
    p {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .card-text {
    h6 {
      color: #333;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      font-family: "Gilroy";
    }
    p {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 7px;
      color: #606060;
      display: flex;
      align-items: center;
      font-family: "Gilroy";
      .bx-folder {
        color: #8b31ca;
        margin-right: 4px;
      }
      img {
        margin-right: 5px;
      }
    }
  }
  .custome-box {
    @media (max-width: 992px) {
      margin-top: 20px;
    }
    .flex {
      @media (max-width: 850px) and (min-width: 415px) and (orientation: landscape) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @media (max-width: 767px) and (min-width: 320px) and (orientation: portrait) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .presentation-main {
    .breadcrumb {
      .breadcrumb-item {
        font-size: 16px;
        &.drag-over {
          text-decoration: underline !important;
        }
      }
    }
    margin-top: 40px;
    &.room-main {
      margin-top: 0;
    }
    .card {
      // min-height: 90%;
      height: 100%;
      margin: 0;
      border: 2px solid transparent;
      border-radius: 0;
    }
    .pesentation-upload {
      // height: 91%;
      display: flex;
      @media (max-width: 991px) {
        min-height: 208px ;
      }
      .file-upload-main {
        height: 100%;
        input[type="file"] {
          display: none;
        }
        .file-upload-label {
          cursor: pointer;
          display: flex;
          height: 100%;
          justify-content: center;
          align-content: center;
          flex-wrap: wrap;
          font-size: 16px;
          font-weight: 500;
          color: #909090;
          font-family: "Gilroy";
          text-align: center;
          @media (max-width: 455px) {
          min-height: 260px;
          }
          margin-bottom: 0;
          .bx-plus {
            width: 100%;
            text-align: center;
            font-weight: 700;
            font-size: 65px;
          }
        }
      }

      .p-upld {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-height: 160px;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: RGB(2 51 143 / 11%);
          .p-upld-wrap {
            .btn {
              color: $white;
              &:hover {
                opacity: 0.85;
              }
            }
          }
        }
        .p-upld-wrap {
          .btn {
            font-weight: bold;
            display: block;
            width: 100%;
            margin-bottom: 8px;
            transition: all 0.3s ease-in-out;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      &:hover{
        border: 2px solid #387cff2e;
      }
    }
  }
}

.popperViewFolderContent {
  min-width: 310px;
  max-width: 310px;
  .fa {
    font-size: 14px;
  }
  .popover-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    .heading {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 10px;
      .fa {
        position: relative;
        top: -1px;
      }
      .title {
        font-size: 16px;
        line-height: 1.4;
        margin: 0 10px;
      }
    }
    .closeBtn {
    }
  }
  .popover-body {
    padding: 0;
    .p-view-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      max-height: 140px;
      min-height: 140px;
      overflow: hidden;
      overflow-y: auto;
      position: relative;
      li {
        padding: 8px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        &.selected {
          background-color: $color-blue;
          color: #fff;
          .name {
            color: #fff;
          }
        }
        &.disable {
          cursor: not-allowed;
          background-color: #d7d7d7;
          &:hover {
            cursor: not-allowed;
            background-color: #d7d7d7;
            .next {
              display: none;
            }
          }
        }
        &:hover {
          background-color: rgb(240, 240, 240);
          &.selected {
            background-color: $color-blue;
            color: #fff;
          }
          .next {
            right: 10px;
          }
        }
        .icon {
          margin-right: 10px;
        }
        .name {
          font-size: 14px;
          margin: 0;
          line-height: 1.4;
        }
        .next {
          position: absolute;
          top: 12px;
          right: -50px;
          transition: all 0.3s ease-in-out;
        }
      }
      span {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
      }
    }
    .p-view-footer {
      border-top: 1px solid #e7e7e7;
      padding: 10px 10px;
      .action {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.recent-use {
  .btn-secondary {
    background-color: transparent !important;
    color: #4e4e4e !important;
    border: none;
  }
}

.dashboard-design {
  // padding-top: 80px !important;
  /*padding-bottom: 150px;*/
  @media (max-width: 992px) {
    // margin-top: 60px !important;
  }
  @media (max-width: 767px) {
    padding-top: 60px !important;
  }
}

.search-page {
  @media (max-width: 767px) {
    .custome-tabs {
      padding-top: 15px !important;
    }
  }
}

.presentations-part {
  padding-left: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  li {
    list-style-type: none;
    padding: 0px 10px;
    a {
      color: #4e4e4e;
      font-size: 14px;
      font-family: "Gilroy";
      font-weight: 500;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .bx-check {
        font-size: 16px;
        margin-left: 8px;
        margin-top: 2px;
      }
    }
  }
}

.folder-image {
  margin-bottom: 30px;
  h3 {
    color: $color-blue;
  }
  img {
    width: 23px;
    height: 19px;
    margin-right: 10px;
  }
}

.custome-tabs {
  .nav-tabs {
    border: none;
    margin-bottom: 20px;
    .nav-link {
      background: transparent;
      border: none;
      color: #bebebe;
      font-weight: 600;
      font-size: 22px;
      background-color: transparent;
      &.active {
        color: #434343;
        background: transparent;
        background-color: transparent;
      }
    }
  }
}

.loader-wraaper {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  img {
    width: 150px;
  }
}

.presentation-card {
  &.document-card {
    &.selected {
      // .card-body {
      //   background: rgba(0, 69, 202, 0.2);
      // }
      // .presentation-card-title {
      //   color: $color-blue;
      // }
      border: 2px solid #387cff;
      &:hover {
        border: 2px solid #387cff;
      }
      &.whenDrag {
        border: 2px solid #387cff;
      }
    }
  }
  &.document-card {
    &.active {
      border: 2px solid #387cff;
      &:hover {
        border: 2px solid #387cff;
      }
    }
  }
  &.whenDrag {
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 0.2;
  }
  .card-body {
    border-top: thin solid #f0f0f0;
    background: #fbfbfb;
    @media (max-width: 1500px) {
      padding: 15px 10px;
    }
    @media (max-width: 1060px) {
      padding: 15px 7px;
    }
    @media (max-width: 1060px) {
      padding: 15px 10px;
    }
    .dropdown,
    .play-button {
      display: inline-block;
      cursor: pointer;
    }

    .dropdown-menu.show {
      left: -145px !important;
      top: -200px !important;
      transform: none !important;
    }
  }
}

.presentation-card.document-card {
  &:hover {
    border: 2px solid #387cff2e;
  }
}

.file-card {
  .card-body {
    .dropdown-menu.show {
      left: -145px !important;
      top: -170px !important;
      transform: none !important;
    }
  }
}

.template-box {
  .presentation-card {
    .dropdown-menu.show {
      left: -145px !important;
      top: -170px !important;
      transform: none !important;
    }
  }
}

.files-section {
  .card-body {
    .dropdown-menu.show {
      left: -145px !important;
      top: -142px !important;
      transform: none !important;
    }
  }
}

.rooms-card {
  &.presentation-card {
    .dropdown-menu.show {
      left: -145px !important;
      top: -115px !important;
      transform: none !important;
      z-index:999999;
    }
      &.active {
        border: 2px solid #387cff;
        &:hover {
          border: 2px solid #387cff;
        }
    }
  }
}

.modal-import {
  display: flex;
  justify-content: center;
  text-align: center;
  h2 {
    font-size: 30px;
    color: #000;
    font-weight: 500;
    margin-bottom: 18px;
    @media (max-width: 575px) {
      font-size: 24px;
      margin-bottom: 12px;
    }
  }
  .modal-content {
    border-radius: 0;
    .modal-body {
      & > div {
        width: 100%;
        &.alert-danger {
          width: 100%;
        }
        .import-wrap {
          width: 100%;
          background-color: #f5f5f5;
          height: 280px;
          margin-bottom: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .btn-upload {
            position: relative;
            margin-bottom: 30px;
            .form-control-file {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              cursor: pointer;
              width: 110px;
              height: 75px;
              opacity: 0;
            }
            p {
              font-size: 14px;
              color: #595959;
            }
          }
        }
        .import-success-wrap {
          display: flex;
          text-align: center;
          flex-direction: column;
          .block {
            max-width: 300px;
            margin: 0 auto;
            width: 100%;
            height: 160px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #1b1b1b;
            text-align: center;
            flex-direction: column;
            h5 {
              font-size: 18px;
              color: #fff;
              font-weight: 500;
              margin-bottom: 10px;
            }
            span {
              font-size: 12px;
              color: #fff;
            }
          }
          .btn-wrap {
            margin-top: 25px;
            .btn-close {
              background-color: transparent;
              padding: 0;
              font-size: 18px;
              color: #6b6b6b;
              border: 0;
              margin-right: 25px;
              &:hover {
                color: $color-blue;
              }
              @media (max-width: 575px) {
                font-size: 14px;
              }
            }
            .btn-blue {
              padding: 18px 24px;
              border-radius: 5px;
              border: 0;
              font-size: 18px;
              line-height: 18px;
              color: #fff;
              background-color: $color-blue;
              &:hover {
                background-color: #1b1b1b;
              }
              @media (max-width: 575px) {
                font-size: 14px;
                line-height: 14px;
                padding: 14px 20px;
              }
            }
          }
        }
      }
    }
  }
}

iframe {
  #slideContainer {
    transform: inherit !important;
  }
}
.presentation-card-title {
  width: 81%;
  cursor: pointer;
  font-weight: 600;
  color: #333333;
  font-family: Gilroy;
  cursor: pointer;
  @media (max-width: 1060px) {
    width: 70%;
  }
}

.folder-name {
  font-weight: 600;
  color: #606060;
  font-family: Gilroy;
}

.iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    overflow: hidden;
    border: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  embed {
    width: calc(100% + 17px);
    background-color: #ffffff;
  }
  // &.pdf-wrapper {
  //   padding-bottom: 0;
  // }
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.search-user {
  background: #f7f7f7;
  border: none;
  text-align: center;
  height: 50px;
}

.share-btn {
  width: 170px;
  height: 38px;
  background: #373737;
  border-radius: 5px;
  margin-bottom: 10px;
}

.modal-import {
  h2 {
    &.share-title {
      margin-bottom: 0px;
      font-family: Gilroy;
    }
  }
}

.share-subtext {
  font-weight: 600;
  font-size: 14px;
  font-family: Gilroy;
  line-height: 17px;
}

.share-modal {
  max-width: 70%;
}
.modal-import .modal-content .modal-body > div.search-wrapper {
  position: relative;
  width: 70%;
  margin: 35px auto;
}
.search-dropdown {
  position: absolute;
  padding: 40px 50px;
  background: #ffffff;
  width: 100%;
  text-align: left;
  z-index: 99999;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.05);
  @media (max-width: 414px) {
    padding: 40px 15px;
  }
  p {
    margin: 15px 0;
  }
  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #6d6d6d;
  }
  .head {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
}
.share-modal {
  .checkbox {
    margin: 15px auto;
    position: relative;
    label {
      position: relative;
      min-height: 25px;
      padding-left: 30px;
      margin-bottom: 0;
      font-weight: normal;
      padding-right: 15px;
      cursor: pointer;
      // display: flex;
      // align-items: center;
      span {
        top: 50%;
        transform: translateY(-50%);
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 22px;
        height: 22px;
        transition: transform 0.28s ease;
        background: #f5f5f5;
      }
      &:after {
        content: "";
        display: block;
        width: 10px;
        height: 5px;
        border-bottom: 2px solid $color-blue;
        border-left: 2px solid $color-blue;
        transform: rotate(-45deg) scale(0);
        transition: transform ease 0.25s;
        position: absolute;
        top: 7px;
        left: 6px;
      }
    }
    input[type="radio"],
    input[type="checkbox"] {
      width: auto;
      opacity: 0.00000001;
      position: absolute;
      left: 0;
      margin-left: -5px;
      &:checked ~ label {
        &:after {
          transform: rotate(-45deg) scale(1);
        }
      }
      &:focus + label::before {
        outline: 0;
      }
    }
  }

  .shared-title {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align: center;
    border-bottom: 1px solid #e7e7e7;
    padding: 10px;
    margin-bottom: 35px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .teams-title {
    font-family: Gilroy;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #3a3a3a;
  }

  .view-btn {
    background: #ebebeb;
    border-radius: 5px;
    padding: 2px 10px;
    margin-right: 10px;
  }
  div.shared-with-wrapper {
    margin: 40px 0;
    width: 100% !important;
  }
  @media (max-width: 991px) {
    .border-tag {
      border-bottom: 1px solid #e7e7e7;
      margin-bottom: 20px;
      margin-left: 25px;
      margin-right: 30px;
      width: 100%;
    }
  }
  .shared-wrapper {
    padding: 0px 50px;
    &.first {
      border-right: 1px solid #e7e7e7;
    }
    @media (max-width: 991px) {
      border-right: 0px !important;
    }
    h5 {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #3a3a3a;
      text-align: left;
      margin-bottom: 15px;
    }
    .right-view {
      flex-direction: row;
      display: flex;
      align-self: center;
    }
  }
  .mdi {
    color: #c4c4c4;
  }
  .team-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h5 {
      color: #6d6d6d;
      width: 70%;
      word-break: break-all;
      text-transform: capitalize;
    }
    p {
      font-size: 8px;
      color: #9d9d9d;
      width: 40px;
    }
  }
  .small-tital {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #8e8e8e;
    display: inline-block;
    padding-right: 15px;
  }
  .checkbox-main {
    display: inline-block;
    width: 100%;
    margin: 10px 0px 10px;
    .checkbox {
      display: inline-block;
      vertical-align: top;
      margin: 0px;
      width: 100%;
      .label-for-edit {
        top: -10px;
        padding-left: 35px;
        text-transform: capitalize;
      }
      .radio {
        display: inline-block;
      }
    }
  }
  .btn-done {
    width: 96px;
    height: 39px;
    background: #303030;
    border-radius: 5px;
    color: #fff;
    font-family: Gilroy;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    border: 1px solid #303030;
    margin-top: 10px;
    &:hover,
    &:focus {
      background-color: #fff;
      color: #303030 !important;
    }
  }
}
.flex {
  display: flex;
  & > div {
    width: 100%;
  }
}

.infinite-scroll-component {
  overflow: inherit !important;
}
.drop-color {
  background-color: #cfd8f5 !important;
}
.present-dropdown {
  .dropdown-toggle {
    width: 160px;
  }
}
.file-document {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  canvas {
    width: 100%;
  }
}
.react-pdf__Page {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.react-pdf__Page__canvas {
  width: 100% !important;
}
.loader-modal {
  .modal-content {
    background-color: transparent;
    border: transparent;
  }
}
.preview {
  .pg-viewer-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: center;
  }
}
.pdf-viewer {
  text-align: center;
}
.presentation_content-wrapper {
  &.page-content {
    padding-bottom: 15px !important;
  }
}
.presentation-header {
  @media (max-width: 767px) {
    flex-wrap: wrap !important;
  }
  .app-search {
    &.d-lg-block {
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
  }
}
.preview-image {
  max-width: 100%;
}
.pdf-canvas {
  canvas {
    width: 80%;
  }
}

.presentation-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  @include media-breakpoint-down(md) {
    display: block;
  }
  .pheader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
    }
    @include media-breakpoint-down(sm) {
      display: block;
    }

    .form-check-label {
      display: flex;
      align-items: center;
    }
    &.pheader-search {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
        justify-content: flex-start;
      }
      .search-wrapper-meet {
        @include media-breakpoint-down(sm) {
          margin-bottom: 0;
        }
      }
    }
    .checkbox {
      margin: 7px 0;
      @include media-breakpoint-down(sm) {
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
  .search-present {
    .form-control {
      background-color: #f8f8fb;
      border-radius: 5px;
      border: 0;
      outline: none;
      box-shadow: none;
      width: auto;
      text-align: left;
      color: #000;
      margin-right: 0px;
      min-width: 260px;
      min-height: 38px;
      padding-right: 40px;
      @media screen and (max-width: 1200px) {
        min-width: auto;
      }
    }
    span {
      top: 8px;
      right: 10px;
    }
  }
  .present-dropdown {
    width: 200px;
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 15px;
    }
    .css-yk16xz-control {
      background-color: #f8f8fb;
      border-color: #f8f8fb;
    }
    .css-1pahdxg-control {
      background-color: #f8f8fb !important;
    }
    .css-26l3qy-menu {
      padding: 10px;
      z-index: 99999999;
    }
    .share-modal {
      &:first-child {
        margin-top: 7px;
      }

      .css-bignbx-option,
      .css-oox6q8-option,
      .css-mzz236-option {
        background-color: #fff;
        color: #000;
        padding: 2px 10px;
        .checkbox {
          background-color: transparent;
          margin: 0;
          label {
            display: block;
            font-weight: 600;
            &::before {
              width: 16px;
              height: 16px;
            }
            &::after {
              top: 5px;
              left: 4px;
            }
          }
        }
      }
    }
  }
}
.presentation-headerwrapper {
  background-color: #ffffff;
  padding: 20px;
  h2 {
    color: #000;
  }
}
.btn-blue {
  color: #fff;
  background-color: $color-blue;
  border-color: $color-blue;
}
.folder-menu {
  &.selected-btn {
    // background-color: rgba(0, 69, 202, 0.2) !important;
    border: 2px solid #387cff !important;
    &:hover {
      border: 2px solid #387cff !important;
    }
    // h3 {
    //   color: $color-blue !important;
    // }
    &.whenDrag {
      -webkit-transition-duration: 0.15s;
      transition-duration: 0.15s;
      -webkit-transition-property: opacity;
      transition-property: opacity;
      -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
      opacity: 0.2;
      border: 2px solid #387cff;
    }
  }
}
.min-height-208px {
  min-height: 208px;
}


.page-note {
  width: 100%;
  display: flex !important;
  padding: 5px 95px 10px 95px;

  &-input {
    width: 100%;
    min-height: 80px;
    outline: none;
    border: none;
    flex-direction: column;
    display: flex !important;
    height: 180px;
  }

  &.centered {
    justify-content: center;
  }
}

.preview .modal-body {
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.add-note-button:hover {
  background: #3d64f9;
}

.add-note-button {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;

  border: none;
  cursor: pointer;

  color: #FFFFFF;width: 75px;
  height: 38px;


  background: #3C64F0;

}