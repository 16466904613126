.filter-modal {
  .modal-title {
    font-weight: 600;
    width: 40%
  }

  .modal-content {
    box-sizing: border-box;
    max-height: 90vh;
    overflow: auto;
  }

  h6 {
    padding-top: 30px;
    font-weight: 700;
    font-size: 15px;
  }

  .filter-item-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .filter-item {
    display: grid;
    grid-template-columns: 100px 1fr;

    .filter-item-label {
      display: flex;
      align-items: center;
    }

    .filter-item-row {
      flex: 1;
    }

    .select2-selection__menu-list {
      padding: 10px;
      max-height: 200px;
      overflow: auto;
    }
  }

  .close-button {
    border: none;
    right: 0;
    position: absolute;
    width: 30px;
    margin-right: 7px;
    border-radius: 5px;
    background-color: transparent;
  }

  @media screen and (max-width: 630px) {
    .modal-content {
      width: 90%;
    }

    .filter-container {
      width: 200px;
    }
  }

  @media screen and (min-width: 631px) and (max-width: 1158px) {
    .modal-content {
      width: 91vw;
    }

    .filter-container {
      width: 40%;
      min-width: 260px;
    }
  }

  @media screen and (min-width: 1159px) and (max-width: 1450px) {
    .modal-content {
      width: 90vw;
      max-width: 1200px;
    }
  }

  @media screen and (min-width: 1455px) {
    .modal-content {
      width: 80vw;
      max-width: 1200px;
    }
  }
}

.filter-container {
  position: sticky;
  top: 0;
  width: 380px;
  flex-shrink: 0;
  border-right: 1px solid #f5f8fa;
  padding: 0 10px 0 20px;
  overflow-x: hidden;
}

.widget-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95% !important;
}

.hidden {
  display: none;
}

.visible {
  width: 100%;
  display: block;
}

.tab-container {
  margin: 0 0 15px 15px;
  display: flex;
  justify-content: center;
}

.active-tab {
  cursor: pointer;
  padding: 10px;
  border-bottom: 2px solid blue;
  font-weight: bold;
}

.inactive-tab {
  cursor: pointer;
  padding: 10px;
  opacity: 0.6;
}

.edit-container {
  display: flex;
  width: 100%;
  padding: 0 !important;
}

.filters-container {
  padding: 10px;
}

.raw-table-no-data {
  display: flex;
  justify-content: center;
  color: #bebebe;
  font-weight: 500;
}

.color-picker-box {
  width: 30px;
  height: 30px;
  border: 1px solid #bebebe;
  cursor: pointer;
  border-radius: 4px;
}

.widget-title-input {
  width: 325px;
  font-size: 17px;
  font-weight: 500;
  border: none;
  padding: 10px !important;
  height: 35px !important;
  border-color: #F1F1F1;

  &:hover, &:focus, &:active {
    border: 1px solid #F1F1F1;
    outline: none;

    & + .edit-pen {
      opacity: 0.6;
    }
  }
}

.widget-title-edit {
  display: flex;
  align-items: center;
}

.edit-pen {
  height: 20px;
  width: 20px;
  opacity: 0;
  margin-left: 5px;
}
