.integration-calendars-group {
  display: flex;
}

.integration {
  display: flex;
  align-items: center;
}

.modal-width {
  .modal-content {
    width: auto;
    padding-bottom: 15px;
  }
}

.integration-card-connect {
  background-color: green;
  color: white;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin: 20px 0 !important;
  width: 150px !important;
}