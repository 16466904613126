@mixin info-box {
  padding: 5px 10px 5px 15px;
  margin-bottom: 10px;
  font-weight: 500;
  margin-right: 5%;
  width: 90.25%;  // 95% of 95%, don't ask
  border-radius: 5px;
}

body.on-ai-agent-form {
  background-color: white;
  overflow: hidden;

  .footer {
    position: relative;
  }
}

.ai-agent-page {
  // Main header is 53px, main footer 60px
  height: calc(100vh - 53px - 60px);
  display: flex;
  flex-direction: column;
}

//Header
.ai-agent-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid #F5F5F5;
  padding: 10px 25px;
  padding-bottom: 6px;
  z-index: 1;

  &__first-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-items: start;
    min-width: fit-content;
  }

  &__title {
    margin: 0 20px 0 0 !important;
    min-width: fit-content;
    color: black;
    font-weight: 700;
    font-size: x-large;
  }

  &__return-button {
    padding: 5px 0;
    min-width: fit-content;
  }

  &__spacer {
    max-width: 100%;
    flex-grow: 1;
  }

  &__second-block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .ai-agent-tryout-container {
      display: flex;
      gap: 5px;
      align-items: center;
      padding: 5px 10px;
      transform: translateY(-2px);

      .try-agent-button {
        background-color: #001AFF !important;
        width: 110px;
      }

      .session-wrapper {
        white-space: nowrap;
        width: 300px;
      }

      .save-button {
        width: 100px;
        height: 38px;
        margin-left: 10px;

        button {
          width: 100%;
          background-color: #3e3e3e;
          &:hover {
            background-color: #2b2b2b;
          }
        }
      }
    }

    .custom-switch-wrapper {
      transform: translateY(-2px);

      .custom-switch-button {
        min-width: 45px;
      }

      .custom-switch-label {
        font-size: 13px;
        transform: translateY(1px);
        min-width: fit-content;
      }
    }

    .settings-agent-actions--burger {
      position: relative;
    }
  }

  &__history-button {
    font-size: 13px;
    font-weight: 400;
    padding: 10px;
    margin-left: 10px;

    i.fa-history {
      margin-right: 5px;
    }

    &:hover {
      background-color: #f6f8fa;
    }
  }

  .ai-agent-tabs {
    display: flex;
  }

  .ai-agent-tab {
    margin: 0 10px 0 0;
    cursor: pointer;
  }

  .active {
    font-weight: 700;
  }
}

.ai-agent-requests-history-modal {
  min-width: 800px !important;
}

//Layout
.ai-agent-layout {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 70px;

  & > * {
    flex: 1;
  }
}

//Form
.ai-agent-form {
  display: flex;
  flex-direction: column;
  max-width: 750px;
  padding: 10px 0 40px 20px;
  overflow-y: scroll;

  &--pushed-down {
    z-index: 0;
    overflow: hidden;
  }

  &__beta-notice {
    @include info-box;
    background-color: #f6f8fa;

    .bold {
      font-weight: bold;
    }
  }

  &__locked-warning {
    @include info-box;
    background-color: #dcedfc;
  }

  &__inputs-wrapper {
    padding: 10px 0;
    margin-right: 5%;
  }

  &__input {
    width: 95%;
    margin-bottom: 10px;

    &--textarea {
      min-height: 190px;
      width: 95%;
    }

    &--footer {
      display: flex;
      margin: 15px 0 10px 0;
      gap: 11px;
      width: 95%;
    }
  }

  &__error {
    font-size: 12px;
    margin: 0 0 10px 0;

    &--gap-top {
      margin-top: 5px;
    }
  }

  .playbook-button-section {
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 95%;


    .playbook-button {
      display: flex;
      align-items: center;
      margin: 10px 0 15px 0;
      padding: 5px 10px 5px 0;
      width: fit-content;
      background-color: #e0e0e0;
      border: none;

      &:hover {
        background-color: #b0b0b0;
      }

      &:focus {
        background-color: #e0e0e0;
      }

      .playbook-button-label {
        color: black;
        font-weight: 500;
        margin-left: 10px;
      }
    }
  }
}

//Preview
.ai-agent-preview {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 15px;

  border-left: 1px solid #f5f5f5;
}

.ai-agent-preview-tabs {
  display: flex;
  margin-left: 0;
  gap: 15px;
  cursor: pointer;

  .ai-agent-preview-tab {
    &.active {
      font-weight: 700;
    }
  }
}

.ai-agent-response {
  overflow-y: auto;
  padding: 15px 10px 0 0;
}

.ai-agent-playbook-summary {
  margin: 15px 5px;
  border: 1px rgba(0, 0, 0, 0.14) solid;
  background-color: #F6F8FA;
  padding: 10px 15px;

  &__title {
    font-size: 14px;
    font-weight: bolder;
  }

  &__text {
    font-weight: 500;
    overflow-y: auto;
    text-align: justify;
    font-size: 13px;
    line-height: 1.5;
  }
}

.footer-wrapper {
  width: 95%;
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: row-reverse;
}

.override-default-style {
  width: 100%;
}

//Title dots animation
.loading-dots::after {
  content: ' ';
  display: inline-block;
  width: 1em;
  text-align: left;
  animation: loading 1s steps(3, end) infinite;
}

@keyframes loading {
  0% {
    content: ' ';
  }
  33% {
    content: '.';
  }
  66% {
    content: '..';
  }
  100% {
    content: '...';
  }
}

//Switch
.custom-switch-wrapper {
  display: flex;
  align-items: center;
  padding: 10px;
  padding-right: 0;
}

.custom-switch-button {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
  margin: 0 5px 0 0 !important;
}

.custom-switch-button input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.custom-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.custom-switch-button input:checked + .custom-slider {
  background-color: #2196F3;
}

.custom-switch-button input:checked + .custom-slider:before {
  transform: translateX(14px);
}

.custom-switch-label {
  font-size: 14px;
  width: 90px;
}

.ai-agent-transcript-preview-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  padding: 5px 0;
}

.ai-agent-transcript-preview {
  overflow-y: auto;
  height: 100%;
  margin-top: 15px;
  padding-bottom: 25px;
}

.ai-agent-transcript-preview-speaker {
  font-weight: bold;
  flex-shrink: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.ai-agent-transcript-preview-text {
  flex: 1;
  word-wrap: break-word;
  color: #000;
}

.ai-arrow-button {
  cursor: pointer;
  margin-top: 5px;
  padding: 2px 7px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.ai-agent-response-table {
  width: 100%;
  background-color: white;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &__line-reference {
    margin: 2px 2px 2px 0;
  }

  &__line-reference:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.ai-agent-response-table th,
.ai-agent-response-table td {
  padding: 10px;
  text-align: left;
  border-left: none;
}

.ai-agent-response-table th {
  background-color: #f8f8f8;
  font-weight: bold;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}

.ai-agent-response-table td {
  border-top: 1px solid #eaeaea;
}

.ai-agent-response-table tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.ai-agent-response-table tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.ai-agent-response-table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.ai-agent-response-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.playbook-block-button {
  background-color: #001AFF !important;
  width: 115px;
  height: 35px;
  padding: 3px;
}

.sub-block-icon {
  font-size: 1.2rem;
  color: #007bff;
  margin-left: 10px;
  cursor: pointer;
}

.sub-block-icon:hover {
  color: #0056b3;
}

.robot-icon {
  font-size: 1rem;
  color: #007bff;
  margin-left: 10px;
  cursor: pointer;
}

.playbook-block {
  width: 95%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
}

.playbook-agent-block {
  margin-top: 25px;
}

.playbook-custom-block-description {
  min-height: 225px;
}

.question-custom-block {
  &__title {
  }

  &__title-disabled {
    background-color: #D6D6D6 !important;
  }

  &__output-type {
  }

  &__output-type-disabled {
    background-color: #D6D6D6 !important;
  }

  &__description {
    min-height: 150px;
  }

  &__description-disabled {
    background-color: #D6D6D6;
  }

  &__tab-button,
  &--tab-button--active {
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    border-bottom: 1px solid #D6D6D6;
    text-align: center;

    &--active {
      color: #000000;
      font-weight: 600;
    }
  }

  &__tab-button:hover {
    background-color: #D6D6D6;
  }

  &__save-btn {
    color: #FFFFFF;
    border-color: transparent;
  }

  &__save-btn:hover {
    background-color: #0019E0;
    border-color: transparent;
  }
}

.question-custom-block-description {
  min-height: 150px;
}

.playbook-agent-description {
  margin: 10px 0;
}

.video-player-playbook {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;

  .ai-agent-response {
    margin: 0 5px;
  }
}

.video-player-playbook-tab {
  &__agent-selector {
    margin: 5px 0 0 7px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;

    &__label {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0 !important;
    }

    &__select {
      cursor: pointer;
    }


  }
}

.variable-type-dropdown {
  .btn.dropdown-toggle {
    width: 100%;
    text-align: start;
    border: 1px solid #d1d1d1;
  }

  .dropdown-menu {
    width: 100%;

    .dropdown-item {
      text-wrap: pretty;
      padding: 0.75rem 2.5rem;

      &:hover {
        color: black;
      }

      .option-label {
        font-weight: 600;
        color: black;
        display: inline;
      }

      .option-badge {
        font-size: 0.7rem;
        background-color: #f0f0f0;
        font-weight: 600;
        padding: 5px 10px;
        border-radius: 5px;
        margin-left: 2rem;

        &.widget-supported {
          background-color: #d0e0d0;
        }
      }

      .option-description {
        margin-top: 0.5rem;
        margin-bottom: 0;
      }
    }
  }
}
