.home-page {
    padding-bottom: 60px;
    overflow-x: visible;
    height: 100%;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 22px 12px 12px;
        margin-right: 0 !important;

        @media (max-width: 1024px) {
          display: grid;
        }

        &__title {
            font-size: 20px;
            font-weight: 600;
            color: #000;
            padding: 0px 13px;
        }

        &__actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            margin-bottom: 10px;

            @media (max-width: 1000px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .add-widget-button {
          color: #F5F8FA !important;
          background-color: #333333 !important;
          border: none;
          padding: 7px;
          margin: auto 6px;
        }
        .add-widget-button:focus,
        .add-widget-button:active,
        .add-widget-button:hover,
        .add-widget-button:not(:disabled):not(.disabled).active {
          box-shadow: none !important;
        }
    }

    .widget-button {
      background-color: black;
    }

  .widget-item-container {
    display: flex;
    align-items: center;
    gap: 12px;

    i {
      width: 10px;
    }
  }

  .responsive-grid-layout-wrapper {
    width: 100%;
    max-width: 100vw;
    overflow-x: auto;

    @media (max-width: 783px) {
      min-width: auto;
    }

    &.empty {
      overflow-x: hidden;
    }
  }

    .widgets {
        min-width: 1300px;
        width: 100%;

        @media (max-width: 783px) {
            min-width: auto;
        }
        .widget {
            @extend .card;
            padding: 0 8px;
            overflow: hidden auto;
            z-index: 1;

            .widget:hover .widget-dropdown {
              opacity: 0.6;
              border: none;
            }

            &:hover .widget__header__stats__options {
              opacity: 0.6;
            }

            .widget button:focus,
            .widget button:active,
            .widget button:hover {
              box-shadow: unset !important;
            }

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0 0 10px;
                height: 45px;
                position: sticky !important;
                top: 0;
                background-color: white;
                z-index: 2;

                &__title {
                    display: flex;
                    align-items: center;
                    grid-gap: 10px;
                    gap: 10px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #000;
                    flex: 2;
                    cursor: pointer;
                }

                &__link {
                  font-size: 14px;
                  font-weight: 300;
                  color: #597BF2;
                  padding: 0;
                }

                &__stats {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;

                  &__options {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    opacity: 0;
                    z-index: 50;

                    &.active {
                      opacity: 1 !important;
                    }
                  }
                }

                .widget-options-dropdown {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  gap: 5px;
                }

                .widget-dropdown {
                  opacity: 0;
                  cursor: pointer;

                    &:hover {
                      opacity: 1 !important;
                      border: none;
                    }
              }
            }

            &__body {
              margin-right: 10px;
                .meeting-list {
                    padding: 0 12px;
                    .meeting {
                        .meeting__info {
                            &--date {
                                font-size: 18px;
                            }
                            &__creator-name {
                                font-size: 11px;
                                img {
                                    width: 12px;
                                    height: 12px;
                                }
                            }
                            &__creator-company {
                                font-size: 14px;
                            }
                            &__company-name {
                                font-size: 12px;
                                font-weight: 600;
                                padding: 1% 0 1% 0;
                                color: $black;
                              }
                            &__creator-link {
                                font-size: 12px;
                            }
                            &__user-name {
                                font-size: 11px;
                                img {
                                    width: 12px;
                                    height: 12px;
                                }
                            }
                        }
                    }

                    .meeting__info__action-analytics {
                        display: block;
                        width: 175px;
                        height: 30px;
                        font-size: 11px;
                    }

                    .meeting__info__action-more {
                        display: none;
                    }
                }
            }
        }

      .sentence-chart-values-empty {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 235px;
        color: #ccc;
        font-size: 12px;
        font-weight: 500;
      }

        .react-grid-item {
          box-shadow: rgba(71, 88, 114, 0.1) 0px 2px 6px;
          border-radius: 5px;
          border: 0;
        }
        .react-grid-item.widget {
          border: 1px solid transparent;
        }
        .react-grid-item.widget:hover {
          border: 1px solid rgb(90, 186, 205);
        }
        .react-grid-item.react-grid-placeholder {
          background-color: green !important;
        }

    }

    .short {
      min-width: 100px;
    }

    .medium {
      min-width: 125px;
    }

    .long {
      min-width: 150px;
    }

    .more-filters-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      padding-left: 15px;

      &__text {
        font-weight: 600;
        color: black;
      }

      &__icon {
        line-height: 1;
        color: #cccccc;
        font-size: 24px;
        margin-left: 2px;
      }
    }

    .more-filters-dropdown {
      &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1040;
      }

      &__content {
        display: flex;
        flex-direction: column;
        gap: 1px;

        background-color: white;
        min-width: 200px;
        z-index: 1041;
        position: absolute;
        top: 110%;
        right: 0;
      }
    }

    .filters {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-left: 17px;

        &__search {
          min-width: 300px;

          div[class*="css-"][class$="-menu"] {
            right: 0;

            @media (max-width: 1024px) {
              left: 0;
            }
          }

          .dashboard-recordings-search__control--is-focused .dashboard-recordings-search__placeholder {
            display: none;
          }
        }

        &__title {
            text-decoration: underline;
            font-size: 13px;
            font-weight: 600;
            min-width: fit-content;
        }

        &__filter {
            @extend .card;
            margin: 0;
            cursor: pointer !important;

            .select2-selection__control {
                border: none;
            }

            .select2-selection__value-container {
                justify-content: start;
                padding-left: 16px;
            }

            .select2-selection__single-value, .select2-selection__placeholder, .select2-selection__value-container--is-multi {
                color: #000000!important;
                font-weight: 600;
            }

            @media (max-width: 1300px) {
              .select2-selection__menu {
                position: absolute !important;
                top: 100% !important;
                left: 0 !important;
                right: 0 !important;
              }
            }

          &--date-time {
                width: fit-content;

                .btn.btn-primary {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 5px 6px 5px 17px;
                    border: none;
                    background: none;
                    width: 100%;
                    color: #000000 !important;
                    font-weight: 600;

                    &:focus {
                      transition: none;
                        box-shadow: none;
                        border: 1px solid #2684FF;
                    }
                }

                .dropdown-menu {
                    left: auto !important;
                    right: 0;
                    transform: none !important;

                    @media (max-width: 1024px) {
                      position: absolute !important;
                      top: 100% !important;
                      left: 0 !important;
                      right: 0 !important;
                      min-width: 220px;
                    }
                }

                i.mdi {
                    margin-left: auto;
                    line-height: 1;
                    color: #cccccc;
                    font-size: 24px;
                }
            }
        }
      }
      
    @media(max-width: 1024px) {
      .filters {
        margin-left: 5px;
      }
    }

    .layout-name-container {
      display: flex;
      align-items: center;
      justify-content: center !important;
      margin: auto;
    }

    .layout-name-container h4 {
      text-align: center;
      width: 100%;
      box-sizing: border-box;
    }

    .layout-modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      cursor: pointer;
      box-sizing: border-box;

      @media (max-width: 1024px) {
        justify-content: flex-start;
      }
    }

    @media(max-width: 1024px) {
      .layout-modal-header {
        margin-bottom: 10px;

        .layout-modal-header {
          margin-bottom: 5px;
        }
      }
    }

    .fa-caret-down:before{
      display: none;
    }

    .star-icon{
      width: 20px;
      height: 18px;
      margin-right: 4px;
      margin-bottom: 4px;
    }

    .layout-modal-body {
      font-size: 14px;
      position: absolute;
      width: 297px;
      z-index: 5;
      background-color: #F5F8FA;
      left: 10px;
      height: 59px;
    }

    .wrapper-full-width {
      padding: 0px 10px;
    }
}

.edit-dashboard-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shared-with-title {
  margin-top: 10px;
}

.user-with-access {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.user-picture-container {
  display: flex;
  width: 10%;

  .user-picture {
    width: 35px;
    height: 35px;
    background: #DFDFDF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.user-name-email {
  display: flex;
  flex-direction: column;
  width: 35%;
  justify-content: center;

  .user-name {
    font-weight: 700;
    font-size: 15px;
  }

  .user-email {
    color: #808387;
    font-size: 13px;
  }
}

.close-button {
  border: none;
  right: 0;
  position: absolute;
  width: 30px;
  margin-right: 7px;
  border-radius: 5px;
  background-color: transparent;
}

.custom-button {
  border: 0;
  opacity: 0.6;
  padding-right: 10px;
  &:hover {
    opacity: 1 !important;
  }
}

.custom-dropdown {
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
  cursor: pointer;
}

.custom-dropdown-button {
  padding: 0;
  background-color: #FFFFFF;
  border: 0;
  cursor: pointer;
  font-size: 14px;
}

.default-filters-body{
  display: flex;
  flex-direction: column !important;
  justify-content: center;
}

.default-filters-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.default-filter{
  padding: 5px 0;
  width: 100%;
}

.magic-filter-modal {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 6px 8px 3px 0;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  margin: 4px 0;
}

.widget-preview-modal {
  position: fixed;
  padding: 20px;
  width: 55rem;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  width: 250px;
  text-align: center;
  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.close-button:hover {
  color: red;
}

.custom-dashboard-option:hover {
  background-color: #f0f0f0;
}