tbody + thead {
  display: none;
}

table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2191";
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193";
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}

table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table thead .sorting:before,
div.dataTables_scrollBody table thead .sorting_asc:before,
div.dataTables_scrollBody table thead .sorting_desc:before,
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}

div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  margin-top: 0 !important;
  border-top: none;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}

table.dataTable.table-sm > thead > tr > th {
  padding-right: 20px;
}

table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em;
}

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}

div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:first-child {
  padding-left: 0;
}

div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:last-child {
  padding-right: 0;
}

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
  text.apexcharts-datalabel {
    fill: #fff !important;
  }
}

.dashboard-wrap {
  &.dash-content {
    padding-top: 0;
  }
}

//.box-shadow-class {
//    box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.03);
//}
.dashboard-card {
  // max-height: 90%;
  min-height: 90%;
  .card-body {
    flex-direction: row;
    justify-content: space-between;
    flex: unset;
    display: flex;
    align-items: center;
    min-height: 100%;
    @media (max-width: 1440px) {
      padding: 1rem;
    }
    .media {
      width: 53px;
      height: 53px;
      order: 2;
      align-items: center;
      .avatar-sm {
        width: 100%;
        height: 100%;
      }
    }
    .media-body {
      width: calc(100% - 70px);
      order: 1;
      align-items: center;
      p {
        font-size: 18px;
        font-weight: 500;
        color: #000;
        line-height: 18px;
        margin-bottom: 5px;
        @media (max-width: 1199px) {
          font-size: 16px;
        }
      }
      h4 {
        font-size: 40px;
        font-weight: 500;
        line-height: 40px;
        margin-bottom: 0;
        @media (max-width: 1540px) {
          font-size: 29px;
        }
        @media (max-width: 1299px) {
          font-size: 25px;
        }
        &.green {
          color: #34c38f;
        }
        &.blue {
          color: #556ee6;
        }
        &.yellow {
          color: #f1b44c;
        }
        &.red {
          color: #ff0000;
        }
      }
    }
    .bg-soft-red {
      background-color: rgba(255, 0, 0, 0.15) !important;
    }
    .dropdown-menu.show {
      left: -145px !important;
      top: -170px !important;
      transform: none !important;
    }
  }
}

.flex-fill {
  &.interaction-wrap {
    .card-body {
      .card-title {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .card-body {
    .card-title {
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      color: #000;
      @media (max-width: 1199px) {
        font-size: 16px;
      }
      span {
        font-size: 14px;
        color: #aaaaaa;
        font-weight: normal;
        margin-top: 5px;
        line-height: 14px;
        display: inline-block;
      }
    }
  }
}

.interaction-wrap {
  .avatar-xs {
    width: 38px;
    height: 38px;
    .avatar-title {
      background-color: #f7f7f7;
      font-size: 12px;
      color: #c5c5c5;
    }
  }
  .company-profile {
    h4 {
      color: #333;
      font-weight: 600;
      margin-bottom: 5px;
      // white-space: pre-line;
      @media (min-width: 992px) and (max-width: 1399px) {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 120px;
      }
    }
    h5 {
      color: #8f8f8f;
      margin-bottom: 5px;
    }
    .date {
      color: #0045ca;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  .pitch-block {
    width: 116px;
    // height: 60px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    flex-direction: column;
    &.darkgrey {
      background-color: #494949;
    }
    &.darkblue {
      background-color: #0045ca;
    }
    &.lightblue {
      background-color: #2b9f91;
    }
    h4 {
      color: #fff;
      margin: 0;
      margin-bottom: 5px;
    }
    p {
      color: #fff;
      margin: 0;
      font-size: 6px;
    }
  }
  .time {
    margin-top: 8px;
    ul {
      padding: 0;
      display: flex;
      justify-content: center;
      margin: 0;
      li {
        font-size: 10px;
        color: #737373;
        line-height: 10px;
        padding: 0 5px;
        &:first-child {
          border-right: 1px solid #737373;
        }
      }
    }
  }
}

.recent-used {
  margin: 0 !important;
  width: 100%;
  .card {
    box-shadow: rgba(71, 88, 114, 0.1) 0px 2px 6px;
    min-height: 90%;
  }
}

.dashboard-main {
  .folder-menu {
    background-color: #fff;
    box-shadow: rgba(71, 88, 114, 0.1) 0px 2px 6px;
    text-align: center;
    margin-bottom: 25px;
    border: 2px solid transparent;
    h3 {
      color: #000000;
      font-size: 12px;
      padding: 15px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Gilroy";
      font-weight: 600;
      img {
        margin-right: 8px;
      }
    }
    &:hover {
      border: 2px solid #387cff2e;
    }
    &.active {
      // background: #0045CA;
      border: 2px solid #387cff;
      &:hover {
        border: 2px solid #387cff;
      }
      // h3 {
      //   color: #fff;
      //   img {
      //     filter: invert(100%) sepia(2%) saturate(0%) hue-rotate(208deg)
      //       brightness(102%) contrast(101%);
      //   }
      // }
    }
  }
  .folder-menu.drag-over {
    border: 2px solid #387cff2e;
  }
  .card-top-part {
    width: 100%;
    height: 142px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    border-radius: 5px 5px 0px 0px;
    padding: 20px;
    p {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      font-family: "Gilroy";
    }
    img {
      width: 89px;
      height: 26px;
      margin: 0 auto;
      display: block;
    }
  }
  .sales-part {
    background: #1b1b1b;
    text-align: center;
  }
  .offer-part {
    background: #8b31ca;
    text-align: center;
  }
  .sales-blue-part {
    background: #319cca;
    img {
      position: absolute;
      left: 20px;
      top: 20px;
      width: 51px;
      height: 15px;
    }
  }
  .other-part {
    background: #3a3a3a;
    align-items: flex-end;
    align-content: flex-end;
    p {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .card-text {
    h6 {
      color: #333;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      font-family: "Gilroy";
    }
    p {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 7px;
      color: #606060;
      display: flex;
      align-items: center;
      font-family: "Gilroy";
      .bx-folder {
        color: #8b31ca;
        margin-right: 4px;
      }
      img {
        margin-right: 5px;
      }
    }
  }
  .custome-box {
    @media (max-width: 992px) {
      margin-top: 20px;
    }
  }
}

.recent-use {
  .btn-secondary {
    background-color: #fff !important;
    color: #4e4e4e !important;
    border: none;
    &.dropdown-toggle {
      border: 1px solid #ededed;
      padding-right: 30px;
      position: relative;
      &:after {
        content: "";
        background-image: url("../../../../assets/images/dashboard/Vector.svg");
        width: 10px;
        height: 10px;
        background-position: right center;
        background-repeat: no-repeat;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.presentations-part {
  padding-left: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  .filter-check {
    margin-right: 10px !important;
  }
  @media screen and (max-width: 991px) {
    &.import-part {
      justify-content: flex-start;
      li {
        margin: 5px 0;
        &:first-child {
          padding-left: 0px;
        }
      }
    }
  }
  li {
    list-style-type: none;
    padding: 0px 10px;

    &:last-child {
      padding-left: 0;
    }
    a {
      color: #4e4e4e;
      font-size: 14px;
      font-family: "Gilroy";
      font-weight: 500;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .bx-check {
        font-size: 16px;
        margin-left: 8px;
        margin-top: 2px;
      }
    }
    .recent-use {
      .btn-secondary {
        &.dropdown-toggle {
          border: 0;
        }
      }
    }
  }
}

.team-dropdown {
  margin-right: 5px;
}

.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon,
.apexcharts-zoom-icon,
.apexcharts-pan-icon,
.apexcharts-reset-icon,
.apexcharts-menu-icon {
  display: none !important;
}

.dashboard-wrap {
  .date-main {
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
  h1 {
    padding: 0;
    margin-top: 0px;
  }
}
