.image-container {
  display: flex;
  align-items: center;
}
.image-upload {
  text-align: center;
  width: 100%;
}

.profile-image-wrapper {
  border-radius: 50%;
  background-color: #DFDFDF;
  position: relative;
  overflow: hidden;
}

.profile-image {
  width: 100%;
  filter: none !important;
  opacity: 1 !important;
}

.profile-image-chat {
  border-radius: 50%;
}

.btn-update-picture {
  margin-top: 10px;
}

.profile-image-wrapper:hover .overlay {
  display: block;
}

.img-content {
  background-color: rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%);
  height: 100%;
  font-size: 15px;
  color: white;
  position: absolute;
  top: 50%;
  left: 0%;
  content: "";
  z-index: 6;
  width: 100%;
  display: none;
  text-align: center;
}

.profile-image-wrapper:hover .img-content {
  display: flex;
  transition: all ease-in-out 0.2s;
  left: 50%;
}

.center {
  text-align: center;
}