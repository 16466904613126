.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.custom-modal {
  width: 90%;
  max-width: 100%;
  max-height: 100%;
  background: #E6E6E6;
  z-index: 1001;
  border-radius: 10px;
}

.custom-modal-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 10px;
}
