.filters-wrapper {
  order: -1;

  + .chart-actions {
    order: -2;
  }
}

.filters-container {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;

  // Hide number input arrows.
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  // Fix height for flex items.
  .flex-height-fix {
    height: 100%;
  }

  .filters {
    padding: 2px 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    li {
      margin: 0 12px 12px 0;
    }
    .filter {
      height: 32px;
      line-height: 32px;
      display: flex;

      .close-btn {
        padding: 0 14px 0 16px;
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
        margin-right: 1px;
        background-color: #e6e6e6;
        box-shadow: #00000005 0px 1px 3px 0px, #1b1f2326 0px 0px 0px 1px;

        &:hover {
          background-color: #cfcfcf;
        }
      }
      .numeric-filter {
        padding: 0 14px 0 12px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        display: flex;
        box-shadow: #00000005 0px 1px 3px 0px, #1b1f2326 0px 0px 0px 1px;

        &.shrink {
          padding-right: 9px;
        }
        &.expand {
          padding-right: 17px;
        }
        .dropdown {
          margin: 0 4px;

          > .btn {
            padding: 0 0.75rem;
            line-height: 30px;
            box-shadow: none !important;
          }
          .dropdown-menu {
            min-width: auto !important;
            left: 1px !important;
            box-shadow: 0 0.25rem 0.75rem #0000002d;

            .dropdown-item {
              padding: 0 10px;
              font-size: 20px;
              line-height: 31px;
            }
          }
        }
        .form-control {
          min-width: 2ch;
          max-width: 10ch;
          height: 32px;
          padding: 0;
          border: 0;
        }
      }
    }
  }

  .filters-dropdown {
    margin-right: 6px;
    flex-shrink: 0;

    .dropdown-menu {
      left: auto !important;
      right: 0 !important;
    }
  }
}
