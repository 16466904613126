// 
// _table.scss
// 

.table {
  th {
      font-weight: $font-weight-bold;
  }
}

// Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}

// Sticky first column
.sticky-table-column table thead th:first-child + th {
  position: sticky;
  left: 0;
  z-index: 2;
  @media(max-width:767px){
    position: initial;
  }
}
.sticky-table-column table td:first-child + td {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #fff;
  @media(max-width:767px){
    position: initial;
  }
}

.table .insights-live-meeting {
  td {
    background-color: unquote($color-blue + 'ee') !important;
    color: #fff;
  }
  .btn-clickable {
    color: #fff !important;
    text-decoration: underline !important;
  }
}
