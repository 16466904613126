.meeting-page{
  h1{
    margin-top: 20px;
  }
  .nav{
    position: relative;
    .nav-item{
      .nav-link{
        font-weight: 600;
      }
    }
    .action-btn{
      position: absolute;
      right: 0;
      display: flex;
      align-self: center;
    }
  }
  
  .tab-wrapper{
    &.card{
      box-shadow: none;
      background: transparent;
      >.card-body{
        padding: 0;
      }
    }
    h3{
      margin-bottom: 35px;
      @media(max-width:767px){
        margin-bottom: 20px;
        font-size: 17px;
      }
    }
  }
  .card-wrapper{
    margin-bottom: 45px;
    @media(max-width:767px){
      margin-bottom: 35px;
    }
    li{
      display: block;
      margin: 0;
    }
    .card-left-content{
      margin: 0;
      box-shadow: none;
      padding: 0;
      .card-title {
        font-size: 21px;
        margin: 0 0 15px 0;
        font-weight: 500;
        @media(max-width:992px){
          font-size: 17px;
        }
        .main-title{
          color: $black;
          min-width: 200px;
          display: inline-block;
          padding-right: 10px;
          @media(max-width:992px){
            min-width: 170px;
          }
        }
        span{
          color: $light-purple-color;
        }
      }
      .card-text{
        display: flex;
        align-items: center;
        p, div{
          margin: 0;
          font-size: 14px;
          line-height: 18px;
          margin-right: 20px;
        }
        label{
          margin: 0;
          color: #bbbbbb;
          margin-right: 20px;
          font-weight: 600;
        }
        span{
          display: inline-block;
          color: #3e3e3e;
          font-size: 13px;
          margin-right: 10px;
        }
        .attendence-name{
          display: inline-block;
          span{
            background: $body-bg;
            border-radius: 4px;
            padding: 3px 10px;
          }
        }
      }
    }
    .link-buttons{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      .btn-primary{
        margin-right: 15px;
      }
      @media(max-width:767px){
        justify-content: flex-start;
        .btn {
          margin-top: 15px;
        }
      }
    }
  }
}

// New Meeting Modal design Start
.meeting-modal{
  max-width: 740px;
  .modal-content{
    border-radius: 0;
    padding: 60px 35px 45px;
    border: 0;
    @media(max-width:767px){
      padding: 20px 15px 15px;
    }
  }
  .modal-header{
    border: 0;
    margin-bottom: 15px;
    position: relative;
    .modal-title{
      font-size: 26px;
      line-height: 35px;
      color: $header-dark-bg;
      text-align: center;
      margin: 0 auto;
      @media(max-width:767px){
        font-size: 24px;
      }
    }
    .close{
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      font-weight: 500;
    }
  }
  .date-control{
    background-color: #f8f8fb;
    border-radius: 0;
    border: 0;
    outline: none;
    box-shadow: none;
    min-width: 180px;
    width: auto;
    // text-align: center;
    margin-right: 20px;
    color: $black;
    @media(max-width:767px){
      min-width: 100%;
      margin-bottom: 7px;
      margin-right: 0;
    }
  }
  form {
    max-width: 530px;
    width: 100%;
    margin: 0 auto;
  }
  .form-group {
    display: flex;
    align-items: center;
    @media(max-width:767px){
      flex-wrap: wrap;
    }
    .dropdown-menu.show {
      display: block;
    }
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      min-width: 150px;
      margin: 0;
      margin-right: 15px;
      @media(max-width:767px){
        min-width: 100%;
        margin-bottom: 7px;
      }
    }
    
    .modal-dropdown {
      min-width: 180px;
      width: auto;
      margin-right: 20px;
      @media(max-width:767px){
        min-width: 100%;
        margin-bottom: 7px;
        margin-right: 0;
      }
      >div{
        background-color: #f8f8fb;
        border-radius: 0;
        border: 0;
        outline: none;
        box-shadow: none;
        text-align: center;
        color: $black;
        >div{
          justify-content: center;
          >span{
            width: 0;
          }
        }
      }
    }
    .checkbox{
      margin: 0;
      display: flex;
      justify-content: flex-start;
      padding-left: 0;
      @media(max-width:767px){
        width: 100%;
      }
      label {
        margin-right: 0;
      }
      input[type="checkbox"]{
        width: auto;
        opacity: 0.00000001;
        position: absolute;
        left: 0;
        margin-left: 0;
        &:checked ~ label:after {
            transform: rotate(-45deg) scale(1);
        }
      }
      label{
        min-width: auto;
        padding-top: 3px;
        &:after{
          border-bottom: 1px solid #0045CA;
          border-left: 1px solid #0045CA;
        }
      }
      .radio{
        position: relative;
      }
    }
  }
  .add-list{
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    @media(max-width:767px){
      width: calc(100% - 41px);
    }
    li{
      background: #f8f8fb;
      display: flex;
      padding-right: 10px;
      border-radius: 5px;
      margin-bottom: 5px;
      p{
        margin: 0 10px 5px 0;
        padding: 3px 10px;
        text-align: center;
        margin-bottom: 0;
      }
      .close{
        font-weight: 500;
        margin-left: 2px;
      }
    }
  }
  .add-btn {
    align-self: flex-start;
    background-color: #f8f8fb;
    border-radius: 0;
    border: 0;
    color: #000;
    height: 38px;
  }
  .modal-footer{
    justify-content: center;
    border: 0;
    .text-btn{
      background: transparent;
      border: 0;
      color: #3e3e3e;
      text-decoration: underline;
    }
  }
}

.meeting-type-modal {
  &__prompt {
    resize: none;
  }

  &__prompt-disabled {
    resize: none;
    background-color: #e7e7e7 !important;
  }

  &__instruction {
    height: 45px;
    color: #6E6E6E;
    font-style: italic;
    padding: 5px 0 0 0;
    border-radius: 10px;

    p {
      margin: 0 !important;
      font-size: 12px;
    }
  }

  &__phrases-and-search {
    display: flex;
    align-items: center;

    .tag {
      height: 25px !important;
    }
  }

  &__switch, &__enable-switch {
    margin-top: 15px;

    .switch {
      margin-bottom: 20px;

      &__wrapper {
        display: flex;
        flex-direction: row-reverse;
        margin: 0;
        justify-content: start;

        &__label {
          font-size: 13px;
        }

        &__switch-button {
          width: 50px;
          height: 23px;
          margin-right: 7px;

          .slider:before {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }

  &__enable-switch {
    margin: 0;
  }
}

.meeting-list {
  .meeting-tabs .meeting-tabs__tab {
    font-size: 18px;
  }
  .meeting__info.meeting__info--action {
    .meeting__info__action--buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }

    .meeting__info {
      &__action-join {
        width: 175px;
        height: 30px;
        font-size: 11px;
        border-radius: 5px;
      }
      &__action-more.dropdown {
        .dropdown-toggle.btn.btn-secondary {
          width: 30px;
          height: 30px;
          background-color: #E8E8E8;
          border-color: #E8E8E8;
          display: flex;
          justify-content: center;
          i {
            color: #7C7C7C;
          }
        }
      }
    }
  }
}
.meeting-datetime {
  .hide-time{
    display: none;
  }
  .show-time{
    display: inherit;
  }
  .react-datepicker-wrapper {
    width: 100px !important;
    margin-right: 10px;
    input {
      // min-width: 70% !important;
      width: 100% !important;
    }
    @media screen and (max-width: 767px) {
      width: 100% !important;
    }
  }
  input {
    min-width: 70px !important;
    width: 35px !important;
    @media screen and (max-width: 767px) {
      width: 100% !important;
    }
  }
}
.meeting-btn, .meeting-btn:hover, .meeting-btn:focus, .meeting-btn:active {
  border: none;
  background: #00B388;
  padding: 13px;
  width: 177px;
  font-size: 15px;
  font-weight: 500;
  color: white !important;
}
.search-wrapper-meet {
  position: relative;
  @media screen and (max-width: 443px) {
      margin-bottom: 15px;
  }
  .search-meeting {
      height: 48px;
      font-size: 15px;
      line-height: 21px;
      border-radius: 5px;
      font-family: "Gilroy";
      font-weight: 500;
      width: auto;
      margin-right: 20px;
      padding-right: 32px;
      width: 200px;
      text-transform: capitalize;
  }
  span {
      left: inherit;
      color: #b5b5b5;
      font-size: 20px;
      position: absolute;
      right: 30px;
      top: 15px;
  }
}
.meeting-gif {
  width: 100%;
  max-width: 315px;
}
.meeting-modal{
  &.attendee-modal {
    max-width: 550px;
  }
}

.attendee-company {
  padding-right: 0 !important;
  p{
    padding-right: 0 !important;
  }
}
.meeting-datetime {
  .show-time {
    input {
      background-color: #f8f8fb;
      border-radius: 0;
      border: 0;
      outline: none;
      box-shadow: none;
      min-width: 180px;
      width: auto;
      text-align: center;
      margin-right: 20px;
      color: #000;
    }
  }
}

.calendar-info-modal {
  &__header {
    display: flex;
    align-items: center;
    padding: 20px;
    border: none;

    .modal-title {
      font-size: 26px;
      line-height: 35px;
      color: $header-dark-bg;
      text-align: center;
      margin: 0 auto;
      @media(max-width:767px){
        font-size: 24px;
      }
    }

    .close {
      position: absolute;
      right: 20px;
    }
  }

  &__body {
    padding: 25px;

    &--loading {
      display: flex;
      justify-content: center;
      padding: 100px 50px;
    }
  }

  &__meeting-data {
    display: grid;
    grid-template-columns: 1fr 3fr;
    font-weight: bold;

    .link {
      text-decoration: underline !important;
    }

    .nowrap {
      white-space: nowrap;
    }
  }

  &__event-log {
    overflow-y: scroll;
    border: 1px solid #EEE;
    margin-top: 20px;
    max-height: 500px;
  }

  &__event-log-entry {
    padding: 2px 5px;

    &:nth-child(odd) {
      background-color: #E8E8E8;
    }
  }

  &__no-event-log {
    padding: 30px;
    font-size: 16px;
    text-align: center;
  }
}
