/* WYSIWYG Control Bar */
.wysiwyg-control-bar {
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #f7f7f7;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
}

.wysiwyg-control-bar button {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding: 4px 10px;
  margin-right: 8px;
  border-radius: 3px;
  font-size: 13px;
  transition: background-color 0.3s ease;
  outline: none;
}

.wysiwyg-control-bar button:hover {
  background-color: #e0e0e0;
}

.wysiwyg-control-bar input[type="color"] {
  margin-right: 8px;
  cursor: pointer;
  border-radius: 3px;
}

.wysiwyg-control-bar button.active {
  background-color: #007BFF;
  color: white;
}

/* WYSIWYG Editor Style */

.wysiwyg-editor {
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  min-height: 200px;
  background-color: #fff;
  font-size: 16px;
  line-height: 1.5;
  transition: box-shadow 0.2s ease-in-out;

  color: rgba(0, 0, 0, 0.5);
}

.wysiwyg-editor .public-DraftStyleDefault-italic {
  font-style: italic;
}

.wysiwyg-editor .color-red {
  color: red;
}

.wysiwyg-editor .color-blue {
  color: blue;
}

.wysiwyg-editor .color-green {
  color: green;
}

.wysiwyg-input {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 5px;
  background-color: #fff;
  margin-bottom: 3px;
}

.wysiwyg-button {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding: 5px;
  margin-left: 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.wysiwyg-button:hover {
  background-color: #f0f0f0;
}

.wysiwyg-invalid-link {
  padding: 2px;
  margin-left: 5px;
  font-weight: 500;
  color: red;
}