#compare-view {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    margin-bottom: 10px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        @media(max-width: 767px) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        @media (max-width: 1500px) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .filters {
            min-width: 120px;
          }
        }

        .page-name {
            font-size: 20px;
            font-weight: 600;
            color: #000;
            padding: 0px 13px;
            width: 25%;
        }

        .active-filters {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .filtered-value {
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 23px;
                color: #000000;
            }
        }
    }

    .filters {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        margin-left: 11px;

        @media (max-width: 1370px) {
            flex-direction: column;
            align-items: flex-start !important;
            margin-left: 0;
        }

        &__title {
            text-decoration: underline;
            font-size: 13px;
            font-weight: 600;
            white-space: nowrap;

            @media (max-width: 1370px) {
                display: none;
            }
        }

        &__filter {
            @extend .card;
            margin: 0;
            min-width: 150px;
            cursor: pointer;
            white-space: nowrap;

            @media(min-width: 1000px) {
              width: 110px;
            }

            @media (max-width: 1000px) {
                width: 100%;
            }

            .select2-selection__control {
                border: none;
            }

            .select2-selection__value-container {
                justify-content: start;
                padding-left: 16px;
            }

            .select2-selection__single-value, .select2-selection__placeholder, .select2-selection__value-container--is-multi {
                color: #000000!important;
                font-weight: 600;
            }

            @media (max-width: 1370px) {
                .select2-selection__menu {
                    position: absolute !important;
                    top: 100% !important;
                    left: 0 !important;
                    right: 0 !important;
                }
            }

            &--date-time {
                width: fit-content;

                @media (max-width: 1000px) {
                    width: 100%;
                }

                .btn.btn-primary {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 5px 6px 5px 17px;
                    border: none;
                    background: none;
                    width: 100%;
                    color: #000000 !important;
                    font-weight: 600;

                    &:focus {
                        transition: none;
                        box-shadow: none;
                        border: 1px solid #2684FF;
                    }
                }

                .dropdown-menu {
                    left: auto !important;
                    right: 0;
                    transform: none !important;

                    @media (max-width: 1370px) {
                        position: absolute !important;
                        top: 100% !important;
                        left: 0 !important;
                        right: 0 !important;
                        min-width: 220px;
                    }
                }

                i.mdi {
                    margin-left: auto;
                    line-height: 1;
                    color: #cccccc;
                    font-size: 24px;
                }
            }
        }
    }

    .page-content {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin-top: 20px;

        .options {
            width: 23%;

            .options-content {

                .group-header {
                    font-size: 18px;
                    font-weight: 600;
                    color: #000;
                    line-height: 32px;
                    letter-spacing: 0em;
                    margin-top: 20px;
                }

                .link {
                    font-size: 15px;
                    color: #333 !important;
                    font-weight: 500;
                    line-height: 25px;
                    letter-spacing: 0em;
                    cursor: pointer;

                    &:hover {
                        font-weight: 600;
                        color: #3C64F0;
                    }
                }

                .active {
                    font-weight: 600;
                    color: #3C64F0;
                }

                .link_disabled {
                    font-size: 15px;
                    color: #999 !important;
                    font-weight: 500;
                    line-height: 25px;
                    letter-spacing: 0em;

                    cursor: default;
                }
            }
        }
    }
}
