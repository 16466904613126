#conversation-insights {
    .header {
        display: flex;
        align-items: center;

        .page-name {
            font-size: 20px;
            font-weight: 600;
            color: #000;
            padding: 0 13px;
            width: 25%;
        }

        .filters {
            margin-left: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }

            &__title {
                text-decoration: underline;
                font-size: 13px;
                font-weight: 600;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            &__filter {
                @extend .card;
                min-width: 150px;
                margin: 0;
                cursor: pointer;

                .select2-selection__control {
                    border: none;
                }

                .select2-selection__value-container {
                    justify-content: start;
                    padding-left: 16px;
                }

                .select2-selection__single-value, .select2-selection__placeholder, .select2-selection__value-container--is-multi {
                    color: #000000!important;
                    font-weight: 600;
                }

                &--date-time {
                    width: 150px;

                    .btn.btn-primary {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: none;
                        background: none;
                        width: 100%;
                        color: #000000 !important;
                        font-weight: 600;

                        &:focus {
                            box-shadow: none;
                            border: 1px solid #2684FF;
                        }
                    }

                    .dropdown-menu {
                        left: auto !important;
                        right: 0;
                        transform: none !important;
                    }

                    i.mdi {
                        margin-left: auto;
                        line-height: 1;
                        color: #cccccc;
                        font-size: 24px;
                    }
                }
            }
        }

    }

    .page-content {
        margin: 50px;

        .transcript-search-form {
            padding: 28px 32px;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            border-radius: 5px;

            &-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                &-title {
                    font-size: 18px;
                    font-weight: 600;
                }

                &-select {
                    width: 140px;
                }
            }

            .example-text {
                font-size: 15px;
            }

            .transcript-search {
                margin: 0;
                width: 100%;
                border: 1px solid #E4E4E4;
                border-radius: 5px;

                &__icon {
                    @extend .input-group-prepend;

                    & > * {
                        border: none;
                        background: none;
                        i {
                            font-size: 18px;
                        }
                    }
                }

                &__input {
                    @extend .form-control;
                    border: none;
                    background: #ffffff;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }

        .charts-container {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .card {
                width: 31%;
            }

            .chart {
                height: 250px;
                overflow-y: auto;
            }
        }

        .transcript-sentences {
            margin-top: 50px;

            &_sentence {
                padding: 24px 36px;
                border-radius: 5px;
                background-color: #fff;

                &_header {
                    display: flex;
                    gap: 20px;
                }

                &_time {
                    color: #3C64F0;
                    font-size: 15px;
                    text-decoration: underline;
                    cursor: pointer;
                }

                &_speaker-name {
                    color: #3C64F0;
                    font-size: 15px;
                    text-decoration: underline;
                }

                &_meeting-name {
                    color: #3C64F0;
                    font-size: 15px;
                    font-weight: 600;
                    text-decoration: underline;
                    cursor: pointer;
                }

                &_body {
                    margin-top: 10px;
                    font-weight: 600;

                    .recording-url {
                        width: 12px;
                        height: 12px;
                        cursor: pointer;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
