// SwitchComponent.scss

.switch {

  &__wrapper {
    display: flex;
    align-items: center;

    &__label {
      margin-right: 10px;
    }

    &__switch-button {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;
        border-radius: 34px;
      }

      .slider:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }

      input:checked + .slider {
        background-color: #2196f3;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
      }

      input:checked + .slider:before {
        transform: translateX(26px);
      }

      &.clicked .slider {
        background-color: #ccc;
      }

      .slider.checked {
        background-color: #2196f3;
      }
    }

    &.disabled &__switch-button {
      opacity: 0.6;
    }
  }
}
