.edit-participants-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;

    .participant-data {
        display: flex;
        flex-direction: row;
        gap: 5px;
        width: 100%;

        .first-name {
            width: 20%;
        }
        .last-name {
            width: 20%;
        }
        .email {
            width: 30%;
            &:focus {
                border-color: #ced4da;
                box-shadow: none;
                background-image: none;
            }
        }
        .phone {
            width: 30%;
        }

        input[disabled] {
            background-color: #ececec;
        }
    }

    .add-participant {
        margin: 1rem;
    }
}
