body[data-layout="horizontal"] {
  .custome-horizontal-menu {
    color: #fff;
    padding: 0 25px;
    height: 53px;
    @media (max-width: 767px) {
      justify-content: center;
      height: 50px;
      width: 100% !important;
      position: fixed;
      bottom: 0px;
      top: inherit;
      padding-left: 8px;
      padding-right: 0;
    }

    .user-pic {
      cursor: pointer;
      @media (max-width: 767px) {
        padding: 0;
      }
      img {
        border-radius: 50%;
        width: 32px;
      }
      p {
        display: inline-block;
        margin: 0;
        vertical-align: top;
        line-height: 34px;
        color: #f5f5f5;
        opacity: 0.5;
        font-weight: 500;
        text-transform: capitalize;
      }
      a {
        &:hover {
          background: #f5f5f5;
          p {
            color: #556ee6;
            background-color: transparent;
          }
        }
      }
    }
    .h-100 {
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    .navbar-brand {
      .logo-sm img {
        display: none;
        @media (max-width: 991.98px) {
          display: inline-block;
          max-width: 26px;
          width: 100%;
        }
      }
      .logo-lg img {
        @media (max-width: 991.98px) {
          display: none;
        }
      }

      @media (max-width: 767px) {
        margin-right: 0;
      }
    }

    .nav-item {
      span {
        @media (max-width: 991.98px) {
          display: none;
        }
      }
      .user-name {
        @media (max-width: 767px) {
          display: none;
        }
      }
    }

    .user-menu-bottom {
      bottom: 10px;
      position: absolute;
      @media (max-width: 767px) {
        margin-top: 0px;
      }

      .sub-menu {
        @media (max-width: 767px) {
          top: auto !important;
          left: auto !important;
          right: -10px;
          bottom: 46px !important;
          width: 155px !important;
        }
        li {
          text-align: left;
          a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .user-sub-user {
        left: 190px !important;
        top: 0 !important;
        @media (max-width: 767px) {
          left: auto !important;
          right: 100%;
        }
      }
    }
    #sidebar-menu {
      @media (max-width: 767px) {
        height: 100%;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0px 10px;
        margin-bottom: 0px;
      }
      ul {
        @media (max-width: 767px) {
          display: flex;
          margin-bottom: 0px;
          align-items: center;
          &.user-menu-bottom {
            position: static;
          }
        }
        li {
          .notification {
            position: relative;
            p {
              background-color: #fff;
              height: 12px;
              width: 12px;
              border-radius: 50%;
              position: absolute;
              color: #000;
              text-align: center;
              top: 0px;
              right: 2px;
              left: inherit;
              top: 12px;
              font-size: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 12px;
            }
            @media (max-width: 767px) {
              display: flex;
              a {
                padding: 5px 10px;
              }
            }
          }
          &:hover {
            > ul {
              left: 50px;
              top: -85px;
              padding: 0px;
              background: #00318f !important;
              li {
                list-style-type: none;
              }
            }
          }
          a {
            @media (max-width: 480px) {
              padding: 15px 10px;
            }
          }
        }
      }
      .logo-responsive {
        display: none;
        padding-left: 0px;
        @media (max-width: 767px) {
          display: flex;
          align-content: center;
          li {
            a:hover {
              background: none !important;
            }
            img {
              max-width: 26px;
              width: 100%;
            }
          }
        }
      }
    }
    .responsive-menu {
      display: none;
      @media (max-width: 767px) {
        display: block;
      }
    }
  }
}

.logo-box {
  a {
    text-decoration: none !important;
    outline: none !important;
  }
}

body[data-sidebar="dark"] .mm-active .active i,
.metismenu li a:hover i {
  color: #00318f !important;
}

.vertical-collpsed #sidebar-menu ul li a > i {
  color: #f5f5f5 !important;
  opacity: 0.5 !important;
  margin-right: 0px;
}

body[data-sidebar="dark"] .navbar-brand-box.custome-box {
  @media (max-width: 991.98px) {
    width: 186px;
  }
}

.logo-lg {
  @media (max-width: 991.98px) {
    display: block !important;
  }
}

body
  .custome-horizontal-header
  .navbar-header
  .navbar-brand-box
  .logo-light
  span.logo-sm {
  @media (max-width: 991.98px) {
    display: none !important;
  }
}

.logo-lg {
    @media (max-width: 991.98px) {
      display: none !important;
    }

    .custome-horizontal-header
    .navbar-header
    .navbar-brand-box
    .logo-light
    span.logo-sm {
    @media (max-width: 991.98px) {
      display: block !important;
    }
  }
}

.custome-horizontal-menu .navbar-nav #sidebar-menu > ul > li > a:hover i {
  color: #f5f5f5 !important;
}

.navbar-item-active {
  background-color: #cccccc26;
}

@media (max-width: 767px) {
  .intercom-lightweight-app {
    display: none;
  }
}
