.audio-player-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 8px 8px 0 0;
}

.audio-player {
  width: 100%;
  max-width: 175px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  border-radius: 50px;
  height: 24px;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: none;
  border: none;
}

.time {
  font-size: 11px;
  color: #333;
  margin-left: 3px;
}

button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  color: #333;
}

button:focus {
  outline: none;
}

button:active {
  outline: none;
}

button i.fa-solid {
  font-size: 11px;
  display: block;
  margin: 8px;
}

.audio-player .controls input[type="range"] {
  -webkit-appearance: none;
  width: 70px;
  height: 8px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  padding: 0;
}

.audio-player .controls input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 8px;
  height: 8px;
  background: #333;
  cursor: pointer;
  border-radius: 50%;
}

.audio-player .controls input[type="range"]::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background: #333;
  cursor: pointer;
  border-radius: 50%;
}

.play-segment-button {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  min-width: 111px;
  height: 25px;
  font-size: 11px;
  padding: 0 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.highlighted-sentence {
  color: #2D69FF;
}